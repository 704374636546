import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import IScrollToTopProps from "./interfaces/IScrollToTopProps";

class ScrollToTop extends Component<IScrollToTopProps> {
  public componentDidUpdate(prevProps: Readonly<IScrollToTopProps>): void {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render(): React.ReactNode {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
