import i18n from "i18next";
import ILanguage from "interfaces/ILanguage";
import LanguageProvider from "providers/languageProvider";
import { initReactI18next } from "react-i18next";

const resources = {};
const fallbackLng: string[] = [];
LanguageProvider.init();
LanguageProvider.languages.forEach((language: ILanguage) => {
  resources[language.key] = { ...language.resources };
  fallbackLng.push(language.key);
});

i18n.use(initReactI18next).init({
  fallbackLng,
  lng: LanguageProvider.language,
  resources,
  ns: ["translation"],
  defaultNS: "translation",
  debug: process.env.REACT_APP_environment === "dev",
  // use `debug: true` to get more info about missing keys!
  parseMissingKeyHandler: (key) => {
    console.warn(`react-i18next key "${key}" not found.`);
    return key;
  },
});

export default i18n;
