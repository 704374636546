import ReactNotification from "components/notifications/reactNotification";
import LoaderTypes from "enums/loaderTypes";
import { TranslationMapper } from "i18n/mapper";
import IChannelMessages from "interfaces/IChannelMessages";
import ILocationOverview from "interfaces/ILocationOverview";
import ILogbook from "interfaces/ILogbook";
import ILogbookMessageRequest from "interfaces/request/ILogbookMessageRequest";
import LogbookService from "services/logbookService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

import { finishLoading, startLoading } from "./generalActions";
import { updateUnreadCountForLocations } from "./scheduleActions";

export const fetchedChannelMessagesForUser = actionCreator<
  ActionTypes.FETCHED_LOGBOOK_CHANNEL_MESSAGES,
  IChannelMessages
>(ActionTypes.FETCHED_LOGBOOK_CHANNEL_MESSAGES);
export const fetchedLogbookForUser = actionCreator<ActionTypes.FETCHED_LOGBOOK, ILogbook>(ActionTypes.FETCHED_LOGBOOK);

export function fetchChannelMessagesForUser(projectId: string, isForegroundLoading: boolean): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    if (isForegroundLoading) {
      dispatch(startLoading(LoaderTypes.LogbookChannel));
    }

    const logbookService = new LogbookService();
    logbookService
      .getChannelMessagesAsync(projectId)
      .then(cm => {
        const topicIds = getState().scheduleState.locations?.map(l => l.projectId) ?? [];
        dispatch(fetchLogbookChannelsForUser(false, topicIds));
        dispatch(fetchedChannelMessagesForUser(cm));
      })
      .catch(() =>
        ReactNotification.error(TranslationMapper.components.logbook.messages_component.error_fetch_messages)
      )
      .finally(() => dispatch(finishLoading(LoaderTypes.LogbookChannel)));
  };
}

export function fetchLogbookChannelsForUser(
  isForegroundLoading: boolean,
  topicIds?: string[],
  locations?: ILocationOverview[]
): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const logbookService = new LogbookService();
    const locationsOverview = locations ?? getState().scheduleState.locations;
    const ids = topicIds ?? locationsOverview?.map(l => l.projectId);

    if (ids == null) {
      return;
    }

    if (isForegroundLoading) {
      dispatch(startLoading(LoaderTypes.LogbookChannel));
    }

    logbookService
      .getLogbookAsync(ids)
      .then((logbook: ILogbook) => {
        dispatch(fetchedLogbookForUser(logbook));
        if (locationsOverview != null) {
          dispatch(updateUnreadCountForLocations(locationsOverview, logbook));
        }
      })
      .catch(() =>
        ReactNotification.error(
          TranslationMapper.components.logbook.messages_component.error_fetch_channels,
          undefined,
          true
        )
      )
      .finally(() => dispatch(finishLoading(LoaderTypes.LogbookChannel)));
  };
}

export function sendMessageForUser(projectId: string, message: string, onSuccess: () => void): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const logbookService = new LogbookService();

    dispatch(startLoading(LoaderTypes.LogbookChannelMessage));
    const messageRequest: ILogbookMessageRequest = {
      content: message,
      projectId: projectId,
    };

    logbookService
      .sendLogbookMessageAsync(messageRequest)
      .then(() => {
        dispatch(fetchChannelMessagesForUser(projectId, false));
        onSuccess();
      })
      .catch(() =>
        ReactNotification.error(TranslationMapper.components.logbook.messages_component.error_sending_message)
      )
      .finally(() => dispatch(finishLoading(LoaderTypes.LogbookChannelMessage)));
  };
}
