import CSS from "csstype";
import Slider from "rc-slider";
import { Component } from "react";

import CleaningFeedbackLevel1 from "../../../assets/img/cleaning-feedback-level-1.svg";
import CleaningFeedbackLevel2 from "../../../assets/img/cleaning-feedback-level-2.svg";
import CleaningFeedbackLevel3 from "../../../assets/img/cleaning-feedback-level-3.svg";
import CleaningFeedbackLevel4 from "../../../assets/img/cleaning-feedback-level-4.svg";
import IPollutionSliderProps, { IPollutionSliderStyling } from "./interfaces/IPollutionSliderProps";

const trackStyle: CSS.Properties = {
  backgroundColor: "#2B954B",
  height: "14px",
};

const railStyle: CSS.Properties = {
  backgroundColor: "#DBDBDB",
  height: "14px",
};

const handleStyle: CSS.Properties = {
  backgroundColor: "#ffffff",
  height: "30px",
  width: "30px",
  boxShadow: "0 0 4px 3px rgba(0,0,0,0.1)",
  marginTop: "-8.5px",
  border: "0px",
};

export class PollutionSlider extends Component<IPollutionSliderProps> {
  private generalStyle: CSS.Properties = {
    marginTop: "5px",
    height: "30px",
    margin: "auto",
    width: this.props.sliderWidth ?? "90%",
  };

  private pollutionStyling(): IPollutionSliderStyling {
    const maxThresholdClean = 25;
    const maxThresholdNeutral = 50;
    const maxThresholdDirty = 75;

    if (!this.props.value) {
      return { trackStyle: trackStyle, image: CleaningFeedbackLevel1 };
    }

    if (this.props.value <= maxThresholdClean) {
      trackStyle.backgroundColor = "#4CA000";
      return { trackStyle, image: CleaningFeedbackLevel1 };
    } else if (this.props.value <= maxThresholdNeutral) {
      trackStyle.backgroundColor = "#63D100";
      return { trackStyle, image: CleaningFeedbackLevel2 };
    } else if (this.props.value <= maxThresholdDirty) {
      trackStyle.backgroundColor = "#FFC92B";
      return { trackStyle, image: CleaningFeedbackLevel3 };
    } else {
      trackStyle.backgroundColor = "#EB3342";
      return { trackStyle, image: CleaningFeedbackLevel4 };
    }
  }

  public render(): JSX.Element {
    const pollutionStyling = this.pollutionStyling();

    return (
      <>
        <div className="d-flex justify-content-center mb-2">
          <img src={pollutionStyling.image} alt="pollution" />
        </div>
        <div className="d-flex justify-content-center">
          <Slider
            min={this.props.min}
            max={this.props.max}
            onAfterChange={this.props.onSelectionChange}
            onChange={this.props.onSliderChange}
            trackStyle={pollutionStyling.trackStyle}
            railStyle={railStyle}
            handleStyle={handleStyle}
            style={this.generalStyle}
            value={this.props.value}
            defaultValue={this.props.defaultValue}
          />
        </div>
      </>
    );
  }
}

export default PollutionSlider;
