import { StorageItems } from "constants/storageItems";
import i18n from "i18n/config";
import { languages } from "i18n/languages";
import i18next from "i18next";
import ILanguage from "interfaces/ILanguage";

export default class LanguageProvider {
  public static languages = languages;
  private static _defaultLang: string;

  public static init(): void {
    let defaultLangKey = LanguageProvider.languages[0].key;
    const defaultLang = LanguageProvider.languages.find(language => language.defaultLang === true);
    if (defaultLang) {
      defaultLangKey = defaultLang.key;
    }
    LanguageProvider.defaultLang = defaultLangKey;

    const language = localStorage.getItem(StorageItems.Language);
    if (language) {
      return;
    }

    localStorage.setItem(StorageItems.Language, defaultLangKey);
  }

  public static set defaultLang(lang: string) {
    this._defaultLang = lang;
  }

  public static get defaultLang(): string {
    return this._defaultLang;
  }

  public static set language(lang: string) {
    const _lang = lang.toLowerCase();
    localStorage.setItem(StorageItems.Language, _lang);
    i18n.changeLanguage(_lang);
  }

  public static get language(): string {
    const language = localStorage.getItem(StorageItems.Language);

    return language || this.defaultLang;
  }

  public static get languageData(): ILanguage {
    const languageData = LanguageProvider.languages.find(language => language.key === this.language);

    if (!languageData) {
      return LanguageProvider.languages[0];
    }

    return languageData;
  }

  public static t(key: string): string {
    return i18next.t(key.toLowerCase());
  }

  public static keyToMapperKey(key: string): string {
    return key.replace(/[ /-]/gi, "_").toLowerCase();
  }
}
