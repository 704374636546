import { fetchedCancellationReasons } from "./actions/activityActions";
import { setCustomer } from "./actions/customerActions";
import { resetError, setError } from "./actions/errorActions";
import { fetchedFeatureFlags, finishLoading, interceptedError, startLoading } from "./actions/generalActions";
import { fetchedChannelMessagesForUser, fetchedLogbookForUser } from "./actions/logbookActions";
import { fetchedNotification, fetchedNotificationCategories } from "./actions/notificationActions";
import {
  fetchedCleaningObject,
  fetchedLocationOverview,
  fetchedSchedule,
  setActivitiesCheckoutTrigger,
  setLocation,
  setScannedFloor,
  setScannedFloorId,
} from "./actions/scheduleActions";

export enum ActionTypes {
  START_LOADING = "START_LOADING",
  FINISH_LOADING = "FINISH_LOADING",
  INTERCEPTED_ERROR = "INTERCEPTED_ERROR",
  FETCHED_SCHEDULE = "FETCHED_SCHEDULE",
  FETCHED_FEATUREFLAGS = "FETCHED_FEATUREFLAGS",
  FETCHED_CLEANING_OBJECT = "FETCHED_CLEANING_OBJECT",
  FETCHED_CANCELLATION_REASONS = "FETCHED_CANCELLATION_REASONS",
  FETCHED_NOTIFICATION_CATEGORIES = "FETCHED_NOTIFICATION_CATEGORIES",
  FETCHED_NOTIFICATION = "FETCHED_NOTIFICATION",
  FETCHED_LOCATION_OVERVIEW = "FETCHED_LOCATION_OVERVIEW",
  FETCHED_LOGBOOK_CHANNEL_MESSAGES = "FETCHED_LOGBOOK_CHANNEL_MESSAGES",
  FETCHED_LOGBOOK = "FETCHED_LOGBOOK",
  SET_LAST_SCANNED_FLOOR = "SET_LAST_SCANNED_FLOOR",
  SET_CUSTOMER = "SET_CUSTOMER",
  SET_ACTIVE_LOCATION = "SET_ACTIVE_LOCATION",
  SET_ACTIVE_ACTIVITIES_CHECKOUT_TRIGGER = "SET_ACTIVE_ACTIVITIES_CHECKOUT_TRIGGER",
  SET_SCANNED_FLOOR = "SET_SCANNED_FLOOR",
  SET_ERROR = "SET_ERROR",
  RESET_ERROR = "RESET_ERROR",
}

export type ActionTypesCollection =
  | ReturnType<typeof startLoading>
  | ReturnType<typeof finishLoading>
  | ReturnType<typeof setError>
  | ReturnType<typeof resetError>
  | ReturnType<typeof interceptedError>
  | ReturnType<typeof fetchedCleaningObject>
  | ReturnType<typeof fetchedSchedule>
  | ReturnType<typeof fetchedCancellationReasons>
  | ReturnType<typeof fetchedNotificationCategories>
  | ReturnType<typeof fetchedNotification>
  | ReturnType<typeof fetchedLocationOverview>
  | ReturnType<typeof setScannedFloor>
  | ReturnType<typeof setCustomer>
  | ReturnType<typeof setLocation>
  | ReturnType<typeof setActivitiesCheckoutTrigger>
  | ReturnType<typeof setScannedFloorId>
  | ReturnType<typeof fetchedChannelMessagesForUser>
  | ReturnType<typeof fetchedLogbookForUser>
  | ReturnType<typeof fetchedFeatureFlags>;
