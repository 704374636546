// https://nodejs.org/api/events.html

import { EventEmitter } from "events";

export enum AppEvents {
  BuildingSelected,
  CustomerSelected,
  CustomerSpaceSelected,
  LanguageChanged,
  MapRefreshed,
}

export default class AppEventHub {
  public static initialize(): void {
    if (!AppEventHub.eventEmitter) {
      AppEventHub.eventEmitter = new EventEmitter();
    }
  }

  public static once(event: AppEvents, listener: (...args: any[]) => void): void {
    AppEventHub.eventEmitter.once(AppEvents[event], listener);
  }

  public static on(event: AppEvents, listener: (...args: any[]) => void): void {
    AppEventHub.eventEmitter.on(AppEvents[event], listener);
  }

  public static off(event: AppEvents, listener: (...args: any[]) => void): void {
    // Use this instead of .off since that relies on high enough (>10.0) version of node.js .
    AppEventHub.eventEmitter.removeListener(AppEvents[event], listener);
  }

  public static emit(event: AppEvents, payload?: any): void {
    AppEventHub.eventEmitter.emit(AppEvents[event], payload);
  }

  private static eventEmitter: EventEmitter;
}
