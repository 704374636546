import NotificationWarningImg from "assets/img/illustration/notification-warning.svg";
import Button from "components/material/buttons/button";
import ConfirmationModal from "components/modal/confirmationModal";
import NotificationStatus from "enums/notificationStatus";
import { TranslationMapper } from "i18n/mapper";
import INotificationStatusUpdate from "interfaces/INotificationStatusUpdate";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateNotificationStatusAsync } from "store/actions/notificationActions";
import { RootState } from "store/reducers/rootReducer";

import {
  INotificationPageDispatchProps,
  INotificationPageProps,
  INotificationPageStateProps,
} from "./interfaces/INotificationPageProps";
import INotificationPageState from "./interfaces/INotificationPageState";

class NotificationPage extends React.Component<INotificationPageProps, INotificationPageState> {
  public constructor(props: INotificationPageProps) {
    super(props);

    this.state = {
      showConfirmationStep: false,
    };

    this.showConfirmationStep = this.showConfirmationStep.bind(this);
  }
  private getNotificationImageUri(): string {
    if (!this.props.notification) {
      return "";
    }

    return `${this.props.notification.category.imageUri}_Notificationcategory_inactive`;
  }

  private cancel(): void {
    this.setState({ showConfirmationStep: false });
  }

  private showConfirmationStep(): void {
    this.setState({ showConfirmationStep: true });
  }

  private completeNotification(): void {
    if (!this.props.notification || !this.props.cleaningObjectId) {
      return;
    }

    const notificationStatusUpdate: INotificationStatusUpdate = {
      notificationId: this.props.notification.id,
      status: NotificationStatus.SignedOff,
    };

    this.props.updateNotificationStatus(notificationStatusUpdate, this.props.cleaningObjectId, this.props.history);
  }

  private renderImages(): JSX.Element {
    if (!this.props.notification) {
      return <></>;
    }

    return (
      <>
        {this.props.notification.pictures?.map((picture, index) => {
          return <img className="h--100-px me-2 mb-2" key={index} src={picture} alt="notification" />;
        })}
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.state.showConfirmationStep && (
          <ConfirmationModal
            cancel={(): void => this.cancel()}
            confirm={(): void => this.completeNotification()}
            title={LanguageProvider.t(TranslationMapper.components.notifications.confirmation_title)}
            bodyText={LanguageProvider.t(TranslationMapper.components.notifications.confirmation_text)}
            bodySubText={LanguageProvider.t(TranslationMapper.components.notifications.confirmation_subtext)}
            confirmButtonText={LanguageProvider.t(TranslationMapper.components.notifications.confirmation_completed)}
            imageSrc={NotificationWarningImg}
          />
        )}
        <div className="content content--with-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 block-content--px block-content--py">
                <div className="card">
                  <div className="card-body">
                    <h2 className="text-tertiary-900">
                      {LanguageProvider.t(TranslationMapper.components.notifications.category)}
                    </h2>
                    <img className="h--100-px" src={this.getNotificationImageUri()} alt="notification category" />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h2 className="text-tertiary-900">
                      {LanguageProvider.t(TranslationMapper.components.notifications.pictures)}
                    </h2>
                    <div>{this.renderImages()}</div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h2 className="text-tertiary-900">
                      {LanguageProvider.t(TranslationMapper.components.notifications.descriptionheader)}
                    </h2>
                    <p className="mb-0">{this.props.notification?.description}</p>
                  </div>
                </div>

                {this.props.notification != null && this.props.notification.comments.length > 0 && (
                  <div className="card">
                    <div className="card-body">
                      <h2 className="mb-4 text-tertiary-900">
                        {LanguageProvider.t(TranslationMapper.components.notifications.commentsheader)}
                      </h2>
                      {this.props.notification?.comments.map((comment) => {
                        return (
                          <div className="mb-4">
                            <div key="{comment.id}" className="d-flex justify-content-between">
                              <h3 className="text-green">{comment.createdBy}</h3>
                              <p className="small text-tertiary mb-0">
                                {moment(comment.createdOn).format("DD MMM HH:mm")}
                              </p>
                            </div>
                            <p className="mb-0">{comment.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 block-content--px block-content--py">
          <div className="bg-white box-shadow-black fixed-bottom ">
            <div className="mx-3 my-4">
              <Button
                label={LanguageProvider.t(TranslationMapper.components.notifications.complete)}
                className="btn btn-primary w-100"
                onClick={this.showConfirmationStep}
                iconEnd={["fal", "check"]}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationPageStateProps => ({
  notification: state.notificationState.activeNotification,
  cleaningObjectId: state.scheduleState.selectedCleaningObject?.id,
});

const mapDispatchToProps: INotificationPageDispatchProps = {
  updateNotificationStatus: updateNotificationStatusAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationPage));
