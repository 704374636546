import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FeedbackQuestion from "../../components/feedbackQuestions/feedbackQuestion";
import ActivityStatus from "../../enums/activityStatus";
import CheckoutTriggerType from "../../enums/checkoutTriggerType";
import { IFeedbackReplies } from "../../interfaces/IFeedbackRepliesAnswer";
import { IFeedbackRequest } from "../../interfaces/IFeedbackRequest";
import IActivityProps from "./interfaces/IActivityProps";

class Activity extends React.Component<IActivityProps> {
  public constructor(props: IActivityProps) {
    super(props);

    this.changeActivityStatus = this.changeActivityStatus.bind(this);
    this.triggerOnChange = this.triggerOnChange.bind(this);
    this.isActivityResponseValid = this.isActivityResponseValid.bind(this);
    this.onFeedbackAnswerChange = this.onFeedbackAnswerChange.bind(this);
    this.renderFeedbackQuestion = this.renderFeedbackQuestion.bind(this);
  }

  private changeActivityStatus(value: boolean): void {
    const status = value ? ActivityStatus.Finished : ActivityStatus.Cancelled;
    let feedbackReplies = this.props.activityStatus?.feedbackReplies;

    if (this.props.activityStatus?.status !== status) {
      feedbackReplies = [];
    }

    this.triggerOnChange(status, feedbackReplies);
  }

  private triggerOnChange(status?: string, feedbackReplies?: IFeedbackReplies[]): void {
    const activity = this.props.activity;
    const isValid = this.isActivityResponseValid(status, feedbackReplies);

    this.props.onChange({
      index: this.props.index,
      activityId: activity.id,
      cleaningObjectId: this.props.cleaningObjectId,
      projectId: this.props.projectId,
      status: status,
      feedbackReplies: feedbackReplies ?? [],
      checkoutTrigger: CheckoutTriggerType.Unknown,
      externalActivityId: activity.externalActivityId,
      scheduleId: activity.scheduleId,
      isValid,
    });
  }

  private isActivityResponseValid(status?: string, feedbackReplies?: IFeedbackReplies[]): boolean {
    let requiredQuestionIds: string[] = [];

    if (status === ActivityStatus.Finished) {
      requiredQuestionIds =
        this.props.activity?.feedbackQuestions?.filter(f => f.required && f.whenFinished).map(q => q.id) ?? [];
    } else if (status === ActivityStatus.Cancelled) {
      requiredQuestionIds =
        this.props.activity?.feedbackQuestions?.filter(f => f.required && f.whenCancelled).map(q => q.id) ?? [];
    }

    return requiredQuestionIds.every(id => {
      if (feedbackReplies == null || feedbackReplies.length === 0) {
        return false;
      }
      return feedbackReplies.some(f => f.feedbackQuestionId === id && f.answer != null && f.answer !== "");
    });
  }

  private get approvedClassName(): string {
    if (this.props.activityStatus?.status === ActivityStatus.Finished) {
      return "approve";
    }
    return "approve opacity-25";
  }

  private get rejectedClassName(): string {
    if (this.props.activityStatus?.status === ActivityStatus.Cancelled) {
      return "reject";
    }
    return "reject opacity-25";
  }

  private onFeedbackAnswerChange(_: string, questionId: string, answer: string | number): void {
    const feedbackAnswer: IFeedbackReplies = {
      feedbackQuestionId: questionId,
      answer: answer.toString(),
    };

    const currentResponses = this.props.activityStatus?.feedbackReplies ?? [];

    const newFeedbackResponses = [
      ...currentResponses.filter(feedback => feedback.feedbackQuestionId !== feedbackAnswer.feedbackQuestionId),
    ];
    newFeedbackResponses.push(feedbackAnswer);

    this.triggerOnChange(this.props.activityStatus?.status, newFeedbackResponses);
  }

  private getActivityDescription(description: string): string {
    const translationMapper = TranslationMapper.components.activities[LanguageProvider.keyToMapperKey(description)];

    if (translationMapper == null) {
      return description;
    }
    return LanguageProvider.t(translationMapper);
  }

  private renderFeedbackQuestion(activityId: string, question: IFeedbackRequest): JSX.Element {
    const activityStatus = this.props.activityStatus;
    const showFeedback =
      activityStatus?.status != null &&
      ((question.whenFinished && activityStatus.status === ActivityStatus.Finished) ||
        (question.whenCancelled && activityStatus.status === ActivityStatus.Cancelled));

    const answer = activityStatus?.feedbackReplies.find(
      feedback => feedback.feedbackQuestionId === question.id
    )?.answer;

    return (
      <div className="feedback-question" key={`${activityId}-${question.id}`}>
        {showFeedback && (
          <FeedbackQuestion
            disabled={this.props.disabled}
            activityId={activityId}
            question={question}
            key={question.id}
            answer={answer}
            onAnswerChange={this.onFeedbackAnswerChange}
          />
        )}
      </div>
    );
  }

  public render(): JSX.Element {
    const activity = this.props.activity;

    return (
      <div id={`activity${activity.id}`} className="card">
        <div className="card-body">
          <h2 className="mb-0">
            {activity.hasPriority && (
              <span className="fa-stack">
                <FontAwesomeIcon icon={["fal", "hourglass"]} fixedWidth className="fa-stack-1x" />
                <FontAwesomeIcon icon={["fas", "circle-exclamation"]} fixedWidth className="fa-stack-1x fa-xs" />
              </span>
            )}
            <span className="text-tertiary-900">{this.getActivityDescription(activity.description)}</span>
          </h2>
          <div className="text-danger">{activity.failureDescription}</div>
          <div className="d-flex justify-content-center my-4">
            <FontAwesomeIcon
              icon={["fas", "circle-xmark"]}
              size="4x"
              className={"text-warning cursor--pointer " + this.rejectedClassName}
              onClick={(): void => this.changeActivityStatus(false)}
            />
            <FontAwesomeIcon
              icon={["fas", "circle-check"]}
              size="4x"
              className={"text-success cursor--pointer ms-4 " + this.approvedClassName}
              onClick={(): void => this.changeActivityStatus(true)}
            />
          </div>
          <div className="feedback-input">
            {activity.feedbackQuestions != null &&
              activity.feedbackQuestions.length > 0 &&
              activity.feedbackQuestions.map(question => this.renderFeedbackQuestion(activity.id, question))}
          </div>
        </div>
      </div>
    );
  }
}

export default Activity;
