import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";

import IReactNotification from "./interfaces/IReactNotification";

const NotificationTypes = {
  Info: "info",
  Succes: "success",
  Warning: "warning",
  Error: "error",
};

// Wrapper for type safety on react-notifications, and added functionality
export default class ReactNotification {
  private static currentOpenNotifications: IReactNotification[] = [];

  public static error(
    messageTranslationLabel: string,
    timeOut?: number,
    showMaxOne?: boolean,
    title?: string,
    priority?: boolean,
    onClick?: () => void
  ): void {
    ReactNotification.createNotification(
      NotificationTypes.Error,
      messageTranslationLabel,
      timeOut,
      onClick,
      title,
      priority,
      showMaxOne
    );
  }

  public static info(
    messageTranslationLabel: string,
    timeOut?: number,
    showMaxOne?: boolean,
    title?: string,
    priority?: boolean,
    onClick?: () => void
  ): void {
    ReactNotification.createNotification(
      NotificationTypes.Info,
      messageTranslationLabel,
      timeOut,
      onClick,
      title,
      priority,
      showMaxOne
    );
  }

  public static success(
    messageTranslationLabel: string,
    timeOut?: number,
    showMaxOne?: boolean,
    title?: string,
    priority?: boolean,
    onClick?: () => void
  ): void {
    ReactNotification.createNotification(
      NotificationTypes.Succes,
      messageTranslationLabel,
      timeOut,
      onClick,
      title,
      priority,
      showMaxOne
    );
  }

  public static warning(
    messageTranslationLabel: string,
    timeOut?: number,
    showMaxOne?: boolean,
    title?: string,
    priority?: boolean,
    onClick?: () => void
  ): void {
    ReactNotification.createNotification(
      NotificationTypes.Warning,
      messageTranslationLabel,
      timeOut,
      onClick,
      title,
      priority,
      showMaxOne
    );
  }

  public static manageNotifications(): void {
    NotificationManager.addChangeListener((e: any) => ReactNotification.onNotificationsChange(e));
  }

  public static onNotificationsChange(e: any): void {
    this.currentOpenNotifications = e;
  }

  private static createNotification(
    type: string,
    messageTranslationLabel: string,
    timeOut?: number,
    onClick?: () => void,
    title: string = "",
    priority: boolean = false,
    showMaxOne: boolean = false
  ): void {
    const message = LanguageProvider.t(messageTranslationLabel);

    if (showMaxOne) {
      this.removePreviousNotifications(message, type);
    }

    NotificationManager[type](message, title, timeOut, onClick, priority);
  }

  private static removeNotification(notification: IReactNotification): void {
    try {
      NotificationManager.remove(notification);
    } catch (e) {
      // failsafe, might happen notification no longer exists due to timeout
      // this isn't a problem because message will already be gone,
      // so this makes sure error won't be thrown here
    }
  }

  private static removePreviousNotifications(message: string, type: string): void {
    const sameNotifications = this.currentOpenNotifications.filter(n => n.message === message && n.type === type);

    if (!sameNotifications || sameNotifications.length === 0) {
      return;
    }

    sameNotifications.forEach(n => this.removeNotification(n));
  }
}
