import NotificationFailedImg from "assets/img/illustration/notification-failed.svg";
import NotificationCreatedImg from "assets/img/illustration/notification-success.svg";
import ErrorTypes from "enums/errorTypes";
import LoaderTypes from "enums/loaderTypes";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import Loader from "../../components/loader/loader";
import INotificationFinishedProps, { INotificationFinishedStateProps } from "./interfaces/INotificationFinishedProps";

class NotificationFinished extends Component<INotificationFinishedProps> {
  public render(): JSX.Element {
    return (
      <>
        {this.props.isLoading && <Loader isLoading={this.props.isLoading} />}
        {!this.props.isLoading && !this.props.showError && (
          <div className="modal__message">
            <img src={NotificationCreatedImg} alt="notification created" />
            <div className="modal__message__block">
              <h2 className="text-infographic">
                {LanguageProvider.t(TranslationMapper.components.notifications.created)}
              </h2>
              <p>{LanguageProvider.t(TranslationMapper.components.notifications.shown)}</p>
            </div>
          </div>
        )}
        {!this.props.isLoading && this.props.showError && (
          <div className="modal__message">
            <img src={NotificationFailedImg} alt="notification failed" />
            <div className="modal__message__block">
              <h2 className="text-infographic">
                {LanguageProvider.t(TranslationMapper.components.notifications.failed)}
              </h2>
              <p>{LanguageProvider.t(TranslationMapper.components.notifications.try_again)}</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationFinishedStateProps => ({
  isLoading: state.notificationState.loaders.some(loader => loader === LoaderTypes.NotificationCreate),
  showError: state.notificationState.errors.some(error => error === ErrorTypes.NotificationCreate),
});

export default connect(mapStateToProps)(NotificationFinished);
