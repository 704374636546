import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setActiveCustomer } from "../../store/actions/customerActions";
import { RootState } from "../../store/reducers/rootReducer";
import {
  ICustomerSelectionDispatchProps,
  ICustomerSelectionProps,
  ICustomerSelectionStateProps,
} from "./interfaces/ICustomerSelectionProps";

class CustomerSelection extends React.Component<ICustomerSelectionProps> {
  public constructor(props: ICustomerSelectionProps) {
    super(props);

    this.setActiveCustomer = this.setActiveCustomer.bind(this);
  }

  public componentDidMount(): void {
    if (this.props.cleaningLocations.length > 0 && this.customerIds.length === 1) {
      this.setActiveCustomer(this.customerIds[0]);
    }
  }

  private setActiveCustomer(customerId: string): void {
    this.props.setActiveCustomer(customerId);
  }

  private get customerIds(): string[] {
    return [...new Set(this.props.cleaningLocations.map((item) => item.customerId))];
  }

  private renderNoLocations(): JSX.Element {
    return <h2>{LanguageProvider.t(TranslationMapper.pages.start_page.no_locations_found)}</h2>;
  }

  public render(): JSX.Element {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 block-content--px block-content--py">
              <h1 className="h1--page-title mb-3">
                {LanguageProvider.t(TranslationMapper.components.customer_selection.title)}
              </h1>
              {this.props.cleaningLocationsAvailable &&
                this.customerIds.map((customerId, index) => (
                  <button
                    className="btn btn--content"
                    key={index}
                    onClick={(): void => this.setActiveCustomer(customerId)}
                  >
                    <div className="btn--content_block">
                      <div>
                        <div className="btn--content__content">
                          <h2>
                            {this.props.cleaningLocations.find((c) => c.customerId === customerId)?.customerName ?? "-"}
                          </h2>
                        </div>
                      </div>
                      <div className="btn--content__end">
                        <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
                      </div>
                    </div>
                  </button>
                ))}
              {!this.props.cleaningLocationsAvailable && this.renderNoLocations()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ICustomerSelectionStateProps => ({
  cleaningLocationsAvailable: state.scheduleState.locations != null && state.scheduleState.locations.length > 0,
  cleaningLocations: state.scheduleState.locations ?? [],
});

const mapDispatchToProps: ICustomerSelectionDispatchProps = {
  setActiveCustomer: setActiveCustomer,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSelection));
