import IFeatureFlag from "interfaces/IFeatureFlag";

import FlagClient from "./clients/flagClient";

export default class FlagService {
  private readonly flagClient: FlagClient = new FlagClient();

  public async GetFlagsByNameAsync(flagNames: string[]): Promise<IFeatureFlag[]> {
    return this.flagClient.GetFlagsByNameAsync(flagNames);
  }
}
