import { TranslationMapper } from "i18n/mapper";
import { NotificationManager } from "react-notifications";

import TelemetryService from "../services/telemetryService";
import LanguageProvider from "./languageProvider";

const iosWebkit = (window as any).webkit;
const androidInterface = (window as any).appInterface;

export enum OsType {
  Unknown = "Unknown",
  Android = "Android",
  iOS = "iOS",
}

/**
 * FunctionName must be a hardcoded value!
 * We found an issue when setting a flexibel function name, like getFunctionName, after minify the hook name will be "value".
 * This will cause problems when using more then 1 callback. That's why functionName should always be a hardcoded value.
 */
export interface INativeShellCallback {
  function: (value?: any) => void;
  functionName: string;
}

export const nativeInterfaceMessage = {
  startScan: "startScan",
};

export default class NativeCommunicationProvider {
  public static isNativeShellActive(): boolean {
    return this.isAndroidInterfaceAvailable();
  }

  public static initiateNFCScanner(callback: INativeShellCallback, callbackManualScan: INativeShellCallback): void {
    if (!NativeCommunicationProvider.isNativeShellActive()) {
      const error = LanguageProvider.t(TranslationMapper.global.errors.no_native_shell_detected);

      TelemetryService.AppInsights?.trackException({
        exception: { name: "NativeCommunicationProvider.initiateNFCScanner exception", message: error },
      });
      NotificationManager.error(error);

      return;
    }

    const userLanguage = LanguageProvider.language;

    switch (NativeCommunicationProvider.osType) {
      case OsType.iOS:
        console.warn("iOS is currently not supported");
        return;
      case OsType.Android:
        NativeCommunicationProvider.buildAndroidShellHook(callback, callbackManualScan);
        NativeCommunicationProvider.postToAndroidShell(
          callback.functionName,
          callbackManualScan.functionName,
          userLanguage
        );
        return;
      default:
        return;
    }
  }

  private static get osType(): OsType {
    if (this.isAndroidInterfaceAvailable()) {
      return OsType.Android;
    }

    if (this.isIOSInterfaceAvailable()) {
      return OsType.iOS;
    }

    return OsType.Unknown;
  }

  private static isIOSInterfaceAvailable(): boolean {
    return iosWebkit != null && iosWebkit.messageHandlers.startScan;
  }

  private static isAndroidInterfaceAvailable(): boolean {
    return androidInterface != null;
  }

  public static postToAndroidShell(callbackName: string, callbackName2?: string, languageSelection?: string): void {
    // Initiate action in Android native shell
    if (!NativeCommunicationProvider.isNativeShellActive()) {
      const error = LanguageProvider.t(TranslationMapper.global.errors.no_native_shell_detected);

      TelemetryService.AppInsights?.trackException({
        exception: { name: "NativeCommunicationProvider.postToAndroidShell exception", message: error },
      });
      NotificationManager.error(error);

      return;
    }

    androidInterface.postMessageWithCallback(callbackName, callbackName2, languageSelection);
  }

  private static buildAndroidShellHook(
    callback: INativeShellCallback,
    secondCallBackFunction?: INativeShellCallback
  ): void {
    // This hook will be used by Android to initiate callback after action in Native shell.
    // For example, user scans a NFC tag, the native shell will use the callback function onReceiveNFCMessage(),
    // this is the hook we define here. Without this hook, the function can't be called by the native shell.

    window[callback.functionName] = (input: any): void => callback.function(input);

    if (secondCallBackFunction && secondCallBackFunction.function) {
      window[secondCallBackFunction.functionName] = (): void => secondCallBackFunction.function();
    }
  }
}
