import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Loader from "../../components/loader/loader";
import Button from "../../components/material/buttons/button";
import Notifications from "../../components/notifications/notifications";
import NotificationWizard from "../../components/notifications/notificationWizard";
import LoaderTypes from "../../enums/loaderTypes";
import IActivityStatusUpdate from "../../interfaces/IActivityStatusUpdate";
import ICleaningObjectActivity from "../../interfaces/ICleaningObjectActivity";
import { finishActivitiesAsync } from "../../store/actions/activityActions";
import { RootState } from "../../store/reducers/rootReducer";
import Activity from "./activity";
import { IActivitiesDispatchProps, IActivitiesProps, IActivitiesStateProps } from "./interfaces/IActivitiesProps";
import IActivitiesState from "./interfaces/IActivitiesState";

class Activities extends React.Component<IActivitiesProps, IActivitiesState> {
  public constructor(props: IActivitiesProps) {
    super(props);

    this.state = {
      showCreateNotificationModal: false,
      activitiesState: [],
    };

    this.inputDisabled = this.inputDisabled.bind(this);
    this.finishCleaningObject = this.finishCleaningObject.bind(this);
    this.openNotificationModal = this.openNotificationModal.bind(this);
    this.onCloseNotificationModal = this.onCloseNotificationModal.bind(this);
    this.updateActivityStatus = this.updateActivityStatus.bind(this);
  }

  private get buttonDisabled(): boolean {
    if (this.props.isLoading) {
      return true;
    }

    return this.state.activitiesState.length === 0 || this.state.activitiesState.some((activity) => !activity.isValid);
  }

  private allUpdatesHaveTheSameSequence(activityId: string, currentUpdates: IActivityStatusUpdate[]): boolean {
    return currentUpdates.every(
      (activityState) => this.getSequence(activityState.activityId) === this.getSequence(activityId)
    );
  }

  private getSequence(activityId: string): number | undefined {
    return this.props.activities.find((x) => x.id === activityId)?.sequence;
  }

  private inputDisabled(activityId: string): boolean {
    return this.state.activitiesState.find((x) => x.activityId === activityId) == null;
  }

  private openNotificationModal(): void {
    this.setState({
      showCreateNotificationModal: true,
    });
  }

  private onCloseNotificationModal(): void {
    this.setState({
      showCreateNotificationModal: false,
    });
  }

  private finishCleaningObject(): void {
    if (this.buttonDisabled) {
      return;
    }

    const activityStatusUpdates = this.state.activitiesState;

    this.props.finishActivities(activityStatusUpdates, this.props.history);
  }

  private getActivityStatus(activity: ICleaningObjectActivity, index: number): IActivityStatusUpdate | undefined {
    return this.state.activitiesState.find(
      (activityState) => activityState.activityId === activity.id && activityState.index === index
    );
  }

  private updateActivityStatus(activityStatusUpdate: IActivityStatusUpdate): void {
    let activitiesState: IActivityStatusUpdate[] = [];

    if (this.allUpdatesHaveTheSameSequence(activityStatusUpdate.activityId, this.state.activitiesState)) {
      activitiesState = [
        ...this.state.activitiesState.filter((activity) => activity.activityId !== activityStatusUpdate.activityId),
      ];
    }

    activitiesState.push(activityStatusUpdate);

    this.setState({
      activitiesState,
    });
  }

  public render(): JSX.Element {
    return (
      <>
        {this.state.showCreateNotificationModal && (
          <NotificationWizard
            onClose={this.onCloseNotificationModal}
            selectedCleaningObjectId={this.props.cleaningObjectId}
            selectedProjectId={this.props.projectId}
          />
        )}

        <div className="content content--with-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">{this.props.isLoading && <Loader isLoading={this.props.isLoading} />}</div>

              <div className="col-12 block-content--px block-content--py">
                {!this.props.isLoading && (
                  <div>
                    <Notifications />

                    {this.props.activities.length === 0 && (
                      <div className="mt-4 ms-4">
                        {LanguageProvider.t(TranslationMapper.components.activities.no_activities)}
                      </div>
                    )}

                    {this.props.activities.map((activity, index) => (
                      <Activity
                        key={`activity${index}`}
                        index={index}
                        projectId={this.props.projectId}
                        cleaningObjectId={this.props.cleaningObjectId}
                        activity={activity}
                        activityStatus={this.getActivityStatus(activity, index)}
                        disabled={this.inputDisabled(activity.id)}
                        onChange={this.updateActivityStatus}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white box-shadow-black fixed-bottom">
          <div className="d-flex justify-content-center px-3 py-4">
            <Button
              label={LanguageProvider.t(TranslationMapper.components.activities.create_notification)}
              className="btn btn-outline-secondary me-2 w-50"
              onClick={this.openNotificationModal}
              disabled={this.props.isLoading}
              iconEnd={["fal", "bell"]}
            />
            <Button
              label={LanguageProvider.t(TranslationMapper.components.activities.done)}
              className="btn btn-primary ms-2 w-50"
              onClick={this.finishCleaningObject}
              disabled={this.buttonDisabled}
              iconEnd={["fal", "check"]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IActivitiesStateProps => ({
  projectId: state.scheduleState.schedule?.id ?? "",
  activities: state.scheduleState.selectedCleaningObject?.activities ?? [],
  cleaningObjectId: state.scheduleState.selectedCleaningObject?.id ?? "-",
  isLoading: state.generalState.loaders.some((loader) => loader === LoaderTypes.CleaningObject || LoaderTypes.Schedule),
});

const mapDispatchToProps: IActivitiesDispatchProps = {
  finishActivities: finishActivitiesAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activities));
