import Button from "components/material/buttons/button";
import ModalComponent from "components/modal/modal";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import DateUtils from "utils/dateUtils";
import { replaceStringPlaceholders } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IAssignActivityProps from "./interfaces/IAssignActivityProps";
import IAssignActivityState from "./interfaces/IAssignActivityState";

export default class AssignActivity extends Component<IAssignActivityProps, IAssignActivityState> {
  public constructor(props: IAssignActivityProps) {
    super(props);

    const state: IAssignActivityState = {
      showPopup: false,
    };

    this.state = state;

    this.togglePopup = this.togglePopup.bind(this);
  }

  private get renderActivityInProgressMessage(): string {
    if (!this.props.activity.slaStartDateTime) {
      return "";
    }

    const message = LanguageProvider.t(
      TranslationMapper.components.notifications.deadline_message_activity_in_progress
    );

    return message + DateUtils.getFriendlyDateTimeString(new Date(this.props.activity.slaStartDateTime));
  }

  private renderFooter(): JSX.Element {
    return (
      <>
        <Button
          className="btn btn-outline-secondary"
          label={LanguageProvider.t(TranslationMapper.components.notifications.do_not_assign_activity_button)}
          onClick={this.togglePopup}
          iconEnd={["fal", "xmark"]}
        />
        <Button
          className="btn btn-primary"
          label={LanguageProvider.t(TranslationMapper.components.notifications.assign_activity_button)}
          onClick={this.props.onAssignToEmployee}
          iconEnd={["fal", "check"]}
        />
      </>
    );
  }

  private togglePopup(event?: React.MouseEvent<HTMLButtonElement>): void {
    if (event) {
      event.stopPropagation();
    }

    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  private get deadlineMessage(): string | undefined {
    let deadline = this.props.activity.slaStartDateTime;
    if (!deadline) {
      return undefined;
    }

    deadline = new Date(deadline);
    const message = LanguageProvider.t(TranslationMapper.components.notifications.deadline_message);
    const formattedDate = DateUtils.getFriendlyDateString(deadline);
    const formattedTime = DateUtils.getTimeString(deadline);
    return replaceStringPlaceholders(message, ...[formattedDate, formattedTime]);
  }

  public render(): JSX.Element {
    return (
      <>
        {this.props.activity.isAssignedToOperator && (
          <div className="btn--content__message">
            <div className="btn--content__message-info">
              <span className="fa-stack">
                <FontAwesomeIcon icon={["fal", "hourglass"]} fixedWidth className="fa-stack-1x" />
                <FontAwesomeIcon icon={["fas", "circle-exclamation"]} fixedWidth className="fa-stack-1x fa-xs" />
              </span>
              <span>{this.renderActivityInProgressMessage}</span>
            </div>
          </div>
        )}

        {!this.props.activity.isAssignedToOperator && (
          <>
            <Button
              label={LanguageProvider.t(TranslationMapper.components.notifications.view_malfunction_button)}
              className="btn btn-outline-secondary mt-2 w-100"
              onClick={this.togglePopup}
              iconEnd={["fal", "circle-exclamation"]}
            ></Button>
            <ModalComponent
              titleResourceLabel={TranslationMapper.components.notifications.assign_activity_popup_header}
              show={this.state.showPopup}
              showHeader={true}
              onModalHide={this.togglePopup}
              footerContent={this.renderFooter()}
            >
              <div className="text-center">
                <h2>{this.props.cleaningObjectName}</h2>
                <p className="small">{this.props.activity.failureDescription}</p>
                {this.props.location && (
                  <p className="small">
                    <FontAwesomeIcon icon={["fal", "location-dot"]} fixedWidth className="me-2" />
                    {this.props.location}
                  </p>
                )}
                {this.deadlineMessage && <p className="small text-danger mb-0">{this.deadlineMessage}</p>}
              </div>
            </ModalComponent>
          </>
        )}
      </>
    );
  }
}
