import Step3Image from "assets/img/illustration/info-approve-or-reject.svg";
import Step2Image from "assets/img/illustration/info-coffee.svg";
import Step1Image from "assets/img/illustration/info-nfc.svg";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";

import { IStartPageStep } from "../../interfaces/IStartPageStep";
import IInfoModalProps from "./interfaces/IInfoModalProps";

export default class InfoModal extends Component<IInfoModalProps> {
  public constructor(props: IInfoModalProps) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private get steps(): IStartPageStep[] {
    return [
      { stepNumber: 1, text: TranslationMapper.global.infomodal.step_1, imageSrc: Step1Image },
      { stepNumber: 2, text: TranslationMapper.global.infomodal.step_2, imageSrc: Step2Image },
      { stepNumber: 3, text: TranslationMapper.global.infomodal.step_3, imageSrc: Step3Image },
    ];
  }

  private onClose(): void {
    this.props.onClose();
  }

  public render(): ReactNode {
    return (
      <div>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal
              show={this.props.show}
              onHide={this.onClose}
              backdrop="static"
              dialogClassName="modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">{LanguageProvider.t(TranslationMapper.global.infomodal.title)}</h1>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="py-0 px-3 modal-body__height-xl">
                <div className="text-center">
                  <img src={Step1Image} alt="" />
                  <p>
                    <span className="text-green fw-bold">1. </span>
                    {LanguageProvider.t(TranslationMapper.global.infomodal.step_1)}
                  </p>

                  <img src={Step2Image} alt="" />
                  <p>
                    <span className="text-green fw-bold">2. </span>
                    {LanguageProvider.t(TranslationMapper.global.infomodal.step_2)}
                  </p>

                  <img src={Step3Image} alt="" />
                  <p>
                    <span className="text-green fw-bold">3. </span>
                    {LanguageProvider.t(TranslationMapper.global.infomodal.step_3)}
                  </p>
                </div>
              </Modal.Body>
            </Modal>,
            this.modalHook
          )}
      </div>
    );
  }
}
