import * as msal from "@azure/msal-browser";

export const AuthenticationConfig: msal.Configuration = {
  auth: {
    clientId: process.env.REACT_APP_clientId || "",
    authority: process.env.REACT_APP_authority || "",
    redirectUri: process.env.REACT_APP_rootUrl || "",
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

export const ApplicationConfig: IApplicationConfig = {
  endpoints: {
    cmApi: process.env.REACT_APP_endpoints_cmApi || "",
  },
  scopes: {
    app: [process.env.REACT_APP_App_scope || ""],
    cmApi: [process.env.REACT_APP_cm_Api_scope || ""],
  },
  environment: process.env.REACT_APP_environment || "",
  disableCosmeticPermissions: process.env.REACT_APP_disableCosmeticPermissions === "true".toLowerCase() || false,
};

interface IApplicationConfig {
  endpoints: {
    [key: string]: string;
  };
  scopes: {
    [key: string]: string[];
  };
  environment: string;
  disableCosmeticPermissions: boolean;
}
