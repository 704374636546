import { CmApiAuthenticator } from "authenticators/cmApiAuthenticator";
import IActivityStatusUpdate from "interfaces/IActivityStatusUpdate";
import IReactSelectValue from "interfaces/IReactSelectValue";

import { ApplicationConfig } from "../../config";
import BaseClient from "./baseClient";

export default class ActivityClient extends BaseClient {
  private endpoint: string = "api/activity";

  public constructor() {
    super(ApplicationConfig.endpoints.cmApi, new CmApiAuthenticator());
  }

  public async finishActivitiesAsync(statusUpdate: IActivityStatusUpdate[]): Promise<Response> {
    return this.post(`${this.endpoint}/finish`, statusUpdate);
  }

  public async getCancellationReasonsAsync(): Promise<IReactSelectValue[]> {
    return this.get<IReactSelectValue[]>(`${this.endpoint}/cancellationreasons`);
  }

    public async assignOperatorToActivityAsync(activityIds: string[], customerId: string): Promise<Response> {
        return this.post(`${this.endpoint}/assign`, { activityIds, customerId });
    }
}
