import Resizer from "react-image-file-resizer";

export default class ImageUtils {
  public static compressToBase64Async(imageFile: File): Promise<string> {
    switch (imageFile.type) {
      case "image/jpg":
      case "image/jpeg":
      case "image/png":
        return ImageUtils.compressImageToMaximizedBase64Async(imageFile);
      default:
        throw new Error("File type not allowed");
    }
  }

  public static compressImageToMaximizedBase64Async(image: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          image,
          1024,
          1024,
          "JPEG",
          100,
          0,
          resizedImage => {
            return resolve(resizedImage as string);
          },
          "base64"
        );
      } catch (error) {
        throw new Error(`${error}`);
      }
    });
  }
}
