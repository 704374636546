import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { Component } from "react";

import { UserAuthenticator } from "../../authenticators/userAuthenticator";
import Button from "../../components/material/buttons/button";
import ModalComponent from "../modal/modal";
import { IUserSettingsProps } from "./interfaces/IUserSettingsProps";
import IUserSettingsState from "./interfaces/IUserSettingsState";

export default class UserSettings extends Component<IUserSettingsProps, IUserSettingsState> {
  private userAuthenticator: UserAuthenticator;

  public constructor(props: IUserSettingsProps) {
    super(props);

    const state: IUserSettingsState = {
      showSettings: false,
    };

    this.state = state;

    this.userAuthenticator = new UserAuthenticator();

    this.toggleShowSettings = this.toggleShowSettings.bind(this);
    this.renderSettings = this.renderSettings.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }

  private logoutUser(event: React.MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.userAuthenticator.signOut();
  }

  private renderSettings(): JSX.Element {
    return (
      <div className="card" key={1}>
        <div className="card-body d-flex justify-content-between">
          <div>
            <h3>{LanguageProvider.t(TranslationMapper.global.settings.account_title)}</h3>
            <h5 className="mb-0">{this.userAuthenticator.getName()}</h5>
          </div>

          <div>
            <Button
              label={LanguageProvider.t(TranslationMapper.global.buttons.logout)}
              onClick={this.logoutUser}
              className="btn btn-outline-secondary"
            />
          </div>
        </div>
      </div>
    );
  }

  private toggleShowSettings(): void {
    this.setState({
      showSettings: !this.state.showSettings,
    });
  }

  public render(): JSX.Element {
    return (
      <>
        <Button className="btn-link btn-sm" iconStart={["fal", "circle-user"]} onClick={this.toggleShowSettings} />

        <ModalComponent
          className="modal__fullscreen"
          show={this.state.showSettings}
          showHeader={true}
          onModalHide={this.toggleShowSettings}
          content={this.renderSettings()}
          titleResourceLabel={TranslationMapper.global.settings.user_settings_title}
          size="xl"
          fullscreen={true}
        />
      </>
    );
  }
}
