import { INotificationCategory, INotificationDetails } from "./interfaces/INotificationWizard";

export default class NotificationValidator {
  public static isCategoryValid(notification: INotificationCategory): boolean {
    if (notification.selectedCategoryId == null || notification.selectedCategoryId === "") {
      return false;
    }
    return true;
  }

  public static areDetailsValid(notification: INotificationDetails): boolean {
    if (
      notification == null ||
      ((notification.pictures == null || notification.pictures.length === 0) &&
        (notification.description == null || notification.description === ""))
    ) {
      return false;
    }

    return true;
  }
}
