import INotification from "interfaces/INotification";
import INotificationStatusUpdate from "interfaces/INotificationStatusUpdate";

import INotificationCategoryItem from "../interfaces/INotificationCategoryItem";
import INotificationRequest from "../interfaces/INotificationRequest";
import NotificationClient from "./clients/notificationClient";

export default class NotificationService {
  private readonly notificationClient: NotificationClient;

  public constructor() {
    this.notificationClient = new NotificationClient();
  }

  public async createNotificationAsync(notification: INotificationRequest): Promise<Response> {
    return this.notificationClient.createNotificationAsync(notification);
  }

  public async getNotificationAsync(notificationId: string): Promise<INotification> {
    return this.notificationClient.getNotificationAsync(notificationId);
  }

  public async getNotificationCategoriesForCleaningObjectAsync(
    cleaningObjectId: string
  ): Promise<INotificationCategoryItem[]> {
    return this.notificationClient.getNotificationCategoriesForCleaningObjectAsync(cleaningObjectId);
  }

  public async updateNotificationStatusAsync(notificationStatusUpdate: INotificationStatusUpdate): Promise<Response> {
    return this.notificationClient.updateNotificationStatusAsync(notificationStatusUpdate);
  }
}
