import { cloneDeep } from "lodash";
import produce from "immer";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { INotificationState, initialNotificationState } from "../state/notificationStoreState";

const initialState: INotificationState = initialNotificationState;

function notificationReducer(
  state: INotificationState = initialState,
  action: ActionTypesCollection
): INotificationState {
  switch (action.type) {
    case ActionTypes.START_LOADING: {
      return produce(state, (draftState) => {
        draftState.loaders = [...state.loaders, action.payload];
      });
    }
    case ActionTypes.FINISH_LOADING: {
      return produce(state, (draftState) => {
        draftState.loaders = [...state.loaders.filter((l) => l !== action.payload)];
      });
    }
    case ActionTypes.FETCHED_NOTIFICATION_CATEGORIES: {
      cloneDeep(state.notificationCategoriesPerCleaningObjectArray);
      const notificationCategoriesPerCleaningObjectArray = cloneDeep(
        state.notificationCategoriesPerCleaningObjectArray
      ) ?? [
        {
          cleaningObjectId: "0",
          notificationCategories: [],
        },
      ];

      notificationCategoriesPerCleaningObjectArray[action.payload.cleaningObjectId] =
        action.payload.notificationCategories ?? [];

      return produce(state, (draftState) => {
        draftState.notificationCategoriesPerCleaningObjectArray = notificationCategoriesPerCleaningObjectArray;
      });
    }
    case ActionTypes.FETCHED_NOTIFICATION:
      return produce(state, (draftState) => {
        draftState.activeNotification = action.payload;
      });
    case ActionTypes.SET_ERROR: {
      return produce(state, (draftState) => {
        draftState.errors = [...state.errors, action.payload];
      });
    }
    case ActionTypes.RESET_ERROR: {
      return produce(state, (draftState) => {
        draftState.errors = state.errors.filter((e) => e !== action.payload);
      });
    }
    default:
      return state;
  }
}

export default notificationReducer;
