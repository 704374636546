import { TranslationMapper } from "i18n/mapper";
import FeatureFlagProvider, { FlagNames } from "providers/featureFlagProvider";
import LanguageProvider from "providers/languageProvider";
import NativeCommunicationProvider, { INativeShellCallback } from "providers/nativeCommunicationProvider";
import { Component } from "react";

import Button from "../../components/material/buttons/button";
import ManualTagInputModal from "../modal/manualTagInputModal";
import INfcProps from "./interfaces/INfcProps";
import INfcState from "./interfaces/INfcState";

export default class Nfc extends Component<INfcProps, INfcState> {
  public constructor(props: INfcProps) {
    super(props);

    this.state = {
      showManualInputModal: false,
    };

    this.openManualInputModal = this.openManualInputModal.bind(this);
    this.onCloseManualInputModal = this.onCloseManualInputModal.bind(this);
    this.startScan = this.startScan.bind(this);
  }

  private startScan(): void {
    const callbackOnScan: INativeShellCallback = {
      function: this.props.callback,
      functionName: "onReceiveNFC",
    };

    const callbackOpenManualInput: INativeShellCallback = {
      function: this.openManualInputModal,
      functionName: "openManualInputModal",
    };

    NativeCommunicationProvider.initiateNFCScanner(callbackOnScan, callbackOpenManualInput);
  }

  private onGetByManualInput(tag: string): void {
    this.props.onGetByManualInput(tag);
    this.setState({
      showManualInputModal: false,
    });
  }

  private openManualInputModal(): void {
    this.setState({
      showManualInputModal: true,
    });
  }

  private onCloseManualInputModal(): void {
    this.setState({
      showManualInputModal: false,
    });
  }

  private get showManualInputButton(): boolean {
    // Used for development, because this button has moved to the native shell, it can't be triggered on pc anymore without this
    return (
      !NativeCommunicationProvider.isNativeShellActive() &&
      FeatureFlagProvider.isFeatureActive(FlagNames.CleaningApp_FF_Show_Old_Manual_NFC_Button)
    );
  }

  public render(): JSX.Element {
    return (
      <div className="bg-white fixed-bottom box-shadow-black py-4 px-3 nfc">
        <div className="d-flex justify-content-center">
          <Button
            label={LanguageProvider.t(TranslationMapper.global.buttons.start_scan)}
            className="btn-primary w-100 mb-2"
            onClick={this.startScan}
            iconEnd={["fal", "mobile-signal"]}
          />
        </div>
        {this.showManualInputButton && (
          <div className="d-flex justify-content-center">
            <button className="btn btn-link" onClick={this.openManualInputModal}>
              {LanguageProvider.t(TranslationMapper.pages.start_page.no_working_tag)}
            </button>
          </div>
        )}
        <ManualTagInputModal
          show={this.state.showManualInputModal}
          onClose={this.onCloseManualInputModal}
          onGetByManualInput={(tag: string): void => this.onGetByManualInput(tag)}
          message={this.props.message}
        />
      </div>
    );
  }
}
