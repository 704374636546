export const TranslationMapper = {
  global: {
    messages: {
      new_app_version: "global.messages.new_app_version",
    },
    buttons: {
      logout: "global.buttons.logout",
      apply: "global.buttons.apply",
      cancel: "global.buttons.cancel",
      return: "global.buttons.return",
      delete: "global.buttons.delete",
      edit: "global.buttons.edit",
      save: "global.buttons.save",
      next: "global.buttons.next",
      previous: "global.buttons.previous",
      close: "global.buttons.close",
      ok: "global.buttons.ok",
      loading: "global.buttons.loading",
      start_scan: "global.buttons.start_scan",
      search: "global.buttons.search",
      filter: "global.buttons.filter",
    },
    errors: {
      failed_login_title: "global.errors.failed_login_title",
      failed_login_text: "global.errors.failed_login_text",
      general: "global.errors.general",
      browser_specific_error_title: "global.errors.browser_specific_error_title",
      browser_specific_error_text: "global.errors.browser_specific_error_text",
      reload_home: "global.errors.reload_home",
      try_again: "global.errors.try_again",
      uncaught_exception_title: "global.errors.uncaught_exception_title",
      invalid_nfc: "global.errors.invalid_nfc",
      no_native_shell_detected: "global.errors.no_native_shell_detected",
      error_finishing_activity: "global.errors.error_finishing_activity",
      error_creating_notification: "global.errors.error_creating_notification",
      error_updating_notification: "global.errors.error_updating_notification",
    },
    infomodal: {
      title: "global.infomodal.title",
      step_1: "global.infomodal.step_1",
      step_2: "global.infomodal.step_2",
      step_3: "global.infomodal.step_3",
    },
    modals: {
      manual_input: "global.modals.manual_input",
      placeholder: "global.modals.placeholder",
      nfc_label: "global.modals.nfc_label",
      invalid_code: "global.modals.invalid_code",
      nfc_previouspage_warning_text: "global.modals.nfc_previouspage_warning_text",
      nfc_previouspage_warning_subtext: "global.modals.nfc_previouspage_warning_subtext",
    },
    date_formats: {
      today: "global.date_formats.today",
      tomorrow: "global.date_formats.tomorrow",
      weekdays: {
        mon: "global.date_formats.weekdays.mon",
        tue: "global.date_formats.weekdays.tue",
        wed: "global.date_formats.weekdays.wed",
        thu: "global.date_formats.weekdays.thu",
        fri: "global.date_formats.weekdays.fri",
        sat: "global.date_formats.weekdays.sat",
        sun: "global.date_formats.weekdays.sun",
        monday: "global.date_formats.weekdays.monday",
        tuesday: "global.date_formats.weekdays.tuesday",
        wednesday: "global.date_formats.weekdays.wednesday",
        thursday: "global.date_formats.weekdays.thursday",
        friday: "global.date_formats.weekdays.friday",
        saturday: "global.date_formats.weekdays.saturday",
        sunday: "global.date_formats.weekdays.sunday",
      },
      time_unit: {
        minute: "global.date_formats.time_unit.minute",
        hour: "global.date_formats.time_unit.hour",
        day: "global.date_formats.time_unit.day",
        week: "global.date_formats.time_unit.week",
        month: "global.date_formats.time_unit.month",
        year: "global.date_formats.time_unit.year",
      },
      months: {
        january: "global.date_formats.months.january",
        february: "global.date_formats.months.february",
        march: "global.date_formats.months.march",
        april: "global.date_formats.months.april",
        may: "global.date_formats.months.may",
        june: "global.date_formats.months.june",
        july: "global.date_formats.months.july",
        august: "global.date_formats.months.august",
        september: "global.date_formats.months.september",
        october: "global.date_formats.months.october",
        november: "global.date_formats.months.november",
        december: "global.date_formats.months.december",
      },
      months_shorthand: {
        january: "global.date_formats.months_shorthand.january",
        february: "global.date_formats.months_shorthand.february",
        march: "global.date_formats.months_shorthand.march",
        april: "global.date_formats.months_shorthand.april",
        may: "global.date_formats.months_shorthand.may",
        june: "global.date_formats.months_shorthand.june",
        july: "global.date_formats.months_shorthand.july",
        august: "global.date_formats.months_shorthand.august",
        september: "global.date_formats.months_shorthand.september",
        october: "global.date_formats.months_shorthand.october",
        november: "global.date_formats.months_shorthand.november",
        december: "global.date_formats.months_shorthand.december",
      },
    },
    rejection_reasons: {
      placeholder: "global.rejection_reasons.placeholder",
      mothball: "global.rejection_reasons.mothball",
      notneeded: "global.rejection_reasons.notneeded",
      machineissue: "global.rejection_reasons.machineissue",
      customerissue: "global.rejection_reasons.customerissue",
      notreachable: "global.rejection_reasons.notreachable",
      outofstockornotdelivered: "global.rejection_reasons.outofstockornotdelivered",
      cleanedonly: "global.rejection_reasons.cleanedonly",
    },
    settings: {
      user_settings_title: "global.settings.user_settings_title",
      account_title: "global.settings.account_title",
      language_title: "global.settings.language_title",
      language_option_nl: "global.settings.language_option_nl",
      language_option_en: "global.settings.language_option_en",
    },
  },
  pages: {
    start_page: {
      no_working_tag: "pages.start_page.no_working_tag",
      no_schedule_found: "pages.start_page.no_schedule_found",
      no_locations_found: "pages.start_page.no_locations_found",
    },
    schedule_page: {
      no_cleaning_object_found: "pages.schedule_page.no_cleaning_object_found",
      reference_not_found: "pages.schedule_page.reference_not_found",
      project_not_selected: "pages.schedule_page.project_not_selected",
    },
    notification_page: {
      no_notification_found: "pages.notification_page.no_notification_found",
    },
  },
  components: {
    activities: {
      done: "components.activities.done",
      fill_in_reason: "components.activities.fill_in_reason",
      finish_cleaning_object: "components.activities.finish_cleaning_object",
      no_activities: "components.activities.no_activities",
      small: "components.activities.small",
      refill: "components.activities.refill",
      cups: "components.activities.cups",
      complaint: "components.activities.complaint",
      wish: "components.activities.wish",
      malfunction: "components.activities.malfunction",
      information_request: "components.activities.information_request",
      finished: "components.activities.finished",
      select_answer: "components.activities.select_answer",
      create_notification: "components.activities.create_notification",
      remove_graffiti: "components.activities.remove_graffiti",
      feedback: {
        statuscode: "components.activities.feedback.statuscode",
        reasoncode: "components.activities.feedback.reasoncode",
        reviewrequest: "components.activities.feedback.reviewrequest",
        reason: "components.activities.feedback.reason",
        reasontext: "components.activities.feedback.reasontext",
        specialistrequired: "components.activities.feedback.specialistrequired",
        mothball: "components.activities.feedback.mothball",
        not_needed: "components.activities.feedback.not_needed",
        machine_issue: "components.activities.feedback.machine_issue",
        customer_issue: "components.activities.feedback.customer_issue",
        not_reachable: "components.activities.feedback.not_reachable",
        ingredients_out_of_stock_not_delivered___machine_only_cleaned:
          "components.activities.feedback.ingredients_out_of_stock_not_delivered___machine_only_cleaned",
        completed_at_location: "components.activities.feedback.completed_at_location",
        completed_no_error: "components.activities.feedback.completed_no_error",
        completed_customer_error: "components.activities.feedback.completed_customer_error",
        completed_operator_error: "components.activities.feedback.completed_operator_error",
        incomplete_specialist_required: "components.activities.feedback.incomplete_specialist_required",
        incomplete_customer_not_present: "components.activities.feedback.incomplete_customer_not_present",
        crockery_error: "components.activities.feedback.incomplete_specialist_required",
        ingredient_error: "components.activities.feedback.ingredient_error",
        not_applicable: "components.activities.feedback.not_applicable",
        no_cross_selling_error: "components.activities.feedback.no_cross_selling_error",
        drip_tray_error: "components.activities.feedback.drip_tray_error",
        no_stock_error: "components.activities.feedback.no_stock_error",
        machine_dirty_error: "components.activities.feedback.machine_dirty_error",
        true: "components.activities.feedback.true",
        false: "components.activities.feedback.false",
        characters: "components.activities.feedback.characters",
      },
    },
    notifications: {
      title: "components.notifications.title",
      created: "components.notifications.created",
      error: "components.notifications.error",
      error_image_file: "components.notifications.error_image_file",
      error_image_size: "components.notifications.error_image_size",
      failed: "components.notifications.failed",
      invalid_data: "components.notifications.invalid_data",
      new: "components.notifications.new",
      category: "components.notifications.category",
      select_category: "components.notifications.select_category",
      shown: "components.notifications.shown",
      try_again: "components.notifications.try_again",
      descriptionheader: "components.notifications.descriptionheader",
      description: "components.notifications.description",
      commentsheader: "components.notifications.commentsheader",
      pictures: "components.notifications.pictures",
      take_pictures: "components.notifications.take_pictures",
      notification: "components.notifications.notification",
      notifications: "components.notifications.notifications",
      complete: "components.notifications.complete",
      confirmation_title: "components.notifications.confirmation_title",
      confirmation_text: "components.notifications.confirmation_text",
      confirmation_subtext: "components.notifications.confirmation_subtext",
      confirmation_cancel: "components.notifications.confirmation_cancel",
      confirmation_completed: "components.notifications.confirmation_completed",
      updated: "components.notifications.updated",
      slafailuresingle: "components.notifications.slafailuresingle",
      slafailuremultiple: "components.notifications.slafailuremultiple",
      assign_activity_popup_header: "components.notifications.assign_activity_popup_header",
      assign_activity_button: "components.notifications.assign_activity_button",
      do_not_assign_activity_button: "components.notifications.do_not_assign_activity_button",
      deadline_message: "components.notifications.deadline_message",
      view_malfunction_button: "components.notifications.view_malfunction_button",
      deadline_message_activity_in_progress: "components.notifications.deadline_message_activity_in_progress",
      error_assign_activity: "components.notifications.error_assign_activity",
      error_activity_already_assigned: "components.notifications.error_activity_already_assigned",
    },
    schedule_cleaning_objects: {
      no_cleaning_objects: "components.schedule_cleaning_objects.no_cleaning_objects",
      no_cleaning_objects_filter: "components.schedule_cleaning_objects.no_cleaning_objects_filter",
      cleaningobjects: "components.schedule_cleaning_objects.cleaningobjects",
      cleaningobjects_completed_title: "components.schedule_cleaning_objects.cleaningobjects_completed_title",
      cleaningobjects_completed_subtext: "components.schedule_cleaning_objects.cleaningobjects_completed_subtext",
      filter_modal: {
        filter: "components.schedule_cleaning_objects.filter_modal.filter",
        floor: "components.schedule_cleaning_objects.filter_modal.floor",
        select: "components.schedule_cleaning_objects.filter_modal.select",
        selected: "components.schedule_cleaning_objects.filter_modal.selected",
        all_items_are_selected: "components.schedule_cleaning_objects.filter_modal.all_items_are_selected",
        reset: "components.schedule_cleaning_objects.filter_modal.reset",
      },
    },
    search: {
      placeholder: "components.search.placeholder",
    },
    venue_overview: {
      no_venues: "components.venue_overview.no_venues",
      no_venues_filter: "components.venue_overview.no_venues_filter",
      today: "components.venue_overview.today",
    },
    customer_selection: {
      number_of_buildings_single: "components.customer_selection.number_of_buildings_single",
      number_of_buildings_multiple: "components.customer_selection.number_of_buildings_multiple",
      title: "components.customer_selection.title",
    },
    logbook: {
      header: "components.logbook.header",
      last_sender: "components.logbook.last_sender",
      message_single: "components.logbook.message_single",
      message_plural: "components.logbook.message_plural",
      select_venue: "components.logbook.select_venue",
      messages_component: {
        message_input_placeholder: "components.logbook.messages_component.message_input_placeholder",
        error_sending_message: "components.logbook.messages_component.error_sending_message",
        error_fetch_messages: "components.logbook.messages_component.error_fetch_messages",
        error_fetch_channels: "components.logbook.messages_component.error_fetch_channels",
        successfully_sent_message: "components.logbook.messages_component.successfully_sent_message",
      },
      single_message_component: {
        new_message_line: "components.logbook.single_message_component.new_message_line",
      },
    },
  },
};

export default TranslationMapper;
