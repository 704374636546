import ManualTagInputModal from "components/modal/manualTagInputModal";
import CheckoutTriggerType from "enums/checkoutTriggerType";
import TranslationMapper from "i18n/mapper";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import FeatureFlagProvider, { FlagNames } from "providers/featureFlagProvider";
import LanguageProvider from "providers/languageProvider";
import NativeCommunicationProvider, { INativeShellCallback } from "providers/nativeCommunicationProvider";
import React from "react";
import NotificationManager from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniversalReferenceObjectAsync, setScannedFloor } from "store/actions/scheduleActions";
import { RootState } from "store/reducers/rootReducer";
import NFCValidator from "utils/nfcValidator";
import { isNullOrEmpty } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CleaningObjectOpenNotifications from "./cleaningObjectOpenNotifications";
import { IFloorGroupState } from "./interfaces/IFloorGroupState";
import IFloorGroupProps, { IFloorGroupDispatchProps, IFloorGroupStateProps } from "./interfaces/IFloorGroupStateProps";
import ScheduleCleaningObject from "./scheduleCleaningObject";

class ScheduleCleaningObjectsFloorAccordion extends React.Component<IFloorGroupProps, IFloorGroupState> {
  public constructor(props: IFloorGroupProps) {
    super(props);

    this.state = {
      isAccordionOpen: this.props.scannedfloor === this.props.floor.id,
      isFloorNfcRequired: this.props.isFloorNFCRequired ?? false,
      isFloorNfcUnlocked: this.props.scannedfloor === this.props.floor.id,
      showManualNFCInputModal: false,
    };

    this.toggleAccordionStateOverview = this.toggleAccordionStateOverview.bind(this);
    this.unlockNfc = this.unlockNfc.bind(this);
    this.startScan = this.startScan.bind(this);
    this.toggleManualTagInput = this.toggleManualTagInput.bind(this);
    this.onReceiveNFCMessage = this.onReceiveNFCMessage.bind(this);
    this.onReceiveManualNFCMessage = this.onReceiveManualNFCMessage.bind(this);
    this.setScannedFloor = this.setScannedFloor.bind(this);
  }

  private setScannedFloor(floorId: string): void {
    this.props.setScannedFloor(floorId);
  }

  private toggleAccordionStateOverview(): void {
    const useManualNfcInput =
      !NativeCommunicationProvider.isNativeShellActive() &&
      FeatureFlagProvider.isFeatureActive(FlagNames.CleaningApp_FF_Show_Old_Manual_NFC_Button);

    if (this.state.isFloorNfcRequired && !this.state.isFloorNfcUnlocked) {
      if (useManualNfcInput) {
        // Fallback for development, allow for manual input without trigger from native shell.
        this.toggleManualTagInput();
      } else {
        this.startScan();
      }
    } else {
      this.setState({
        isAccordionOpen: !this.state.isAccordionOpen,
      });
    }
  }

  private startScan(): void {
    const callbackOnScan: INativeShellCallback = {
      function: this.onReceiveNFCMessage,
      functionName: "locationOverviewOnReceiveNFC",
    };

    const callbackOpenManualInput: INativeShellCallback = {
      function: this.toggleManualTagInput,
      functionName: "locationOverviewOpenManualInputModal",
    };

    NativeCommunicationProvider.initiateNFCScanner(callbackOnScan, callbackOpenManualInput);
  }

  private onReceiveNFCMessage(input: any): void {
    const nfcMessage = NFCValidator.tryParseNfcMessage(input);

    if (!nfcMessage) {
      return;
    }

    const nfcRequest: INfcReferenceObjectRequest = {
      id: nfcMessage.id,
      code: nfcMessage.message,
    };

    this.props.onNfcTagScanned(nfcRequest, this.props.history, CheckoutTriggerType.NfcTagScanned, undefined, true);
  }

  private onReceiveManualNFCMessage(nfcTag: string): void {
    if (isNullOrEmpty(nfcTag)) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.invalid_nfc));
      return;
    }

    const nfcRequest: INfcReferenceObjectRequest = {
      id: undefined,
      code: nfcTag,
    };

    this.props.onNfcTagScanned(nfcRequest, this.props.history, CheckoutTriggerType.NfcTagManual, undefined, true);
  }

  private unlockNfc(): void {
    this.setScannedFloor(this.props.floor.id);
    this.setState({ isFloorNfcUnlocked: true, isAccordionOpen: true });
  }

  private toggleManualTagInput(): void {
    this.setState({
      showManualNFCInputModal: !this.state.showManualNFCInputModal,
    });
  }

  private get shouldRenderCleaningObjects(): boolean {
    return this.state.isAccordionOpen || this.props.isSearchStringFilterApplied;
  }

  private get renderContentEnd(): JSX.Element {
    if (this.props.floor.cleaningObjects.length !== 0) {
      if (this.state.isFloorNfcRequired && !this.state.isFloorNfcUnlocked) {
        return <FontAwesomeIcon icon={["fal", "mobile-signal"]} fixedWidth className="align-middle" />;
      }

      if (this.shouldRenderCleaningObjects) {
        return <FontAwesomeIcon icon={["fal", "chevron-up"]} fixedWidth className="align-middle" />;
      }

      if (!this.shouldRenderCleaningObjects) {
        return <FontAwesomeIcon icon={["fal", "chevron-down"]} fixedWidth className="align-middle" />;
      }
    }
    return <></>;
  }

  private get renderCleaningObjects(): JSX.Element {
    return (
      <div className="btn--content__accordion-items">
        {this.props.floor.cleaningObjects.map(cleaningObject => (
          <ScheduleCleaningObject key={cleaningObject.id} cleaningObject={cleaningObject} isAccordionItem={true} />
        ))}
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <button
          className={`btn btn--content${this.shouldRenderCleaningObjects ? " rounded-bottom-0" : ""}`}
          onClick={this.toggleAccordionStateOverview}
        >
          <div className="btn--content_block">
            <div>
              <div className="btn--content__content">
                <h2>{`${this.props.floor.name} (${this.props.floor.cleaningObjects.length})`}</h2>
              </div>
              <div className="btn--content__message">
                <CleaningObjectOpenNotifications
                  slaNotificationCount={
                    this.props.floor.cleaningObjects.filter(cleaningObjects => cleaningObjects.nextSlaDateTime != null)
                      .length
                  }
                  notifications={this.props.floor.cleaningObjects.flatMap(
                    cleaningObject => cleaningObject.notifications
                  )}
                />
              </div>
            </div>
            <div className="btn--content__end">{this.renderContentEnd}</div>
          </div>
        </button>
        {this.shouldRenderCleaningObjects && this.renderCleaningObjects}

        <ManualTagInputModal
          show={this.state.showManualNFCInputModal}
          onClose={this.toggleManualTagInput}
          onGetByManualInput={this.onReceiveManualNFCMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IFloorGroupStateProps => ({
  scannedfloor: state.scheduleState.lastScannedFloor,
  activeLocation: state.scheduleState.selectedLocation,
});

const mapDispatchToProps: IFloorGroupDispatchProps = {
  onNfcTagScanned: getUniversalReferenceObjectAsync,
  setScannedFloor: setScannedFloor,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleCleaningObjectsFloorAccordion));
