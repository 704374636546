import IFeatureFlag from "interfaces/IFeatureFlag";

export const FlagNames = {
  CleaningApp_FF_Is_Clarity_Active: "CleaningApp_FF_Is_Clarity_Active",
  // Don't remove: Needed for testing purposes, without this button, nfc prompt can't be triggered without native shell.
  CleaningApp_FF_Show_Old_Manual_NFC_Button: "CleaningApp_FF_Show_Old_Manual_NFC_Button",
};

export default class FeatureFlagProvider {
  public static flagState: IFeatureFlag[] = [];

  public static manageFlagChanges(flagStoreState: IFeatureFlag[]): void {
    // Keep up to date with redux, making it possible for unconnected components to use flags
    this.flagState = flagStoreState;
  }

  public static isFeatureActive(flagName: string, flagStoreState?: IFeatureFlag[]): boolean {
    const flags = flagStoreState ?? this.flagState;
    return flags.find(f => f.flagName === flagName)?.active === true;
  }
}
