import { TranslationMapper } from "i18n/mapper";
import * as React from "react";
import { connect } from "react-redux";
import { clearError } from "store/actions/generalActions";
import { RootState } from "store/reducers/rootReducer";

import ErrorDisplay from "../components/errorDisplay/errorDisplay";
import IErrorPageProps, { IErrorPageDispatchProps, IErrorPageStateProps } from "./interfaces/IErrorPageProps";

export class ErrorPage extends React.Component<IErrorPageProps> {
  public constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    this.props.resetError();
  }

  public componentDidUpdate(): void {
    this.props.resetError();
  }

  public render(): JSX.Element {
    return (
      <div>
        <ErrorDisplay
          errorCode={this.props.errorCode}
          errorTitle={TranslationMapper.global.errors.uncaught_exception_title}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IErrorPageStateProps => ({
  error: state.generalState.error,
  errorCode: "0xD87A",
});

const mapDispatchToProps: IErrorPageDispatchProps = {
  resetError: clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
