import IActivityStatusUpdate from "../interfaces/IActivityStatusUpdate";
import IReactSelectValue from "../interfaces/IReactSelectValue";
import ActivityClient from "./clients/activityClient";

export default class ActivityService {
  private readonly activityClient: ActivityClient;

  public constructor() {
    this.activityClient = new ActivityClient();
  }

  public async finishActivitiesAsync(statusUpdate: IActivityStatusUpdate[]): Promise<Response> {
    return this.activityClient.finishActivitiesAsync(statusUpdate);
  }

  public async getCancellationReasonsAsync(): Promise<IReactSelectValue[]> {
    return this.activityClient.getCancellationReasonsAsync();
  }

	public async assignOperatorToActivityAsync(activityIds: string[], customerId: string): Promise<Response> {
		return this.activityClient.assignOperatorToActivityAsync(activityIds, customerId);
	}
}
