import { ILogBookStoreState, initialLogBookStoreState } from "store/state/logbookStoreState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

function logbookReducer(
  state: ILogBookStoreState = initialLogBookStoreState,
  action: ActionTypesCollection
): ILogBookStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_LOGBOOK_CHANNEL_MESSAGES:
      return { ...state, selectedChannelMessages: action.payload };
    case ActionTypes.FETCHED_LOGBOOK:
      return { ...state, logbook: action.payload };
    default:
      return state;
  }
}

export default logbookReducer;
