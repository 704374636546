import { combineReducers } from "redux";

import activityReducer from "./activityReducer";
import customerReducer from "./customerReducer";
import generalReducer from "./generalReducer";
import logbookReducer from "./logbookReducer";
import notificationReducer from "./notificationReducer";
import scheduleReducer from "./scheduleReducer";

const rootReducer = combineReducers({
  generalState: generalReducer,
  scheduleState: scheduleReducer,
  activityState: activityReducer,
  customerState: customerReducer,
  notificationState: notificationReducer,
  logbookState: logbookReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
