import IWizardStep from "components/wizard/interfaces/IWizardStep";
import { TranslationMapper } from "i18n/mapper";
import INotificationCategoryItem from "interfaces/INotificationCategoryItem";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";

import INotificationCategoryStepProps from "./interfaces/INotificationCategoryStepProps";
import INotificationCategoryStepState from "./interfaces/INotificationCategoryStepState";
import NotificationValidator from "./notificationValidator";

class NotificationCategoryStep extends Component<INotificationCategoryStepProps, INotificationCategoryStepState> {
  public constructor(props: INotificationCategoryStepProps) {
    super(props);

    const state = this.props.value ?? {
      selectedCategoryId: "",
    };

    this.state = state;

    this.getNotificationImageUri = this.getNotificationImageUri.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
  }

  private selectCategory(category: INotificationCategoryItem): void {
    this.setState((current) => ({ ...current, selectedCategoryId: category.id }), this.propagateChange);
  }

  private propagateChange(): void {
    const isValid = NotificationValidator.isCategoryValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private getNotificationImageUri(notificationCategory: INotificationCategoryItem): string {
    if (notificationCategory.id === this.state.selectedCategoryId) {
      return `${notificationCategory.imageUri}_Notificationcategory_active`;
    }
    return `${notificationCategory.imageUri}_Notificationcategory_inactive`;
  }

  public renderCategories(): JSX.Element {
    return (
      <div className="row category-row">
        {this.props.categories.length > 0 &&
          this.props.categories.map((category, index) => (
            <div
              key={index}
              onClick={(): void => this.selectCategory(category)}
              className={`btn btn--image-label mb-3 ${
                category.id === this.state.selectedCategoryId ? "active" : "inactive"
              }`}
            >
              <img src={this.getNotificationImageUri(category)} alt="notification category" />
            </div>
          ))}
      </div>
    );
  }

  public render(): ReactNode {
    return (
      <Form>
        <Row>
          <Form.Group as={Col} sm={12}>
            <Form.Label>{LanguageProvider.t(TranslationMapper.components.notifications.select_category)}</Form.Label>
            {this.renderCategories()}
          </Form.Group>
        </Row>
      </Form>
    );
  }
}

const wizardStep: IWizardStep = {
  form: NotificationCategoryStep,
  titleResource: "",
  name: "category",
};

export default wizardStep;
