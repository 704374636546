import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";
import { getAllScheduledLocationsAsync } from "./scheduleActions";

export const setCustomer = actionCreator<ActionTypes.SET_CUSTOMER, string>(ActionTypes.SET_CUSTOMER);

export function setActiveCustomer(customerId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(setCustomer(customerId));
    dispatch(getAllScheduledLocationsAsync());
  };
}
