import { Component } from "react";

import ISystemNotificationBarProps, { SystemNotificationPriority } from "./interfaces/ISystemNotificationBarProps";

class SystemNotificationBar extends Component<ISystemNotificationBarProps> {
  public render(): JSX.Element {
    return (
      <div
        onClick={this.props.onClick}
        className={`alert m-3 ${
          SystemNotificationPriority[this.props.messagePriority] === SystemNotificationPriority.medium
            ? "alert-info"
            : "alert-warning"
        }${this.props.isHidden ? " d-none" : ""} ${this.props.className ?? ""}`}
      >
        <div className="message" onClick={this.props.onClick}>
          {this.props.children}
          {this.props.message}
        </div>
      </div>
    );
  }
}

export default SystemNotificationBar;
