import CheckoutTriggerType from "enums/checkoutTriggerType";
import ICleaningObject from "interfaces/ICleaningObject";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Nfc from "../../components/nfc/nfc";
import ScheduleCleaningObjects from "../../components/scheduleCleaningObjects/scheduleCleaningObjects";
import LoaderTypes from "../../enums/loaderTypes";
import { NfcScanType } from "../../enums/nfcScanType";
import emptySchedule from "../../models/emptySchedule";
import { getUniversalReferenceObjectAsync } from "../../store/actions/scheduleActions";
import { RootState } from "../../store/reducers/rootReducer";
import NFCValidator from "../../utils/nfcValidator";
import {
  ISchedulePageDispatchProps,
  ISchedulePageProps,
  ISchedulePageStateProps,
} from "./interfaces/ISchedulePageProps";
import { ISchedulePageState } from "./interfaces/ISchedulePageState";

class SchedulePage extends React.Component<ISchedulePageProps, ISchedulePageState> {
  public constructor(props: ISchedulePageProps) {
    super(props);

    this.state = {};

    this.onReceiveManualTagInput = this.onReceiveManualTagInput.bind(this);
    this.onReceiveNFCMessage = this.onReceiveNFCMessage.bind(this);
  }

  private onReceiveManualTagInput(tag: string): void {
    const nfcRequest: INfcReferenceObjectRequest = {
      id: undefined,
      code: tag,
    };

    this.props.onNfcTagScanned(
      nfcRequest,
      this.props.history,
      CheckoutTriggerType.NfcTagManual,
      this.props.schedule.id
    );
  }

  private onReceiveNFCMessage(input: any): void {
    const nfcMessage = NFCValidator.tryParseNfcMessage(input);

    if (!nfcMessage) {
      return;
    }

    const nfcRequest: INfcReferenceObjectRequest = {
      id: nfcMessage.id,
      code: nfcMessage.message,
    };

    this.props.onNfcTagScanned(
      nfcRequest,
      this.props.history,
      CheckoutTriggerType.NfcTagScanned,
      this.props.schedule.id
    );
  }

  private get showNfc(): boolean {
    return (
      this.props.schedule.floors.some((floor) => floor.hasNfc) ||
      this.cleaningObjects.some((cleaningObject) => cleaningObject.hasNfc)
    );
  }

  private get cleaningObjects(): ICleaningObject[] {
    return this.props.schedule.floors.flatMap((floor) => floor.cleaningObjects) ?? [];
  }

  public render(): JSX.Element {
    return (
      <>
        <ScheduleCleaningObjects
          isLoading={this.props.isLoading}
          schedule={this.props.schedule}
          nfcFloorFilter={this.props.scannedFloorId}
        />
        {this.showNfc && (
          <Nfc
            scanType={NfcScanType.Machine}
            onGetByManualInput={this.onReceiveManualTagInput}
            callback={this.onReceiveNFCMessage}
            message={this.state.nfcMessage}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ISchedulePageStateProps => ({
  schedule: state.scheduleState.schedule ?? emptySchedule, // no schedule available could be the case when this page is called directly by the user
  isLoading: state.generalState.loaders.some((loader) => loader === LoaderTypes.Schedule || LoaderTypes.NfcScan),
  scannedFloorId: state.scheduleState.scannedFloorId,
});

const mapDispatchToProps: ISchedulePageDispatchProps = {
  onNfcTagScanned: getUniversalReferenceObjectAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchedulePage));
