import AllActivitiesCompletedImage from "assets/img/illustration/all-activities-completed.svg";
import Loader from "components/loader/loader";
import SearchBar from "components/search/searchBar";
import { TranslationMapper } from "i18n/mapper";
import IFloor from "interfaces/IFloor";
import IReactSelectValue from "interfaces/IReactSelectValue";
import LanguageProvider from "providers/languageProvider";
import React from "react";

import HeaderBar from "./headerBar/headerBar";
import { IScheduleCleaningObjectsProps } from "./interfaces/IScheduleCleaningObjectsProps";
import { IScheduleCleaningObjectsState } from "./interfaces/IScheduleCleaningObjectsState";
import ScheduleCleaningObject from "./scheduleCleaningObject";
import ScheduleCleaningObjectsFloorAccordion from "./scheduleCleaningObjectsFloorAccordion";

class ScheduleCleaningObjects extends React.Component<IScheduleCleaningObjectsProps, IScheduleCleaningObjectsState> {
  public constructor(props: IScheduleCleaningObjectsProps) {
    super(props);
    this.state = {
      searchString: "",
      floorFilter: this.props.nfcFloorFilter ? [{ value: this.props.nfcFloorFilter, label: "" }] : [],
    };

    this.onSearchStringChanged = this.onSearchStringChanged.bind(this);
    this.onFloorSelectionChanged = this.onFloorSelectionChanged.bind(this);
  }

  public componentDidUpdate(prevProps: IScheduleCleaningObjectsProps): void {
    if (!prevProps.nfcFloorFilter && this.props.nfcFloorFilter) {
      this.setState({
        searchString: "",
        floorFilter: this.props.nfcFloorFilter ? [{ value: this.props.nfcFloorFilter, label: "" }] : [],
      });
    } else if (prevProps.nfcFloorFilter && !this.props.nfcFloorFilter && this.state.floorFilter) {
      this.setState({
        floorFilter: [],
      });
    }
  }

  private get schedule(): IFloor[] {
    let schedule = this.props.schedule.floors;

    if (this.state.floorFilter.length > 0) {
      schedule = schedule.filter(
        floor => this.state.floorFilter.length > 0 && this.state.floorFilter.some(f => f.value === floor.id)
      );
    }

    if (this.isSearchStringFilterApplied) {
      // Flatten nested cleaningObject[] and apply filter. Then remove all empty floor objects.
      schedule = schedule
        .flatMap(floor => {
          return {
            ...floor,
            cleaningObjects: floor.cleaningObjects.filter(c =>
              c.name.toLocaleLowerCase().includes(this.state.searchString.toLocaleLowerCase())
            ),
          };
        })
        .filter(floor => floor.cleaningObjects?.length !== 0);
    }

    return schedule;
  }

  private get isSearchStringFilterApplied(): boolean {
    return !!this.state.searchString;
  }

  private get hasCleaningObjects(): boolean {
    return this.props.schedule.floors.some(f => f.cleaningObjects.length > 0);
  }

  private get showCleaningObjects(): boolean {
    return !this.props.isLoading && this.hasCleaningObjects;
  }

  private get showNoCleaningObjectsFilter(): boolean {
    return this.showCleaningObjects && this.isSearchStringFilterApplied && this.schedule.length === 0;
  }

  private onSearchStringChanged(value: string): void {
    this.setState({
      searchString: value,
    });
  }

  private renderNoCleaningObjects(translationKey: string): JSX.Element {
    return <h3>{LanguageProvider.t(TranslationMapper.components.schedule_cleaning_objects[translationKey])}</h3>;
  }

  private get renderCleaningObjectsCompletedItem(): JSX.Element {
    return (
      <div className="container bg-white pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <img src={AllActivitiesCompletedImage} className="illustration mb-4" alt="cleaning objects completed" />
            <h2 className="text-infographic">
              {LanguageProvider.t(
                TranslationMapper.components.schedule_cleaning_objects.cleaningobjects_completed_title
              )}
            </h2>
            <p>
              {LanguageProvider.t(
                TranslationMapper.components.schedule_cleaning_objects.cleaningobjects_completed_subtext
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  private onFloorSelectionChanged(selected: IReactSelectValue[]): void {
    this.setState({
      floorFilter: selected,
    });
  }

  private get floors(): IReactSelectValue[] {
    return this.props.schedule.floors.map(floor => {
      return { value: floor.id, label: floor.name };
    });
  }

  private get cleaningObjectsCount(): string {
    return `${this.schedule.flatMap(floor => floor.cleaningObjects).length}`;
  }

  public render(): JSX.Element {
    return (
      <div className="content content--with-nfc">
        <div className="container-fluid">
          <div className="row">
            {this.props.isLoading && <Loader isLoading={this.props.isLoading} />}
            {!this.props.isLoading && (
              <>
                <div className="col-12">
                  <SearchBar onChange={this.onSearchStringChanged} />
                </div>
                <div className="col-12 block-content--px block-content--py">
                  <HeaderBar
                    floors={this.floors}
                    selectedFloors={this.state.floorFilter}
                    cleaningObjectsCount={this.cleaningObjectsCount}
                    onChange={this.onFloorSelectionChanged}
                  />

                  {!this.hasCleaningObjects && !this.props.isLoading && this.renderCleaningObjectsCompletedItem}
                  {this.showNoCleaningObjectsFilter &&
                    !this.props.isLoading &&
                    this.renderNoCleaningObjects("no_cleaning_objects_filter")}

                  {this.showCleaningObjects &&
                    (this.props.schedule.groupByFloors
                      ? this.schedule.map(floor => {
                          if (floor.cleaningObjects.length === 0) {
                            return null;
                          }
                          return (
                            <ScheduleCleaningObjectsFloorAccordion
                              key={floor.id}
                              floor={floor}
                              isSearchStringFilterApplied={this.isSearchStringFilterApplied}
                              isFloorNFCRequired={this.props.schedule.isFloorNFCRequired}
                            />
                          );
                        })
                      : this.schedule
                          .flatMap(floor => floor.cleaningObjects)
                          .map(cleaningObject => (
                            <ScheduleCleaningObject key={cleaningObject.id} cleaningObject={cleaningObject} />
                          )))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleCleaningObjects;
