import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { Component } from "react";
import { Form } from "react-bootstrap";

import ISearchBarProps from "./interfaces/ISearchBarProps";
import ISearchBarState from "./interfaces/ISearchBarState";

export default class SearchBar extends Component<ISearchBarProps, ISearchBarState> {
  public constructor(props: ISearchBarProps) {
    super(props);

    this.state = {
      searchString: "",
    };

    this.onChange = this.onChange.bind(this);
  }

  private onChange(event: any): void {
    this.setState({
      searchString: event.target.value,
    });
    this.props.onChange(event.target.value);
  }

  public render(): JSX.Element {
    return (
      <div className="p-3 search-bar">
        <Form.Control
          onChange={this.onChange}
          className="form-control--icon--end form-control--icon--magnifying-glass"
          placeholder={LanguageProvider.t(TranslationMapper.components.search.placeholder)}
          value={this.state.searchString}
        ></Form.Control>
      </div>
    );
  }
}
