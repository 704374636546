import { CmApiAuthenticator } from "../../authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "../../config";
import BaseClient from "./baseClient";

export default class UserClient extends BaseClient {
  private endpoint: string = "user";

  public constructor() {
    super(ApplicationConfig.endpoints.cmApi, new CmApiAuthenticator());
  }

  public async ensureAccessAsync(): Promise<boolean> {
    return true;
  }
}
