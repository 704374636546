import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { Form } from "react-bootstrap";

import { IFeedbackRequestAnswer } from "../../interfaces/IFeedbackRequestAnswer";
import { isNullOrEmpty, toLocaleString } from "../../utils/stringUtils";
import BaseInput from "./baseInput";

export default class SelectInput extends BaseInput {
  private get options(): IFeedbackRequestAnswer[] {
    if (!this.props.answerOptions) {
      return [];
    }

    const placeholderValue: IFeedbackRequestAnswer[] = [
      {
        id: undefined,
        answer: LanguageProvider.t(TranslationMapper.components.activities.select_answer),
      },
    ];

    return placeholderValue.concat(this.props.answerOptions);
  }

  private getAnswerDescription(answer?: string | number): string {
    const answerText = toLocaleString(answer);

    if (isNullOrEmpty(answerText)) {
      return "";
    }

    const translationMapper =
      TranslationMapper.components.activities.feedback[LanguageProvider.keyToMapperKey(answerText)];

    if (translationMapper == null) {
      return answerText;
    }
    return LanguageProvider.t(translationMapper);
  }

  private renderOptions(): JSX.Element {
    return (
      <>
        {this.options.map((option, index) => {
          return (
            <option hidden={option.id == null} key={index} value={option.id}>
              {this.getAnswerDescription(option.answer)}
            </option>
          );
        })}
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <Form.Control
        as="select"
        id={this.props.id}
        name={this.props.id}
        className={this.className}
        onChange={this.onChange}
        disabled={this.props.inputDisabled}
        value={this.props.answer}
      >
        {this.renderOptions()}
      </Form.Control>
    );
  }
}
