import { IGeneralStoreState, initialGeneralStoreState } from "store/state/generalStoreState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

function generalReducer(
  state: IGeneralStoreState = initialGeneralStoreState,
  action: ActionTypesCollection
): IGeneralStoreState {
  switch (action.type) {
    case ActionTypes.START_LOADING:
      return { ...state, loaders: [...state.loaders, action.payload] };
    case ActionTypes.FINISH_LOADING: {
      const updatedLoaders = state.loaders.filter((l) => l !== action.payload);
      return { ...state, loaders: updatedLoaders };
    }
    case ActionTypes.INTERCEPTED_ERROR:
      if (action.payload) {
        return { ...state, error: action.payload, errorCode: "0xDA59" };
      }
      return { ...state, error: undefined, errorCode: undefined };
    case ActionTypes.FETCHED_FEATUREFLAGS: {
      const updatedFeatureFlags = [...state.featureFlags, ...action.payload];
      return { ...state, featureFlags: updatedFeatureFlags };
    }
    default:
      return state;
  }
}

export default generalReducer;
