import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { initialActivityState, IActivityState } from "../state/activityStoreState";

function activityReducer(state: IActivityState = initialActivityState, action: ActionTypesCollection): IActivityState {
  switch (action.type) {
    case ActionTypes.START_LOADING:
      return { ...state, loaders: [...state.loaders, action.payload] };
    case ActionTypes.FINISH_LOADING: {
      return { ...state, loaders: [...state.loaders.filter((l) => l !== action.payload)] };
    }
    case ActionTypes.FETCHED_CANCELLATION_REASONS:
      return { ...state, cancellationReasons: action.payload };
    default:
      return state;
  }
}

export default activityReducer;
