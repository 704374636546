import Button from "components/material/buttons/button";
import ModalComponent from "components/modal/modal";
import { TranslationMapper } from "i18n/mapper";
import IReactSelectValue from "interfaces/IReactSelectValue";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { MultiSelect } from "react-multi-select-component";
import { connect } from "react-redux";
import { clearScannedFloorDispatcher } from "store/actions/scheduleActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IHeaderBarDispatchProps, IHeaderBarProps } from "./interfaces/IHeaderBarProps";
import { IHeaderBarState } from "./interfaces/IHeaderBarState";

class HeaderBar extends React.Component<IHeaderBarProps, IHeaderBarState> {
  public constructor(props: IHeaderBarProps) {
    super(props);

    this.state = {
      isFilterPaneOpen: false,
    };

    this.onFilterButtonClicked = this.onFilterButtonClicked.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onCancelButtonClicked = this.onCancelButtonClicked.bind(this);
    this.onSearchButtonClicked = this.onSearchButtonClicked.bind(this);
    this.onResetFilterButtonClicked = this.onResetFilterButtonClicked.bind(this);
  }

  private onFilterButtonClicked(): void {
    if (this.completedAllObjects) {
      return;
    }

    this.setState({
      isFilterPaneOpen: true,
    });
  }

  private onSelectionChange(select: IReactSelectValue[]): void {
    this.props.clearScannedFloor();
    this.setState({
      floorFilter: select,
    });
  }

  private onCancelButtonClicked(): void {
    this.hide();
  }

  private onResetFilterButtonClicked(): void {
    this.props.onChange([]);
    this.props.clearScannedFloor();
    this.hide();
  }

  private onSearchButtonClicked(): void {
    this.props.onChange(this.state.floorFilter ?? []);
    this.hide();
  }

  private hide(): void {
    this.setState({
      floorFilter: undefined,
      isFilterPaneOpen: false,
    });
  }

  private get hasFilterSettings(): boolean {
    return (
      this.props.selectedFloors.length > 0 ||
      (this.state.floorFilter !== undefined && this.state.floorFilter.length > 0)
    );
  }

  private get completedAllObjects(): boolean {
    return this.props.floors.length === 0;
  }

  private get renderHeader(): JSX.Element {
    return (
      <>
        <div className="modal-header__nav-spacing d-flex">
          {this.hasFilterSettings && (
            <Button
              className="btn-sm btn--element-end btn--reset-filter"
              data-dismiss="modal"
              aria-label="Reset"
              label={LanguageProvider.t(TranslationMapper.components.schedule_cleaning_objects.filter_modal.reset)}
              onClick={this.onResetFilterButtonClicked}
              iconEnd={["fal", "xmark"]}
            />
          )}
        </div>

        <div className="modal-header__info">
          <h1 className="modal-title">
            {LanguageProvider.t(TranslationMapper.components.schedule_cleaning_objects.filter_modal.filter)}
          </h1>
        </div>
        <div className="modal-header__nav-spacing"></div>
      </>
    );
  }

  private get renderContent(): JSX.Element {
    return (
      <div className="container-fluid block-content--px block-content--py bg-white h-100">
        <h3 className="text-tertiary-900">
          {LanguageProvider.t(TranslationMapper.components.schedule_cleaning_objects.filter_modal.floor)}
        </h3>
        <MultiSelect
          options={this.props.floors}
          value={this.state.floorFilter ?? this.props.selectedFloors ?? []}
          labelledBy="Select"
          onChange={this.onSelectionChange}
          hasSelectAll={false}
          disableSearch={true}
          overrideStrings={{
            selectSomeItems: LanguageProvider.t(
              TranslationMapper.components.schedule_cleaning_objects.filter_modal.select
            ),
            allItemsAreSelected: LanguageProvider.t(
              TranslationMapper.components.schedule_cleaning_objects.filter_modal.all_items_are_selected
            ),
          }}
        />
      </div>
    );
  }

  private get renderFooter(): JSX.Element {
    return (
      <div className="d-flex justify-content-center w-100">
        <Button
          className="btn btn-outline-secondary w-50 me-2"
          label={LanguageProvider.t(TranslationMapper.global.buttons.close)}
          onClick={this.onCancelButtonClicked}
          iconEnd={["fal", "xmark"]}
        />
        <Button
          className="btn btn-primary w-50 ms-2"
          label={LanguageProvider.t(TranslationMapper.global.buttons.search)}
          onClick={this.onSearchButtonClicked}
          iconEnd={["fal", "search"]}
        />
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="d-flex justify-content-between mb-3 align-items-center">
          <h1 className="h1--page-title mb-0">
            {LanguageProvider.t(TranslationMapper.components.schedule_cleaning_objects.cleaningobjects)}
            <span className="badge bg-gray-300 text-dark ms-2">{this.props.cleaningObjectsCount}</span>
          </h1>
          <div className="d-inline-block position-relative">
            <button
              className="btn btn-link btn--element-end pe-0"
              disabled={this.completedAllObjects}
              onClick={this.onFilterButtonClicked}
            >
              {LanguageProvider.t(TranslationMapper.global.buttons.filter)}
              <span className="btn btn-primary btn-sm btn--rounded">
                <FontAwesomeIcon icon={["fal", "sliders-simple"]} fixedWidth />
                {this.props.selectedFloors.length > 0 && <span className="btn--rounded--active"></span>}
              </span>
            </button>
          </div>
        </div>
        {
          <ModalComponent
            size="xl"
            show={this.state.isFilterPaneOpen}
            onModalHide={this.onCancelButtonClicked}
            className="modal__fullscreen"
            header={this.renderHeader}
            content={this.renderContent}
            showHeader={true}
            fullscreen={true}
            footerContent={this.renderFooter}
          />
        }
      </>
    );
  }
}

const mapDispatchToProps: IHeaderBarDispatchProps = {
  clearScannedFloor: clearScannedFloorDispatcher,
};

export default connect(null, mapDispatchToProps)(HeaderBar);
