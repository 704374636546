import Loader from "components/loader/loader";
import LoaderTypes from "enums/loaderTypes";
import IMessage from "interfaces/IMessage";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchChannelMessagesForUser } from "store/actions/logbookActions";
import { RootState } from "store/reducers/rootReducer";

import ILogbookMessagesProps, {
  ILogbookMessagesDispatchProps,
  ILogbookMessagesStateProps,
} from "./interfaces/ILogbookMessagesProps";
import ILogbookMessagesState from "./interfaces/ILogbookMessagesState";
import LogbookMessage from "./logbookMessage";

class LogbookMessages extends Component<ILogbookMessagesProps, ILogbookMessagesState> {
  private readonly initialFocusElementRef: React.RefObject<HTMLDivElement>;

  private messageRefreshInterval: NodeJS.Timeout;

  public constructor(props: ILogbookMessagesProps) {
    super(props);

    const state: ILogbookMessagesState = {
      hasScrolledToLastMessage: false,
    };

    this.state = state;

    this.scrollToLastMessage = this.scrollToLastMessage.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.manageMessageRefreshInterval = this.manageMessageRefreshInterval.bind(this);

    this.initialFocusElementRef = React.createRef();
  }

  public shouldComponentUpdate(nextProps: ILogbookMessagesProps): boolean {
    return (
      !this.state.hasScrolledToLastMessage ||
      nextProps.messages.length !== this.props.messages.length ||
      nextProps.selectedTopicId !== this.props.selectedTopicId ||
      nextProps.isLoading !== this.props.isLoading ||
      nextProps.messages.findIndex((m) => m.isLastReadMessage) !==
        this.props.messages.findIndex((m) => m.isLastReadMessage)
    );
  }

  public componentDidUpdate(prevProps: ILogbookMessagesProps): void {
    if (this.props.selectedTopicId && prevProps.selectedTopicId !== this.props.selectedTopicId) {
      this.props.onFetchMessages(this.props.selectedTopicId, true);
      this.manageMessageRefreshInterval(true);
    }

    if (
      (this.initialFocusElementRef && !this.state.hasScrolledToLastMessage) ||
      prevProps.messages.length !== this.props.messages.length ||
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.scrollToLastMessage();
    }
  }

  public componentDidMount(): void {
    if (this.props.selectedTopicId) {
      this.props.onFetchMessages(this.props.selectedTopicId, true);
    }
    this.manageMessageRefreshInterval(true);
  }

  public componentWillUnmount(): void {
    this.manageMessageRefreshInterval(false);
  }

  private manageMessageRefreshInterval(startRefreshInterval: boolean): void {
    clearInterval(this.messageRefreshInterval);
    const selectedTopicId = this.props.selectedTopicId;

    if (!startRefreshInterval || !selectedTopicId) {
      return;
    }

    const intervalInMs = 60000;
    const fetchMessages = (): void => this.props.onFetchMessages(selectedTopicId, false);
    this.messageRefreshInterval = setInterval(fetchMessages, intervalInMs);
  }

  private scrollToLastMessage(): void {
    if (this.initialFocusElementRef.current != null) {
      this.initialFocusElementRef.current.scrollIntoView();
      this.setState({
        hasScrolledToLastMessage: true,
      });
    }
  }

  private renderMessage(message: IMessage, index: number): JSX.Element {
    if (this.props.messages == null || !this.props.selectedTopicId) {
      return <></>;
    }
    const isLastMessage = this.props.messages.length - 1 === index;

    return (
      <div ref={isLastMessage ? this.initialFocusElementRef : undefined} key={index}>
        <LogbookMessage
          dateTime={message.timeStamp}
          senderName={message.senderName}
          messageContent={message.content}
          isFromCurrentUser={message.isCurrentUserSender}
          isLastReadMessage={message.isLastReadMessage}
          isLastMessage={isLastMessage}
        />
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.props.isLoading && <Loader isLoading={this.props.isLoading} />}
        {!this.props.isLoading && this.props.messages && (
          <>{this.props.messages.map((c: IMessage, index: number) => this.renderMessage(c, index))}</>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ILogbookMessagesStateProps => ({
  messages: state.logbookState.selectedChannelMessages?.messages ?? [],
  isLoading: state.generalState.loaders.some((l) => l === LoaderTypes.LogbookChannel),
});

const mapDispatchToProps: ILogbookMessagesDispatchProps = {
  onFetchMessages: fetchChannelMessagesForUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogbookMessages);
