import INotification from "interfaces/INotification";

import ErrorTypes from "../../enums/errorTypes";
import LoaderTypes from "../../enums/loaderTypes";
import INotificationCategoryItem from "../../interfaces/INotificationCategoryItem";

export interface INotificationState {
  loaders: LoaderTypes[];
  errors: ErrorTypes[];
  activeNotification?: INotification;
  notifications?: INotification[];
  notificationCategoriesPerCleaningObjectArray?: [
    {
      cleaningObjectId: string;
      notificationCategories: INotificationCategoryItem[] | undefined;
    }
  ];
}

export const initialNotificationState: INotificationState = {
  loaders: [],
  errors: [],
};
