import { CmApiAuthenticator } from "authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "config";
import IFeatureFlag from "interfaces/IFeatureFlag";

import BaseClient from "./baseClient";

export default class FlagClient extends BaseClient {
  private readonly endpoint: string = "api/Flag";

  public constructor() {
    super(ApplicationConfig.endpoints.cmApi, new CmApiAuthenticator());
  }

  public async GetFlagsByNameAsync(flagNames: string[]): Promise<IFeatureFlag[]> {
    return this.postWithResponse<IFeatureFlag[]>(`${this.endpoint}`, flagNames);
  }
}
