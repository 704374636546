import * as msal from "@azure/msal-browser";

import { UserAuthenticator } from "../authenticators/userAuthenticator";
import UserClient from "./clients/userClient";

export default class UserService {
  private readonly userClient: UserClient;

  private readonly userAuthenticator: UserAuthenticator;

  public constructor() {
    this.userClient = new UserClient();
    this.userAuthenticator = new UserAuthenticator();
  }

  public async ensureAccessAsync(): Promise<boolean> {
    return this.userAuthenticator
      .authenticateUserAsync()
      .then((user: msal.AccountInfo | null) => {
        if (user == null) {
          return false;
        }

        return this.userClient.ensureAccessAsync();
      })
      .catch(error => {
        return false;
      });
  }
}
