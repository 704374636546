import React, { Component } from "react";
import { PulseLoader } from "react-spinners";

import ILoaderProps from "./interfaces/ILoaderProps";

export default class Loader extends Component<ILoaderProps> {
  public render(): JSX.Element {
    return (
      <div className="position-releative">
        <div className="position-absolute top-50 start-50 translate-middle">
          <PulseLoader color={"#00172E"} size={this.props.size ?? 10} margin={"15px"} loading={this.props.isLoading} />
        </div>
      </div>
    );
  }
}
