import * as msal from "@azure/msal-browser";

import MsalFactory from "../factories/msalFactory";
import { MsalAuthenticator } from "./msalAuthenticator";

export class UserAuthenticator extends MsalAuthenticator {
  public async authenticateUserAsync(): Promise<msal.AccountInfo | null> {
    return this.signInAsync();
  }

  public getUser(): msal.AccountInfo {
    const account = MsalFactory.getMsalContext().getAllAccounts();
    return account[0];
  }

  public getName(): string {
    const account = this.getUser();
    return account.name ?? "";
  }

  public signOut(): void {
    MsalFactory.logoutAllAccounts("/");
  }
}
