import LoaderTypes from "enums/loaderTypes";
import DesignSystem from "pages/designSystem/DesignSystem";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";
import EnvironmentUtils from "utils/environmentUtils";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";

import MainHeader from "./components/header/mainHeader";
import ServiceWorker from "./components/serviceWorker/serviceWorker";
import ErrorPage from "./error/pages/errorPage";
import IAppProps, { IAppStateProps } from "./interfaces/IAppProps";
import CleaningObjectPage from "./pages/cleaningObject/cleaningObjectPage";
import HomePage from "./pages/home/homePage";
import NotificationPage from "./pages/notification/notificationPage";
import SchedulePage from "./pages/schedule/schedulePage";
import { RoutingPath } from "./providers/routingProvider";

// Add fontawesome icons to the library
library.add(fal, fas, fad);

class App extends React.Component<IAppProps> {
  public constructor(props: IAppProps) {
    super(props);
  }

  private navRoutes: any = () => (
    <Switch>
      <Redirect exact from={RoutingPath.null} to={RoutingPath.home} />
      <Route exact path={RoutingPath.home} component={HomePage} />
      <Route exact path={RoutingPath.schedule} component={SchedulePage} />
      <Route exact path={RoutingPath.cleaningObject} component={CleaningObjectPage} />
      <Route exact path={RoutingPath.notification} component={NotificationPage} />

      {EnvironmentUtils.isTestOrDevEnvironment() && <Route exact path="/design-system" component={DesignSystem} />}

      <Route exact path={RoutingPath.error} component={this.errorPage} />
      <Redirect to={RoutingPath.home} />
    </Switch>
  );

  private errorPage: (location: any) => JSX.Element = ({ location }) => <ErrorPage />;

  public render(): JSX.Element {
    return (
      <div className={`app${this.props.isLoadingOperatorLocations ? " body-is-loading" : ""}`}>
        <MainHeader />
        <ServiceWorker />
        <main>{this.navRoutes()}</main>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IAppStateProps => ({
  error: state.generalState.error,
  isLoadingOperatorLocations: state.notificationState.loaders.some(loader => loader === LoaderTypes.OperatorLocations),
});

export default withRouter(connect(mapStateToProps)(App));
