import NotificationStatus from "enums/notificationStatus";
import { TranslationMapper } from "i18n/mapper";
import INotification from "interfaces/INotification";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNotificationAsync } from "store/actions/notificationActions";
import { RootState } from "store/reducers/rootReducer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  INotificationsDispatchProps,
  INotificationsProps,
  INotificationsStateProps,
} from "./interfaces/INotificationsProps";
import INotificationsState from "./interfaces/INotificationsState";

class Notifications extends React.Component<INotificationsProps, INotificationsState> {
  public constructor(props: INotificationsProps) {
    super(props);

    this.state = {
      showCreateNotificationModal: false,
    };

    this.openNotificationModal = this.openNotificationModal.bind(this);
    this.onCloseNotificationModal = this.onCloseNotificationModal.bind(this);
  }

  private openNotificationModal(): void {
    this.setState({
      showCreateNotificationModal: true,
    });
  }

  private onCloseNotificationModal(): void {
    this.setState({
      showCreateNotificationModal: false,
    });
  }

  private openNotification(notification: INotification): void {
    if (!notification) {
      return;
    }

    this.props.getNotification(notification.id, this.props.history);
  }

  private get openNotifications(): INotification[] | undefined {
    if (!this.props.notifications) {
      return [];
    }

    return this.props.notifications.filter(
      (not) =>
        not.status === NotificationStatus.Open ||
        not.status === NotificationStatus.ActionRequired ||
        not.status === NotificationStatus.InProgress
    );
  }

  private renderNotification(notification: INotification): JSX.Element {
    return (
      <button
        className="btn btn--content"
        key={notification.id}
        onClick={(): void => this.openNotification(notification)}
      >
        <div className="btn--content_block">
          <div>
            <div className="btn--content__content d-flex justify-content-between">
              <h2>{LanguageProvider.t(TranslationMapper.components.notifications.title)}</h2>
            </div>
            <div className="btn--content__message">
              <div className="btn--content__message-info">
                <span className="fa-stack">
                  <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                  <FontAwesomeIcon icon={["fas", "circle-exclamation"]} fixedWidth className="fa-stack-1x fa-xs" />
                </span>
                <span>{notification.category?.name}</span>
              </div>
            </div>
          </div>
          <div className="btn--content__end">
            <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
          </div>
        </div>
      </button>
    );
  }

  public render(): JSX.Element {
    return (
      <div className={this.openNotifications && this.openNotifications.length > 0 ? "mb-3" : ""}>
        {this.openNotifications?.map((n) => {
          return this.renderNotification(n);
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationsStateProps => ({
  notifications: state.scheduleState.selectedCleaningObject?.notifications ?? [],
});

const mapDispatchToProps: INotificationsDispatchProps = {
  getNotification: getNotificationAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
