import Logbook from "components/logbook/logbook";
import ManualTagInputModal from "components/modal/manualTagInputModal";
import SearchBar from "components/search/searchBar";
import CheckoutTriggerType from "enums/checkoutTriggerType";
import { TranslationMapper } from "i18n/mapper";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import moment from "moment";
import FeatureFlagProvider, { FlagNames } from "providers/featureFlagProvider";
import LanguageProvider from "providers/languageProvider";
import NativeCommunicationProvider, { INativeShellCallback } from "providers/nativeCommunicationProvider";
import React from "react";
import NotificationManager from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getScheduleByIdAsync,
  getUniversalReferenceObjectAsync,
  setActiveLocation,
} from "store/actions/scheduleActions";
import { isNullOrEmpty } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loader from "../../components/loader/loader";
import LoaderTypes from "../../enums/loaderTypes";
import ILocationOverview from "../../interfaces/ILocationOverview";
import { RootState } from "../../store/reducers/rootReducer";
import NFCValidator from "../../utils/nfcValidator";
import ILocationOverviewProps, {
  ILocationOverviewDispatchProps,
  ILocationOverviewStateProps,
} from "./interfaces/ILocationOverviewProps";
import { ILocationOverviewState } from "./interfaces/ILocationOverviewState";

class LocationOverview extends React.Component<ILocationOverviewProps, ILocationOverviewState> {
  public constructor(props: ILocationOverviewProps) {
    super(props);

    this.state = {
      searchString: "",
      showLogbook: false,
      showManualNFCInputModal: false,
    };

    this.onSearchStringChanged = this.onSearchStringChanged.bind(this);
    this.handleSetActiveLocation = this.handleSetActiveLocation.bind(this);
    this.setActiveLocation = this.setActiveLocation.bind(this);
    this.closeLogbook = this.closeLogbook.bind(this);
    this.startScan = this.startScan.bind(this);
    this.onReceiveNFCMessage = this.onReceiveNFCMessage.bind(this);
    this.onReceiveManualNFCMessage = this.onReceiveManualNFCMessage.bind(this);
    this.toggleManualTagInput = this.toggleManualTagInput.bind(this);
  }

  public componentDidMount(): void {
    if (this.singleCleaningLocation && !this.props.locations[0].hasNfc) {
      this.handleSetActiveLocation(this.props.locations[0]);
    }
  }

  private startScan(): void {
    const callbackOnScan: INativeShellCallback = {
      function: this.onReceiveNFCMessage,
      functionName: "locationOverviewOnReceiveNFC",
    };

    const callbackOpenManualInput: INativeShellCallback = {
      function: this.toggleManualTagInput,
      functionName: "locationOverviewOpenManualInputModal",
    };

    NativeCommunicationProvider.initiateNFCScanner(callbackOnScan, callbackOpenManualInput);
  }

  private onReceiveNFCMessage(input: any): void {
    const nfcMessage = NFCValidator.tryParseNfcMessage(input);

    if (!nfcMessage) {
      return;
    }

    const nfcRequest: INfcReferenceObjectRequest = {
      id: nfcMessage.id,
      code: nfcMessage.message,
    };

    this.props.onNfcTagScanned(nfcRequest, this.props.history, CheckoutTriggerType.NfcTagScanned);
  }

  private onReceiveManualNFCMessage(nfcTag: string): void {
    if (isNullOrEmpty(nfcTag)) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.invalid_nfc));
      return;
    }

    const nfcRequest: INfcReferenceObjectRequest = {
      id: undefined,
      code: nfcTag,
    };

    this.props.onNfcTagScanned(nfcRequest, this.props.history, CheckoutTriggerType.NfcTagManual);
  }

  private get dateText(): string {
    return `${LanguageProvider.t(TranslationMapper.components.venue_overview.today)} ${moment().format("DD MMMM")}`;
  }

  private get filteredLocations(): ILocationOverview[] {
    if (this.state.searchString == null) {
      return this.props.locations;
    }

    return this.props.locations.filter(c =>
      c.name.toLocaleLowerCase().includes(this.state.searchString.toLocaleLowerCase())
    );
  }

  private get singleCleaningLocation(): boolean {
    return this.props.locations.length === 1;
  }

  private get showLocations(): boolean {
    return !this.props.isLoading && this.filteredLocations != null && this.filteredLocations.length > 0;
  }

  private get showNoLocations(): boolean {
    return !this.props.isLoading && (this.props.locations == null || this.props.locations.length === 0);
  }

  private get showNoLocationsFilter(): boolean {
    return (
      !this.props.isLoading &&
      this.props.locations != null &&
      this.props.locations.length > 0 &&
      this.filteredLocations.length === 0
    );
  }

  private onSearchStringChanged(value: string): void {
    this.setState({
      searchString: value,
    });
  }

  private toggleManualTagInput(): void {
    this.setState({
      showManualNFCInputModal: !this.state.showManualNFCInputModal,
    });
  }

  private handleSetActiveLocation(location: ILocationOverview): void {
    const useManualNfcInput =
      !NativeCommunicationProvider.isNativeShellActive() &&
      FeatureFlagProvider.isFeatureActive(FlagNames.CleaningApp_FF_Show_Old_Manual_NFC_Button);

    if (location.isCheckInRequired) {
      if (useManualNfcInput) {
        // Fallback for development, allow for manual input without trigger from native shell.
        this.toggleManualTagInput();
      } else {
        this.startScan();
      }
    } else {
      this.setActiveLocation(location);
    }
  }

  private setActiveLocation(location: ILocationOverview): void {
    this.props.setActiveLocation({
      customerId: location.customerId,
      projectId: location.projectId,
      name: location.name,
    });
    if (location.isScheduledToday) {
      this.props.getScheduleById(this.props.history);
    } else if (location.totalUnreadMessages > 0) {
      this.setState({
        showLogbook: true,
      });
    }
  }

  private closeLogbook(): void {
    this.setState({
      showLogbook: false,
    });
  }

  private numberOfMalfunctionsText(numberOfMalfunctions: number): string {
    const hasMultiple = numberOfMalfunctions > 1;
    const messageLabel = hasMultiple
      ? TranslationMapper.components.notifications.slafailuremultiple
      : TranslationMapper.components.notifications.slafailuresingle;
    return `${numberOfMalfunctions} ${LanguageProvider.t(messageLabel)}`;
  }

  private unreadMessagesText(unreadMessages: number): string {
    const hasMultiple = unreadMessages > 1;
    const messageLabel = hasMultiple
      ? TranslationMapper.components.logbook.message_plural
      : TranslationMapper.components.logbook.message_single;
    return `${unreadMessages} ${LanguageProvider.t(messageLabel)}`;
  }

  private renderLocation(location: ILocationOverview): JSX.Element {
    return (
      <button
        className="btn btn--content"
        key={location.projectId}
        onClick={(): void => this.handleSetActiveLocation(location)}
      >
        <div className="btn--content_block">
          <div>
            <div className="btn--content__content">
              <h2>{location.name}</h2>
            </div>
            <div className="btn--content__message">
              {location.numberOfMalfunctions > 0 && (
                <div className="btn--content__message-info">
                  <span className="fa-stack">
                    <FontAwesomeIcon icon={["fal", "hourglass"]} fixedWidth className="fa-stack-1x" />
                    <FontAwesomeIcon icon={["fas", "circle-exclamation"]} fixedWidth className="fa-stack-1x fa-xs" />
                  </span>
                  <span>{this.numberOfMalfunctionsText(location.numberOfMalfunctions)}</span>
                </div>
              )}

              {location.totalUnreadMessages > 0 && (
                <div className="btn--content__message-info">
                  <span className="fa-stack">
                    <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                    <FontAwesomeIcon icon={["fas", "circle-exclamation"]} fixedWidth className="fa-stack-1x fa-xs" />
                  </span>
                  <span>{this.unreadMessagesText(location.totalUnreadMessages)}</span>
                </div>
              )}
            </div>
          </div>
          <div className="btn--content__end">
            <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
          </div>
        </div>
      </button>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="content content--with-nfc">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {!this.props.isLoading && this.props.locations.length > 0 && (
                  <SearchBar onChange={this.onSearchStringChanged} />
                )}
              </div>
              <div className="col-12 block-content--px block-content--py">
                {this.props.isLoading && <Loader isLoading={this.props.isLoading} />}
                {!this.props.isLoading && <h1 className="h1--page-title mb-3">{this.dateText}</h1>}
                {this.showNoLocations && (
                  <h2 className="text-tertiary">
                    {LanguageProvider.t(TranslationMapper.components.venue_overview.no_venues)}
                  </h2>
                )}
                {this.showNoLocationsFilter && (
                  <h2 className="text-tertiary">
                    {LanguageProvider.t(TranslationMapper.components.venue_overview.no_venues_filter)}
                  </h2>
                )}
                {this.showLocations && this.filteredLocations.map(location => this.renderLocation(location))}
              </div>
            </div>
          </div>
        </div>

        <Logbook
          showLogbook={this.state.showLogbook}
          showLogbookButton={false}
          onCloseLogbook={this.closeLogbook}
          selectedLocationProjectId={this.props.selectedLocation?.projectId}
        />
        <ManualTagInputModal
          show={this.state.showManualNFCInputModal}
          onClose={this.toggleManualTagInput}
          onGetByManualInput={this.onReceiveManualNFCMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ILocationOverviewStateProps => ({
  locations:
    state.scheduleState.locations?.filter(
      l => l.customerId === state.customerState.activeCustomer && (l.isScheduledToday || l.totalUnreadMessages > 0)
    ) ?? [],
  isLoading:
    !state.generalState.loaders.some(loader => loader === LoaderTypes.Schedule) && // not loading while Schedule is loading, prevent flickering
    state.generalState.loaders.some(loader => loader === LoaderTypes.OperatorLocations),
  selectedLocation: state.scheduleState.selectedLocation,
});

const mapDispatchToProps: ILocationOverviewDispatchProps = {
  setActiveLocation: setActiveLocation,
  getScheduleById: getScheduleByIdAsync,
  onNfcTagScanned: getUniversalReferenceObjectAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationOverview));
