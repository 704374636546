import ReferenceType from "enums/referenceType";
import * as H from "history";
import { TranslationMapper } from "i18n/mapper";
import i18next from "i18next";
import ILogbook from "interfaces/ILogbook";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import ISelectedLocation from "interfaces/ISelectedLocation";
import { NotificationManager } from "react-notifications";

import CheckoutTriggerType from "../../enums/checkoutTriggerType";
import LoaderTypes from "../../enums/loaderTypes";
import ICleaningObject from "../../interfaces/ICleaningObject";
import ILocationOverview from "../../interfaces/ILocationOverview";
import ISchedule from "../../interfaces/ISchedule";
import RoutingProvider, { RoutingPath } from "../../providers/routingProvider";
import ScheduleService from "../../services/scheduleService";
import TelemetryService from "../../services/telemetryService";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";
import { setActiveCustomer } from "./customerActions";
import { finishLoading, startLoading } from "./generalActions";
import { fetchLogbookChannelsForUser } from "./logbookActions";
import { getNotificationCategoriesForCleaningObjectAsync } from "./notificationActions";

export const fetchedSchedule = actionCreator<ActionTypes.FETCHED_SCHEDULE, ISchedule | undefined>(
  ActionTypes.FETCHED_SCHEDULE
);
export const fetchedCleaningObject = actionCreator<ActionTypes.FETCHED_CLEANING_OBJECT, ICleaningObject | undefined>(
  ActionTypes.FETCHED_CLEANING_OBJECT
);
export const fetchedLocationOverview = actionCreator<ActionTypes.FETCHED_LOCATION_OVERVIEW, ILocationOverview[]>(
  ActionTypes.FETCHED_LOCATION_OVERVIEW
);
export const setLocation = actionCreator<ActionTypes.SET_ACTIVE_LOCATION, ISelectedLocation | undefined>(
  ActionTypes.SET_ACTIVE_LOCATION
);
export const setActivitiesCheckoutTrigger = actionCreator<
  ActionTypes.SET_ACTIVE_ACTIVITIES_CHECKOUT_TRIGGER,
  CheckoutTriggerType
>(ActionTypes.SET_ACTIVE_ACTIVITIES_CHECKOUT_TRIGGER);
export const setScannedFloorId = actionCreator<ActionTypes.SET_SCANNED_FLOOR, string | undefined>(
  ActionTypes.SET_SCANNED_FLOOR
);

export const setScannedFloor = actionCreator<ActionTypes.SET_LAST_SCANNED_FLOOR, string>(
  ActionTypes.SET_LAST_SCANNED_FLOOR
);

export function clearActiveLocation(): AppAction {
  return (dispatch): void => {
    dispatch(setLocation(undefined));
  };
}

export function setActiveLocation(location: ISelectedLocation): AppAction {
  return (dispatch): void => {
    dispatch(setLocation(location));
    dispatch(setActiveCustomer(location.customerId));
  };
}

export function setActiveActivitiesCheckoutTrigger(triggerType: CheckoutTriggerType): AppAction {
  return (dispatch): void => {
    dispatch(setActivitiesCheckoutTrigger(triggerType));
  };
}

export function getScheduleByIdAsync(routeHistory: H.History, id?: string, redirect: boolean = true): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async (dispatch, getState) => {
    const cleaningScheduleService = new ScheduleService();
    dispatch(clearScannedFloorDispatcher());

    const locationId = id ?? getState().scheduleState.selectedLocation?.projectId;

    if (locationId == null) {
      dispatch(fetchedSchedule(undefined));
      return;
    }

    dispatch(startLoading(LoaderTypes.Schedule));
    const customerId = getState().customerState.activeCustomer;

    return cleaningScheduleService
      .getScheduleByLocationAsync(locationId)
      .then(schedule => {
        dispatch(fetchedSchedule(schedule));
        dispatch(
          setActiveLocation({
            customerId: customerId,
            projectId: locationId,
            name: schedule.locationName ?? "-",
          })
        );
        navigateToSchedule(schedule, routeHistory, redirect);
      })
      .catch(e => {
        TelemetryService.AppInsights?.trackException({
          exception: { name: "getScheduleByIdAsync exception", message: e },
        });
        NotificationManager.error(i18next.t(TranslationMapper.pages.start_page.no_schedule_found));

        dispatch(fetchedSchedule(undefined));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.Schedule));
      });
  };
}

export function getScheduleByFloorAsync(floorId: string, routeHistory: H.History, projectId?: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    const cleaningScheduleService = new ScheduleService();
    const redirect = true;
    dispatch(startLoading(LoaderTypes.Schedule));

    return cleaningScheduleService
      .getScheduleByFloorAsync(floorId, projectId)
      .then(schedule => {
        dispatch(fetchedSchedule(schedule));
        dispatch(setScannedFloorId(floorId));
        navigateToSchedule(schedule, routeHistory, redirect);
      })
      .catch(e => {
        TelemetryService.AppInsights?.trackException({
          exception: { name: "getScheduleByFloorAsync exception", message: e },
        });
        NotificationManager.error(i18next.t(TranslationMapper.pages.start_page.no_schedule_found));

        dispatch(fetchedSchedule(undefined));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.Schedule));
      });
  };
}

export function getCleaningObjectByIdAsync(projectId: string, id: string, routeHistory: H.History): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    const cleaningScheduleService = new ScheduleService();
    dispatch(startLoading(LoaderTypes.CleaningObject));

    return cleaningScheduleService
      .getCleaningObjectByIdAsync(projectId, id)
      .then(cleaningObject => {
        dispatch(fetchedCleaningObject(cleaningObject));
        dispatch(getNotificationCategoriesForCleaningObjectAsync(cleaningObject.id));
        RoutingProvider.invokePushNewRoute(routeHistory, RoutingPath.cleaningObject, { cleaningObjectId: id });
      })
      .catch(error => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "getCleaningObjectByIdAsync exception",
            message: `Something went wrong while retrieving cleaning objects: ${error}`,
          },
        });
        NotificationManager.error(i18next.t(TranslationMapper.pages.schedule_page.no_cleaning_object_found));

        dispatch(fetchedCleaningObject(undefined));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.CleaningObject));
      });
  };
}

export function getAllScheduledLocationsAsync(onSuccess?: () => AppAction): any {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    dispatch(startLoading(LoaderTypes.OperatorLocations));

    const cleaningScheduleService = new ScheduleService();

    return cleaningScheduleService
      .getScheduledLocationsAsync()
      .then(venues => {
        const topicIds = venues.map(v => v.projectId);
        dispatch(fetchedLocationOverview(venues));
        dispatch(fetchLogbookChannelsForUser(false, topicIds, venues));

        if (onSuccess) {
          dispatch(onSuccess());
        }
      })
      .catch(error => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "getVenueOverviewAsync exception",
            message: `Something went wrong while retrieving venue overview: ${error}`,
          },
        });
        NotificationManager.error(i18next.t(TranslationMapper.pages.start_page.no_locations_found));

        dispatch(fetchedLocationOverview([]));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.OperatorLocations));
      });
  };
}

export function getUniversalReferenceObjectAsync(
  nfcReferenceRequestObject: INfcReferenceObjectRequest,
  routeHistory: H.History,
  checkoutTriggerType: CheckoutTriggerType,
  projectId?: string,
  preventRedirect?: boolean
): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    const scheduleService = new ScheduleService();
    dispatch(startLoading(LoaderTypes.NfcScan));

    return scheduleService
      .getUniversalReferenceObject(nfcReferenceRequestObject)
      .then(result => {
        if (result.referenceType === ReferenceType.CleaningObject) {
          if (!projectId) {
            dispatch(fetchedCleaningObject(undefined));
            NotificationManager.error(i18next.t(TranslationMapper.pages.schedule_page.project_not_selected));
            return;
          }
          dispatch(setActiveActivitiesCheckoutTrigger(checkoutTriggerType));
          dispatch(getCleaningObjectByIdAsync(projectId, result.referenceId, routeHistory));
        } else if (result.referenceType === ReferenceType.Floor) {
          // preventRedirect was added for users to open a floor without redirecting to a page with only that floor
          if (!preventRedirect) {
            dispatch(getScheduleByFloorAsync(result.referenceId, routeHistory, projectId));
          }
          dispatch(setScannedFloor(result.referenceId));
        } else if (result.referenceType === ReferenceType.Location) {
          dispatch(getScheduleByIdAsync(routeHistory, result.referenceId));
        }
      })
      .catch(error => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "getUniversalReferenceObjectAsync exception",
            message: `Something went wrong while retrieving reference object: ${error}`,
          },
        });

        NotificationManager.error(i18next.t(TranslationMapper.pages.schedule_page.reference_not_found));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.NfcScan));
      });
  };
}

export function setLastScannedFloor(floorId: string): AppAction {
  return (dispatch): void => {
    dispatch(setScannedFloor(floorId));
  };
}

export function updateUnreadCountForLocations(locations: ILocationOverview[], logbook: ILogbook): AppAction {
  const updatedLocations = locations.map(location => ({
    ...location,
    totalUnreadMessages: logbook.channelResponse.find(c => c.topicId === location.projectId)?.unreadMessagesCount ?? 0,
  }));
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => dispatch(fetchedLocationOverview(updatedLocations));
}

export function clearScannedFloorDispatcher(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => dispatch(setScannedFloorId(undefined));
}

function navigateToSchedule(schedule: ISchedule, routeHistory: H.History, redirect: boolean = true): void {
  if (redirect) {
    RoutingProvider.invokePushNewRoute(routeHistory, RoutingPath.schedule, { scheduleId: schedule.id });
  }
}
