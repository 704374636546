import UnreadMessagesCounter from "components/unreadMessagesCounter/unreadMessagesCounter";
import { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconImageNotFound from "../../assets/img/image-not-found.svg";
import Placeholer600x400 from "../../assets/img/placeholder-600x400.svg";
import DesignSystemExclusive from "./DesignSystemExclusive";
import DesignSystemExclusiveTypes from "./interfaces/DesignSystemExclusiveTypeEnum";
import IDesignSystemIcons from "./interfaces/IDesignSystemProps";
import IDesignSystemState from "./interfaces/IDesignSystemState";

export default class DesignSystem extends Component<{}, IDesignSystemState> {
  public constructor(props: IDesignSystemState) {
    super(props);

    const state: IDesignSystemState = {
      button: {
        isDisabled: false,
      },
      form: {
        isValidated: false,
      },
      input: {
        isDisabled: false,
        isInvalid: false,
      },
      inputSelectCustom: {
        isOpen: false,
      },
    };

    this.state = state;

    this.setButtonDisabledState = this.setButtonDisabledState.bind(this);
    this.setInputDisabledState = this.setInputDisabledState.bind(this);
    this.setInputIsInvalidState = this.setInputIsInvalidState.bind(this);
    this.setInputSelectCustomState = this.setInputSelectCustomState.bind(this);
  }

  private setButtonDisabledState(): void {
    this.setState({
      button: {
        isDisabled: !this.state.button.isDisabled,
      },
    });
  }

  private setInputDisabledState(): void {
    this.setState({
      input: {
        isDisabled: !this.state.input.isDisabled,
        isInvalid: this.state.input.isInvalid,
      },
    });
  }

  private setInputIsInvalidState(): void {
    this.setState({
      input: {
        isDisabled: this.state.input.isDisabled,
        isInvalid: !this.state.input.isInvalid,
      },
      form: {
        isValidated: !this.state.input.isInvalid,
      },
    });
  }

  private setInputSelectCustomState(): void {
    this.setState({
      inputSelectCustom: {
        isOpen: !this.state.inputSelectCustom.isOpen,
      },
    });
  }

  public renderHeader(): JSX.Element {
    return <DesignSystemExclusive type={DesignSystemExclusiveTypes.HEADER} />;
  }

  public renderTypography(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="typography" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Typography
          </h4>
        </div>
        <h1>Header.H1</h1>
        <h2 className="text-infographic">Header.H2.Infographic</h2>
        <h2>Header.H2</h2>
        <h3>Header.H3</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur <a href="./design-system">adipiscing elit</a>, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          officia deserunt mollit anim id est laborum.
        </p>
        <p className="small">
          Text small - Lorem ipsum dolor sit amet, consectetur <a href="./design-system">adipiscing elit</a>, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <hr />
        <div className="d-flex">
          <div className="me-3">
            <ul>
              <li>An item</li>
              <li>A second item</li>
              <li>A third item</li>
            </ul>
          </div>
          <div>
            <ol>
              <li>An item</li>
              <li>A second item</li>
              <li>A third item</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  public renderButton(): JSX.Element {
    const name = "name";
    const imageExists = true;
    const active = true;
    const imageUri =
      "https://ogwijzer.nl/wp-content/uploads/2020/01/39054795_2273313536016451_2148661856313540608_n.jpg";

    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="button" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Buttons
          </h4>
        </div>
        <div>
          <h3>default</h3>
          <div className="d-flex align-items-center mb-4">
            <div className="me-2">
              <button className="btn btn-primary" disabled={this.state.button.isDisabled}>
                BTN.primary
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-secondary" disabled={this.state.button.isDisabled}>
                BTN.secondary
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary" disabled={this.state.button.isDisabled}>
                BTN.outline-secondary
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link" disabled={this.state.button.isDisabled}>
                BTN.link
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn-sm" disabled={this.state.button.isDisabled}>
                BTN.small
              </button>
            </div>
          </div>
          <hr />
          <h3>icons start</h3>
          <div className="d-flex align-items-center mb-4">
            <div className="me-2">
              <button className="btn btn-primary btn--element-start" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth />
                BTN.primary
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-secondary btn--element-start" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth />
                BTN.secondary
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary btn--element-start" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth />
                BTN.outline-secondary
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn--element-start" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fas", "circle-plus"]} fixedWidth />
                BTN.link
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn--element-start" disabled={this.state.button.isDisabled}>
                <span className="btn btn-primary btn-sm btn--rounded">
                  <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
                </span>
                BTN.link with rounded icon
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn-sm btn--element-start" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fas", "circle-plus"]} fixedWidth />
                BTN.small
              </button>
            </div>
          </div>
          <hr />
          <h3>icons end</h3>
          <div className="d-flex align-items-center mb-4">
            <div className="me-2">
              <button className="btn btn-primary btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.primary
                <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-secondary btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.secondary
                <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.outline-secondary
                <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.link
                <FontAwesomeIcon icon={["fas", "circle-plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.link with rounded icon
                <span className="btn btn-primary btn-sm btn--rounded">
                  <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
                </span>
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn-sm btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.small
                <FontAwesomeIcon icon={["fas", "circle-plus"]} fixedWidth />
              </button>
            </div>
          </div>
          <hr />
          <h3>messages unread</h3>
          <div className="d-flex align-items-center mb-4">
            <div className="me-2">
              <button className="btn btn-primary btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.primary
                <span className="messages-unread">
                  6 <span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-secondary btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.secondary
                <span className="messages-unread">
                  6 <span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.outline-secondary
                <span className="messages-unread">
                  123456 <span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.link
                <span className="messages-unread">
                  6 <span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-link btn-sm btn--element-end" disabled={this.state.button.isDisabled}>
                BTN.small
                <span className="messages-unread">
                  6 <span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
          </div>
          <hr />
          <h3>rounded icon</h3>
          <div className="d-flex align-items-center mb-4">
            <div className="me-2">
              <button className="btn btn-primary btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-primary btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-secondary btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary btn--rounded" disabled={this.state.button.isDisabled}>
                Mo
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-primary btn-sm btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-primary btn-sm btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-secondary btn-sm btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary btn-sm btn--rounded" disabled={this.state.button.isDisabled}>
                <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
              </button>
            </div>
            <div className="me-2">
              <button className="btn btn-outline-secondary btn-sm btn--rounded" disabled={this.state.button.isDisabled}>
                Mo
              </button>
            </div>
          </div>
          <hr />
          <h3>rounded text</h3>
          <div className="d-flex align-items-center mb-4">
            <div className="me-4">
              <h4>
                <i>btn default</i>
              </h4>
              <button className="btn btn-outline-primary btn--rounded" disabled={this.state.button.isDisabled}>
                Mo
              </button>
            </div>
            <div className="me-2">
              <h4>
                <i>btn inactive</i>
              </h4>
              <button
                className="btn btn-outline-primary btn--rounded btn--rounded-inactive"
                disabled={this.state.button.isDisabled}
              >
                Mo
              </button>
            </div>
          </div>
          <hr />
          <h3>No border</h3>
          <div className="d-flex align-items-center mb-4">
            <div className="me-2">
              <button className="btn btn-link">
                <FontAwesomeIcon icon={["fal", "plus"]} size="lg" fixedWidth />
              </button>
              <button className="btn btn-link btn-sm">
                <FontAwesomeIcon icon={["fal", "plus"]} size="lg" fixedWidth />
              </button>
            </div>
          </div>
          <hr />
          <h3>Label</h3>
          <div className="row row--btn-image-label">
            <button title={name} className={`btn btn--image-label ${active ? "active" : "inactive"}`} type="button">
              <img src={imageExists ? imageUri : IconImageNotFound} alt="" />
              <div>{name}</div>
            </button>
          </div>
          <DesignSystemExclusive type={DesignSystemExclusiveTypes.BUTTON} />
        </div>
      </div>
    );
  }

  public renderBadge(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="badge" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Badge
          </h4>
        </div>
        <div className="d-flex align-items-center mb-4">
          <div className="me-2">
            <span className="badge bg-green">Primary</span>
          </div>
          <div className="me-2">
            <span className="badge bg-secondary">Secondary</span>
          </div>
          <div className="me-2">
            <span className="badge bg-warning">Warning</span>
          </div>
        </div>
      </div>
    );
  }

  public renderForm(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="form" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Form
          </h4>
        </div>
        <form className={`row g-3 needs-validation${this.state.form.isValidated ? " was-validated" : ""}`} noValidate>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label">
              Input <i>placeholder</i>
            </label>
            <input
              type="text"
              className={`form-control${this.state.input.isInvalid ? " is-invalid" : ""}`}
              id="validationCustom01"
              required
              placeholder="Placeholder"
              disabled={this.state.input.isDisabled}
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please fill in field.</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label">
              Input <i>icon start</i>
            </label>
            <input
              type="text"
              className={`form-control form-control--icon--start form-control--icon--magnifying-glass${
                this.state.input.isInvalid ? " is-invalid" : ""
              }`}
              id="validationCustom02"
              required
              disabled={this.state.input.isDisabled}
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please fill in field.</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom03" className="form-label">
              Input <i>icon end</i>
            </label>
            <input
              type="text"
              className={`form-control form-control--icon--end form-control--icon--magnifying-glass${
                this.state.input.isInvalid ? " is-invalid" : ""
              }`}
              id="validationCustom03"
              required
              disabled={this.state.input.isDisabled}
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please fill in field.</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom04" className="form-label">
              Input <i>type=["number"]</i>
            </label>
            <input
              type="number"
              className={`form-control${this.state.input.isInvalid ? " is-invalid" : ""}`}
              id="validationCustom04"
              required
              disabled={this.state.input.isDisabled}
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please fill in field.</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustomGroup01" className="form-label">
              Input group <i>icon start & none clickable</i>
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
              </span>
              <input
                type="text"
                className={`form-control${this.state.input.isInvalid ? " is-invalid" : ""}`}
                id="validationCustomGroup01"
                required
                placeholder="Placeholder"
                disabled={this.state.input.isDisabled}
              />
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustomGroup02" className="form-label">
              Input group <i>icon end & clickable</i>
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className={`form-control${this.state.input.isInvalid ? " is-invalid" : ""}`}
                id="validationCustomGroup02"
                required
                placeholder="Placeholder"
                disabled={this.state.input.isDisabled}
              />
              <button type="button" className="input-group-text" onClick={(): void => alert("clicked")}>
                <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom03" className="form-label">
              Input group <i>icon start</i>
            </label>
            <div className="input-group mb-3">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon2" onClick={() => alert("clicked")}>
                  <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
                </span>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom041" className="form-label">
              Input <i>no border</i>
            </label>
            <input
              type="text"
              className={`form-control form-control--no-border${this.state.input.isInvalid ? " is-invalid" : ""}`}
              id="validationCustom041"
              required
              placeholder="Placeholder"
              disabled={this.state.input.isDisabled}
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please fill in field.</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom042" className="form-label">
              Textarea
            </label>
            <textarea
              className={`form-control${this.state.input.isInvalid ? " is-invalid" : ""}`}
              disabled={this.state.input.isDisabled}
              id="validationCustom042"
              required
              rows={6}
            ></textarea>
            <div className="form-text">Max. 500 characters.</div>
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please fill in field.</div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
              <div className="col-md-4">
                <label htmlFor="validationCustom05" className="form-label">
                  Select
                </label>
                <select
                  className={`form-select${this.state.input.isInvalid ? " is-invalid" : ""}`}
                  id="validationCustom05"
                  required
                  disabled={this.state.input.isDisabled}
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  <option>...</option>
                  <option>...</option>
                  <option>...</option>
                </select>
                <div className="valid-feedback">Looks good!</div>
                <div className="invalid-feedback">Please fill in field.</div>
              </div>
              <div className="col-md-4">
                <div>
                  <label htmlFor="validationCustom06" className="form-label">
                    Custom select
                  </label>
                  <div
                    className={`select-custom${this.state.input.isDisabled ? " disabled" : ""}${
                      this.state.input.isInvalid ? " is-invalid" : ""
                    }${this.state.inputSelectCustom.isOpen ? " select-custom--is-open" : ""}`}
                  >
                    <div className="select-custom__toggle" onClick={this.setInputSelectCustomState}>
                      <div className="select-custom__toggle-value">Placeholder</div>
                      <div className="select-custom__toggle-indicator">
                        <FontAwesomeIcon
                          icon={["fal", `${this.state.inputSelectCustom.isOpen ? "chevron-up" : "chevron-down"}`]}
                        />
                      </div>
                    </div>
                    <div className="select-custom__options">
                      <div className="select-custom__option select-custom__option--search">
                        <input
                          type="text"
                          className="form-control form-control--icon--end form-control--icon--magnifying-glass"
                          required
                        />
                      </div>
                      <div className="select-custom__option">...</div>
                      <div className="select-custom__option selected">...</div>
                      <div className="select-custom__option">...</div>
                      <div className="select-custom__option">
                        <div className="d-flex justify-content-between">
                          <div>
                            Option Name
                            <p className="small mb-0">Subtext</p>
                          </div>
                          <div className="d-flex align-items-center">
                            {/* UnreadMessagesCounter */}
                            <span className="ms-2 messages-unread">
                              4<span className="visually-hidden">unread messages</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                  <div className="invalid-feedback">Please fill in field.</div>
                </div>
                <div className="alert alert-warning mt-2" role="alert">
                  <h4 className="alert-heading">
                    Don't use this <i>Custom Select</i>. This is only the output HTML.
                  </h4>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <label htmlFor="validationCustom06" className="form-label">
                    (Searchable) Multi select
                  </label>
                  <div className="rmsc multi-select">
                    <div
                      className="dropdown-container"
                      aria-labelledby="Operators"
                      aria-readonly="true"
                      aria-expanded="false"
                    >
                      <div className="dropdown-heading">
                        <div className="dropdown-heading-value">
                          <span>
                            <div>1 geselecteerd</div>
                          </span>
                        </div>
                        <button type="button" className="clear-selected-button" aria-label="Clear Selected">
                          <div className="clear-selected-button"></div>
                        </button>
                        <div className="dropdown-arrow"></div>
                      </div>
                    </div>
                  </div>
                  <div className="alert alert-warning mt-2" role="alert">
                    <h4 className="alert-heading">
                      Don't use this <i>(Searchable) Multi Select</i>. This is only the output HTML.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="alert alert-info mt-2" role="alert">
                  <h4 className="alert-heading">
                    There a more selector only these html are generated by the component:
                    <ul>
                      <li>
                        SearchableSelect - <i>not added in this page because css is rendered inline by js</i>
                      </li>
                    </ul>
                  </h4>
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="form-check">
              <input
                className={`form-check-input${this.state.input.isInvalid ? " is-invalid" : ""}`}
                type="checkbox"
                value=""
                id="validationCheckbox01"
                required
                disabled={this.state.input.isDisabled}
              />
              <label className="form-check-label" htmlFor="validationCheckbox01">
                Input <i>type=["checkbox"]</i>
              </label>
              <div className="valid-feedback">Looks good!</div>
              <div className="invalid-feedback">Please fill in field.</div>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input${this.state.input.isInvalid ? " is-invalid" : ""}`}
                type="checkbox"
                value=""
                id="validationCheckbox02"
                required
                checked
                disabled={this.state.input.isDisabled}
              />
              <label className="form-check-label" htmlFor="validationCheckbox02">
                Input <i>type=["checkbox"] - checked</i>
              </label>
              <div className="valid-feedback">Looks good!</div>
              <div className="invalid-feedback">Please fill in field.</div>
            </div>
          </div>

          <div className="col-4">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Input <i>type=["radio"]</i>
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Input <i>type=["radio"] - checked</i>
              </label>
            </div>
          </div>

          <div className="col-4">
            <div className="form-check form-switch">
              <input
                className={`form-check-input${this.state.input.isInvalid ? " is-invalid" : ""}`}
                type="checkbox"
                role="switch"
                id="validationCustom08"
                disabled={this.state.input.isDisabled}
              />
              <label className="form-check-label" htmlFor="validationCustom08">
                Switch
              </label>
              <div className="valid-feedback">Looks good!</div>
              <div className="invalid-feedback">Please fill in field.</div>
            </div>
          </div>

          <div className="col-12">
            <hr />
          </div>
          <div className="col-6">
            <label htmlFor="validationCustom09" className="form-label">
              Input <i>type=["file"]</i>
            </label>
            <input
              type="file"
              className="form-control"
              id="validationCustom09"
              required
              disabled={this.state.input.isDisabled}
            />
          </div>
          <div className="col-6">
            <label htmlFor="validationCustom10" className="form-label">
              Input <i>type=["file"] - custom</i>
            </label>
            <div className="input-file--container d-flex">
              <div className="input-file--item input-file--item--sm">
                <img src={Placeholer600x400} alt="" />
                <FontAwesomeIcon icon={["fad", "circle-plus"]} className="input-file--item--remove" />
              </div>
              <div className="input-file--item">
                <img src={Placeholer600x400} alt="" />
                <FontAwesomeIcon icon={["fad", "circle-plus"]} className="input-file--item--remove" />
              </div>
              <label htmlFor="validationCustom10" className="input-file--item input-file--item--add">
                <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
                <input
                  type="file"
                  className="form-control"
                  id="validationCustom10"
                  required
                  disabled={this.state.input.isDisabled}
                />
              </label>
            </div>
          </div>
          <div className="col-12">
            <div className="col-12">
              <hr />
            </div>
            <button className="btn btn-primary" type="submit">
              Submit form
            </button>
          </div>
        </form>
      </div>
    );
  }

  public renderTable(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="table" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Table
          </h4>
        </div>
        <table className="table table--bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Handle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">1</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <td colSpan={4} scope="row" className="td--content">
                <h2>Header.H2</h2>
                <h3>Header.H3</h3>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat reprehenderit at cupiditate minus
                quibusdam labore corporis ad quisquam ipsam consequatur maiores, debitis reiciendis! Quos hic reiciendis
                numquam, reprehenderit possimus id!
              </td>
            </tr>
            <tr>
              <td scope="row">2</td>
              <td>Jacob</td>
              <td>thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <td scope="row">3</td>
              <td colSpan={2}>Larry the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>

        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Table - React
          </h4>
        </div>
        <table className="table table--bordered">
          <thead>
            <tr role="row">
              <th colSpan={1} role="columnheader">
                &nbsp;
              </th>
              <th colSpan={1} role="columnheader" title="Toggle SortBy">
                Routenaam
                <FontAwesomeIcon icon={["fal", "arrow-up-arrow-down"]} fixedWidth />
              </th>
              <th colSpan={1} role="columnheader" title="Toggle SortBy">
                Gebouw
                <FontAwesomeIcon icon={["fal", "arrow-up-arrow-down"]} fixedWidth />
              </th>
              <th colSpan={1} role="columnheader" title="Toggle SortBy">
                Starttijd
                <FontAwesomeIcon icon={["fal", "arrow-up-arrow-down"]} fixedWidth />
              </th>
              <th colSpan={1} role="columnheader" title="Toggle SortBy">
                Frequentie
                <FontAwesomeIcon icon={["fal", "arrow-up-wide-short"]} fixedWidth />
              </th>
              <th colSpan={1} role="columnheader" title="Toggle SortBy">
                Actief
                <FontAwesomeIcon icon={["fal", "arrow-down-wide-short"]} fixedWidth />
              </th>
              <th colSpan={1} role="columnheader">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row" className="">
              <td role="cell">
                <input className="form-check-input td-checkbox" type="checkbox" checked />
              </td>
              <td role="cell">Routenaam</td>
              <td role="cell">Gebouw</td>
              <td role="cell">09:15</td>
              <td role="cell">ma di wo do vr</td>
              <td role="cell">
                <FontAwesomeIcon
                  icon={["fas", "circle-check"]}
                  fixedWidth
                  size="lg"
                  className="fa--1rem text-success"
                />
              </td>
              <td role="cell">
                <button className="btn btn-sm py-0">
                  <FontAwesomeIcon icon={["fal", "chevron-down"]} fixedWidth className="fa--1rem text-primary" />
                </button>
              </td>
            </tr>
            <tr className="tr-content">
              <td className="activity-toggle-header">&nbsp;</td>
              <td colSpan={6} className="td-content-pt-3-5">
                <h3>Schoonmaker(s)</h3>
              </td>
            </tr>
            <tr className="tr-content">
              <td className="activity-toggle-header">&nbsp;</td>
              <td colSpan={6} className="td-content-pb-3-5">
                <ul>
                  <li>Cleaner1</li>
                </ul>
              </td>
            </tr>
            <tr className="tr-content">
              <td>&nbsp;</td>
              <td>
                <h3>Feedback vraag</h3>
              </td>
              <td colSpan={2}>
                <h3>Feedback antwoord</h3>
              </td>
              <td>
                <h3>Tonen bij</h3>
              </td>
              <td>
                <h3>Verplicht</h3>
              </td>
              <td></td>
            </tr>
            <tr className="tr-content">
              <td className="td-content-pb-3-5">&nbsp;</td>
              <td className="td-content-pb-3-5">
                <ul>
                  <li>Heb je vloeistoffen bijgevuld?</li>
                </ul>
              </td>
              <td colSpan={2} className="td-content-pb-3-5">
                <div>Meerkeuze</div>
              </td>
              <td className="td-content-pb-3-5">
                <div>
                  <div className="activity-icon approve"></div>
                </div>
              </td>
              <td className="td-content-pb-3-5">
                <div>Ja</div>
              </td>
              <td className="td-content-pb-3-5"></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  public renderModal(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="modal" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Modals
          </h4>
        </div>
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">Don't use this HTML. This is only the output HTML.</h4>
        </div>
        <h3>
          Modal <i>content</i>
        </h3>
        <div className="modal" tabIndex={-1} style={{ position: "static", display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">
                      Modal title <i>title + subtitle + steps</i>
                    </h1>
                    <h5 className="modal-title">Modal sub title</h5>
                  </div>
                  <div className="modal__steps">1/3</div>
                </div>
                <button type="button" className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-header">
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">
                      Modal title <i>title + steps</i>
                    </h1>
                  </div>
                  <div className="modal__steps">1/3</div>
                </div>
                <button type="button" className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-header">
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">
                      Modal title <i>title + subtitle</i>
                    </h1>
                    <h5 className="modal-title">Modal sub title</h5>
                  </div>
                </div>
                <button type="button" className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-header">
                <div className="modal-header__info">
                  <h1 className="modal-title">
                    Modal title <i>title</i>
                  </h1>
                </div>
                <button type="button" className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Modal body text goes here.</p>
              </div>
              <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-outline-secondary btn--element-end" data-bs-dismiss="modal">
                  Close
                  <FontAwesomeIcon icon={["fal", "circle-xmark"]} fixedWidth />
                </button>
                <button type="button" className="btn btn-primary btn--element-end">
                  Save changes
                  <FontAwesomeIcon icon={["fal", "circle-check"]} fixedWidth />
                </button>
              </div>
            </div>
          </div>
          <h3>
            Modal <i>success</i>
          </h3>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Modal title</h1>
                <button type="button" className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="modal__message">
                  <img src={Placeholer600x400} alt="" />
                  <div className="modal__message__block">
                    <h2 className="text-infographic">Lorem ipsum</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  </div>
                  <div className="modal__message__block">
                    <h2 className="text-infographic">Lorem ipsum</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-end">
                <button type="button" className="btn btn-primary btn--element-end">
                  Close
                  <FontAwesomeIcon icon={["fal", "xmark"]} fixedWidth />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderAccordion(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="accordion" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Accordion
          </h4>
        </div>
        <h2>Default</h2>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Accordion Item #1
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse
                plugin adds the appropriate classes that we use to style each element.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed accordion-button--has-icon"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Accordion Item #2
                <button className="btn btn-link accordion--btn-action" data-bs-toggle="collapse" data-bs-target>
                  <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth size="lg" />
                </button>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse
                plugin adds the appropriate classes that we use to style each element.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Accordion Item #3
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse
                plugin adds the appropriate classes that we use to style each element.
              </div>
            </div>
          </div>
          <hr />
          <h2>Always open</h2>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Accordion Item #1
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body">
                  <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Accordion Item #2
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body">
                  <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Accordion Item #3
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="accordion-body">
                  <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderDropdown(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="dropdown" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Dropdown
          </h4>
        </div>
        <div className="dropdown">
          <button
            className="btn btn-outline-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown button
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  public renderIcon(): JSX.Element {
    const usedIconsFal: IDesignSystemIcons[] = [
      { icon: ["fal", "circle-left"], label: "pagination" },
      { icon: ["fal", "circle-right"], label: "pagination" },
      { icon: ["fal", "circle-plus"], label: "add (within a none colored button)" },
      { icon: ["fal", "circle-user"], label: "header user" },
      { icon: ["fal", "circle-info"], label: "info modal" },
      { icon: ["fal", "circle-exclamation"], label: "warning" },
      { icon: ["fal", "plus"], label: "add (within colored button)" },
      { icon: ["fal", "pen"], label: "edit" },
      { icon: ["fal", "copy"], label: "duplicate item" },
      { icon: ["fal", "trash"], label: "delete item" },
      { icon: ["fal", "xmark"], label: "close" },
      { icon: ["fal", "arrow-up-to-line"], label: "upload, import" },
      { icon: ["fal", "arrow-down-to-line"], label: "download, export" },
      { icon: ["fal", "floppy-disk"], label: "save" },
      { icon: ["fal", "check"], label: "complete" },
      { icon: ["fal", "paper-plane-top"], label: "send" },
      { icon: ["fal", "comments"], label: "logbook" },
      { icon: ["fal", "mobile-signal"], label: "nfc tag" },
      { icon: ["fal", "building"], label: "header customer" },
      { icon: ["fal", "location-dot"], label: "location" },
      { icon: ["fal", "arrow-left"], label: "button back" },
      { icon: ["fal", "arrow-right"], label: "button next" },
      { icon: ["fal", "chevron-up"], label: "table expander opened, select opened" },
      { icon: ["fal", "chevron-down"], label: "table expander closed, select closed" },
      { icon: ["fal", "chevron-right"], label: "btn--content" },
      { icon: ["fal", "ellipsis-stroke"], label: "dropdown selector" },
      { icon: ["fal", "clock"], label: "select time picker" },
      { icon: ["fal", "bell"], label: "notification" },
      { icon: ["fal", "hourglass"], label: "jde malfunction" },
      { icon: ["fal", "magnifying-glass"], label: "input field search" },
      { icon: ["fal", "sliders-simple"], label: "filter" },
      { icon: ["fal", "file-circle-question"], label: "document" },
    ];

    const usedIconsFas: IDesignSystemIcons[] = [
      { icon: ["fas", "circle-check"], label: "checked / added checked" },
      { icon: ["fas", "circle-xmark"], label: "not checked / not added, delete" },
      { icon: ["fas", "circle-exclamation"], label: "warning" },
    ];

    const usedIconsFad: IDesignSystemIcons[] = [{ icon: ["fad", "circle-xmark"], label: "Delete selected image" }];

    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="icon" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Used icons
          </h4>
        </div>

        <div className="mb-5">
          <h2>Regular</h2>
          <div>
            <table className="table table--bordered">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Icon value</th>
                  <th scope="col">Used for</th>
                </tr>
              </thead>
              <tbody>
                {usedIconsFal.map((icon, index) => {
                  return (
                    <tr>
                      <td>
                        <FontAwesomeIcon icon={icon.icon} fixedWidth size="lg" />
                      </td>
                      <td>
                        {icon.icon[0]} - {icon.icon[1]}
                      </td>
                      <td>{icon.label}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mb-5">
          <h2>Solid</h2>
          <div className="d-flex">
            <table className="table table--bordered">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Icon value</th>
                  <th scope="col">Used for</th>
                </tr>
              </thead>
              <tbody>
                {usedIconsFas.map((icon, index) => {
                  return (
                    <tr>
                      <td>
                        <FontAwesomeIcon icon={icon.icon} fixedWidth size="lg" />
                      </td>
                      <td>
                        {icon.icon[0]} - {icon.icon[1]}
                      </td>
                      <td>{icon.label}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h2>Duotone</h2>
          <div className="d-flex">
            <table className="table table--bordered">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Icon value</th>
                  <th scope="col">Used for</th>
                </tr>
              </thead>
              <tbody>
                {usedIconsFad.map((icon, index) => {
                  return (
                    <tr>
                      <td>
                        <FontAwesomeIcon icon={icon.icon} fixedWidth size="lg" />
                      </td>
                      <td>
                        {icon.icon[0]} - {icon.icon[1]}
                      </td>
                      <td>{icon.label}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  public renderOther(): JSX.Element {
    return (
      <div className="col-12 block-content--px block-content--py block-content--border">
        {/* eslint-disable-next-line */}
        <a id="other" style={{ scrollMarginTop: "11rem" }} />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
            Others
          </h4>
        </div>
        <h2>Pagination</h2>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item disabled">
              <button className="page-link" aria-label="First">
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={["fal", "chevrons-left"]} fixedWidth size="sm" />
                </span>
              </button>
            </li>
            <li className="page-item disabled">
              <button className="page-link" aria-label="Previous">
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={["fal", "chevron-left"]} fixedWidth size="sm" />
                </span>
              </button>
            </li>
            <li className="page-item page-item--desc">1 - 6</li>
            <li className="page-item">
              <button className="page-link" aria-label="Next">
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth size="sm" />
                </span>
              </button>
            </li>
            <li className="page-item">
              <button className="page-link" aria-label="Last">
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={["fal", "chevrons-right"]} fixedWidth size="sm" />
                </span>
              </button>
            </li>
          </ul>
        </nav>

        <hr />

        <h2>Toasts</h2>
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">
            Don't use this HTML. Toast is build with react-notifications combined with Bootstrap toasts.
          </h4>
        </div>
        <div className="d-flex">
          <div className="me-3">
            <div className="toast toast--success show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-body">Hello, world! This is a toast message.</div>
            </div>
          </div>

          <div className="me-3">
            <div className="toast toast--error show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-body">Hello, world! This is a toast message.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderNavAndOptions(): JSX.Element {
    return (
      <div className="col-12" style={{ position: "sticky", bottom: 0, zIndex: 999 }}>
        <div className="alert alert-info d-flex justify-content-between align-items-center">
          <div>
            <h6>
              <FontAwesomeIcon icon={["fal", "folder-open"]} fixedWidth className="me-2" />
              Shared Hi Five components
            </h6>
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a href="#typography" className="nav-link">
                  Typography
                </a>
              </li>
              <li className="nav-item">
                <a href="#button" className="nav-link">
                  Button
                </a>
              </li>
              <li className="nav-item">
                <a href="#badge" className="nav-link">
                  Badge
                </a>
              </li>
              <li className="nav-item">
                <a href="#form" className="nav-link">
                  Form
                </a>
              </li>
              <li className="nav-item">
                <a href="#table" className="nav-link">
                  Table
                </a>
              </li>
              <li className="nav-item">
                <a href="#modal" className="nav-link">
                  Modal
                </a>
              </li>
              <li className="nav-item">
                <a href="#accordion" className="nav-link">
                  Accordion
                </a>
              </li>
              <li className="nav-item">
                <a href="#dropdown" className="nav-link">
                  Dropdown
                </a>
              </li>
              <li className="nav-item">
                <a href="#icon" className="nav-link">
                  Icon
                </a>
              </li>
              <li className="nav-item">
                <a href="#other" className="nav-link">
                  Other
                </a>
              </li>
            </ul>
            <hr />
            <div>
              <h6>
                <FontAwesomeIcon icon={["fal", "folder-closed"]} fixedWidth className="me-2" />
                Specific App components
              </h6>
              <DesignSystemExclusive type={DesignSystemExclusiveTypes.NAV} />
            </div>
          </div>
          <div className="vr"></div>
          <div className="px-3">
            <form className="row needs-validation" noValidate>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex">
                      <div className="px-3">
                        <h3>Others</h3>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="btns-disabled"
                            required
                            onChange={this.setButtonDisabledState}
                            checked={this.state.button.isDisabled}
                          />
                          <label className="form-check-label" htmlFor="btns-disabled">
                            Buttons disabled
                          </label>
                        </div>
                      </div>
                      <div className="px-3">
                        <h3>Form</h3>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="inputs-disabled"
                            required
                            onChange={this.setInputDisabledState}
                            checked={this.state.input.isDisabled}
                          />
                          <label className="form-check-label" htmlFor="inputs-disabled">
                            Input/Select disabled
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="inputs-invalid"
                            required
                            onChange={this.setInputIsInvalidState}
                            checked={this.state.input.isInvalid}
                          />
                          <label className="form-check-label" htmlFor="inputs-invalid">
                            Form is validated & Input/Select invalid
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.renderHeader()}

        <div className="content">
          <div className="container-fluid">
            <div className="row pb-5">
              {this.renderTypography()}
              {this.renderButton()}
              {this.renderBadge()}
              {this.renderForm()}
              {this.renderTable()}
              {this.renderModal()}
              {this.renderAccordion()}
              {this.renderDropdown()}
              {this.renderIcon()}
              {this.renderOther()}
            </div>
          </div>
        </div>

        {this.renderNavAndOptions()}
      </>
    );
  }
}
