import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";

import INFCMessage from "../interfaces/INFCMessage";
import NFCMessage from "../models/nfcMessage";
import TelemetryService from "../services/telemetryService";

export default class NFCValidator {
  public static tryParseNfcMessage(input: any): INFCMessage | undefined {
    let parsed: INFCMessage;

    try {
      parsed = new NFCMessage(input);
      if (!this.isMessageValid(parsed.message)) {
        TelemetryService.AppInsights?.trackTrace({
          message: `NFCValidator.tryParseNfcMessage, invalid nfc. ${parsed.message}`,
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.invalid_nfc));

        return undefined;
      }

      return parsed;
    } catch (error) {
      TelemetryService.AppInsights?.trackException({
        exception: { name: "NFCValidator.tryParseNfcMessage exception", message: error as string },
      });
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.invalid_nfc));

      return undefined;
    }
  }

  public static isMessageValid(message: string): boolean {
    return message.length > 4;
  }
}
