import { configureStore, Store } from "@reduxjs/toolkit";

import { ActionTypesCollection } from "./actionTypes";
import { fetchInitialConfiguration } from "./fetchInitialConfiguration";
import rootReducer, { RootState } from "./reducers/rootReducer";
import { initialActivityState } from "./state/activityStoreState";
import { initialCustomerState } from "./state/customerStoreState";
import { initialGeneralStoreState } from "./state/generalStoreState";
import { initialLogBookStoreState } from "./state/logbookStoreState";
import { initialNotificationState } from "./state/notificationStoreState";
import { initialScheduleState } from "./state/scheduleStoreState";

export const initialStates: RootState = {
  generalState: initialGeneralStoreState,
  scheduleState: initialScheduleState,
  activityState: initialActivityState,
  customerState: initialCustomerState,
  notificationState: initialNotificationState,
  logbookState: initialLogBookStoreState,
};

class StoreFactory {
  public static Create(): Store<RootState, ActionTypesCollection> {
    const store = configureStore({
      reducer: rootReducer,
      preloadedState: initialStates,
    });

    store.dispatch(fetchInitialConfiguration());

    return store;
  }
}

export default StoreFactory;
