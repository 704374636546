import Button from "components/material/buttons/button";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { Component, ReactNode } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import ReactDOM from "react-dom";

import IManualTagInputModalProps from "./interfaces/IManualTagInputModalProps";
import IManualTagInputModalState from "./interfaces/IManualTagInputModalState";

export default class ManualTagInputModal extends Component<IManualTagInputModalProps, IManualTagInputModalState> {
  public constructor(props: IManualTagInputModalProps) {
    super(props);

    const state: IManualTagInputModalState = {
      tagValue: "",
    };

    this.state = state;
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOkClick = this.onOkClick.bind(this);
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private get inputValid(): boolean {
    return this.state != null && this.state.tagValue != null && this.state.tagValue.length > 5;
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const tagValue = event.target.value;

    this.setState({
      tagValue: tagValue,
    });
  }

  private onOkClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();

    if (!this.inputValid) {
      return;
    }

    this.setState({
      tagValue: "",
    });

    this.props.onGetByManualInput(this.state.tagValue);
  }

  private onClose(): void {
    this.setState(
      {
        tagValue: "",
      },
      () => {
        this.props.onClose();
      }
    );
  }

  private get showMessage(): boolean {
    return this.props.message != null && this.props.message !== "";
  }

  public render(): ReactNode {
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal show={this.props.show} onHide={this.onClose} dialogClassName="modal-dialog-centered">
              <Modal.Header closeButton>
                <Modal.Title className="w-100">
                  <div className="modal-header__info">
                    <h1 className="modal-title">{LanguageProvider.t(TranslationMapper.global.modals.manual_input)}</h1>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>{LanguageProvider.t(TranslationMapper.global.modals.nfc_label)}</Form.Label>
                  <Form.Control
                    name="tagValue"
                    onChange={this.onChange}
                    value={this.state.tagValue}
                    placeholder={LanguageProvider.t(TranslationMapper.global.modals.placeholder)}
                  />
                </Form.Group>
                {this.showMessage && (
                  <Form.Group>
                    <div className="invalid-feedback d-block">{this.props.message}</div>
                  </Form.Group>
                )}
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                <Button
                  className="btn-outline-secondary"
                  label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
                  onClick={this.onClose}
                  iconEnd={["fal", "xmark"]}
                />
                <Button
                  className="btn-primary"
                  disabled={!this.inputValid}
                  label={LanguageProvider.t(TranslationMapper.global.buttons.ok)}
                  onClick={this.onOkClick}
                  iconEnd={["fal", "check"]}
                />
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}
