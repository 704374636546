import IWizardStep from "components/wizard/interfaces/IWizardStep";
import { TranslationMapper } from "i18n/mapper";
import { IInputChangeEvent } from "interfaces/IInputChangeEvent";
import LanguageProvider from "providers/languageProvider";
import React, { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import ImageUtils from "utils/imageUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import INotificationDetailsStepProps from "./interfaces/INotificationDetailsStepProps";
import INotificationDetailsStepState from "./interfaces/INotificationDetailsStepState";
import NotificationValidator from "./notificationValidator";

class NotificationDetailsStep extends Component<INotificationDetailsStepProps, INotificationDetailsStepState> {
  public constructor(props: INotificationDetailsStepProps) {
    super(props);

    const state = this.props.value ?? {
      id: "",
      description: "",
      notificationCategoryId: "",
      notificationCategoryName: "",
      notificationCategoryImageUri: "",
      pictures: [],
    };

    this.state = state;

    this.onChange = this.onChange.bind(this);
    this.propagateChange = this.propagateChange.bind(this);

    this.deletePicture = this.deletePicture.bind(this);
    this.handleFileSelection = this.handleFileSelection.bind(this);

    this.propagateChange();
  }

  private onChange(event: IInputChangeEvent<any>): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((current) => ({ ...current, [name]: value }), this.propagateChange);
  }

  private propagateChange(): void {
    const isValid = NotificationValidator.areDetailsValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private onFileUploadClick(e: React.MouseEvent<HTMLInputElement>): void {
    // Reset input onClick to make sure upload is possible
    e.currentTarget.files = null;
    e.currentTarget.value = "";
  }

  private deletePicture(index: number): void {
    const pictures = this.state.pictures;
    pictures.splice(index, 1);
    this.setState(
      {
        pictures: pictures,
      },
      this.propagateChange
    );
  }

  private async handleFileSelection(e?: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const selectorFile = e?.target?.files?.[0];

    if (!e || !selectorFile) {
      return;
    }

    const maxUncompressedSize = 1024 * 1024 * 15; // = 15Mb

    if (selectorFile.size > maxUncompressedSize) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.components.notifications.error_image_size));

      // Clear HTML input, otherwise no new files can be entered once a incorrect file has been added.
      e.target.value = "";
      e.target.files = null;
    } else {
      switch (selectorFile.type) {
        case "image/jpg":
        case "image/jpeg":
        case "image/png":
          await ImageUtils.compressToBase64Async(selectorFile).then((image) => {
            const pictures = this.state.pictures ?? [];
            pictures.push(image);
            this.setState((current) => ({ ...current, pictures: pictures }), this.propagateChange);
          });
          break;
        default:
          NotificationManager.error(LanguageProvider.t(TranslationMapper.components.notifications.error_image_file));
      }
    }
  }

  private renderImages(): JSX.Element {
    return (
      <div className="input-file--container d-flex row mx-0">
        {this.state.pictures?.map((picture, index) => {
          return (
            <div className="input-file--item mb-2 p-0" key={index}>
              <img src={picture} alt="uploaded" />
              <FontAwesomeIcon
                icon={["fad", "circle-xmark"]}
                className="input-file--item--remove"
                onClick={(): void => this.deletePicture(index)}
              />
            </div>
          );
        })}

        <label
          htmlFor="notification-image"
          className={`input-file--item input-file--item--add mb-2 ${
            this.state.pictures === null || this.state.pictures.length < 5 ? "" : " d-none"
          }`}
        >
          <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
          <input
            type="file"
            className="form-control"
            accept="image/*;capture=environment"
            onClick={this.onFileUploadClick}
            onChange={this.handleFileSelection}
            id="notification-image"
          />
        </label>
      </div>
    );
  }

  public render(): ReactNode {
    return (
      <Form>
        <Row>
          <Form.Group as={Col} sm={12} className="modal__col-mb">
            <Form.Label>{LanguageProvider.t(TranslationMapper.components.notifications.take_pictures)}</Form.Label>
            {this.renderImages()}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} sm={12}>
            <Form.Label>{LanguageProvider.t(TranslationMapper.components.notifications.description)}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={this.onChange}
              value={this.state.description}
            />
          </Form.Group>
        </Row>
      </Form>
    );
  }
}

const wizardStep: IWizardStep = {
  form: NotificationDetailsStep,
  titleResource: "",
  name: "details",
};

export default wizardStep;
