import ICleaningObject from "interfaces/ICleaningObject";
import ILocationOverview from "interfaces/ILocationOverview";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import ISchedule from "interfaces/ISchedule";
import IUniversalReferenceResponse from "interfaces/IUniversalReferenceResponse";

import ScheduleClient from "./clients/scheduleClient";

export default class ScheduleService {
  private readonly cleaningScheduleClient: ScheduleClient;

  public constructor() {
    this.cleaningScheduleClient = new ScheduleClient();
  }

  public getScheduleByLocationAsync(locationId: string): Promise<ISchedule> {
    return this.cleaningScheduleClient.getScheduleByLocationAsync(locationId);
  }

  public getCleaningObjectByIdAsync(projectId: string, cleaningObjectId: string): Promise<ICleaningObject> {
    return this.cleaningScheduleClient.getCleaningObjectById(projectId, cleaningObjectId);
  }

  public getScheduledLocationsAsync(): Promise<ILocationOverview[]> {
    return this.cleaningScheduleClient.getScheduledLocationsAsync();
  }

  public getScheduleByFloorAsync(floorId: string, projectId?: string): Promise<ISchedule> {
    return this.cleaningScheduleClient.getScheduleByFloorAsync(floorId, projectId);
  }

  public getUniversalReferenceObject(
    nfcReferenceRequestObject: INfcReferenceObjectRequest
  ): Promise<IUniversalReferenceResponse> {
    return this.cleaningScheduleClient.getUniversalReferenceObject(nfcReferenceRequestObject);
  }
}
