import ILanguage from "interfaces/ILanguage";

import TranslateNL from "../i18n/locales/nl/translation.json";

export const languages: ILanguage[] = [
    {
      key: "nl",
      label: "NL",
      resources: {
        translation: TranslateNL,
      },
    },
  ];