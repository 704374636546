import { TranslationMapper } from "i18n/mapper";
import INFCMessage from "interfaces/INFCMessage";
import LanguageProvider from "providers/languageProvider";

export default class NFCMessage implements INFCMessage {
  public id: string;
  public message: string;

  public constructor(json: string) {
    const parsed = JSON.parse(json);

    if (!this.isTypeSafe(parsed)) {
      throw new Error(LanguageProvider.t(TranslationMapper.global.errors.invalid_nfc));
    }

    this.id = parsed.id ?? "";
    this.message = parsed.message ?? "";
  }

  private isTypeSafe(nfcTagValue: INFCMessage): nfcTagValue is INFCMessage {
    return "id" in nfcTagValue && "message" in nfcTagValue;
  }
}
