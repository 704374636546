import { CmApiAuthenticator } from "authenticators/cmApiAuthenticator";
import INotification from "interfaces/INotification";
import INotificationStatusUpdate from "interfaces/INotificationStatusUpdate";

import { ApplicationConfig } from "../../config";
import INotificationCategoryItem from "../../interfaces/INotificationCategoryItem";
import INotificationRequest from "../../interfaces/INotificationRequest";
import BaseClient from "./baseClient";

export default class ActivityClient extends BaseClient {
  private endpoint: string = "api/notification";

  public constructor() {
    super(ApplicationConfig.endpoints.cmApi, new CmApiAuthenticator());
  }

  public async createNotificationAsync(notification: INotificationRequest): Promise<Response> {
    return this.post(`${this.endpoint}`, notification);
  }

  public async getNotificationAsync(cnotificationId: string): Promise<INotification> {
    return this.get<INotification>(`${this.endpoint}/${cnotificationId}`);
  }

  public async getNotificationCategoriesForCleaningObjectAsync(
    cleaningObjectId: string
  ): Promise<INotificationCategoryItem[]> {
    return this.get<INotificationCategoryItem[]>(`${this.endpoint}/cleaningobject/${cleaningObjectId}/categories`);
  }

  public async updateNotificationStatusAsync(notificationStatusUpdate: INotificationStatusUpdate): Promise<Response> {
    return this.post(`${this.endpoint}/updatestatus`, notificationStatusUpdate);
  }
}
