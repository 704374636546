export function isNullOrEmpty(text?: string): boolean {
  return text == null || text === "";
}

export function toLocaleString(text?: string | number): string {
  return text == null ? "" : text.toLocaleString();
}

export function replaceStringPlaceholders(str: string, ...val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}
