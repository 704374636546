enum LoaderTypes {
    Schedule = "Schedule",
    CleaningObject = "CleaningObject",
    OperatorLocations = "OperatorLocations",
    Notifications = "Notifications",
    NotificationCreate = "NotificationCreate",
    NfcScan = "NfcScan",
    LogbookChannel = "LogbookChannel",
    LogbookChannelMessage = "LogbookChannelMessage"
}

export default LoaderTypes;
