import CheckoutTriggerType from "enums/checkoutTriggerType";
import ExceptionTypes from "enums/exceptionTypes";
import LoaderTypes from "enums/loaderTypes";
import * as H from "history";
import { TranslationMapper } from "i18n/mapper";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";

import IActivityStatusUpdate from "../../interfaces/IActivityStatusUpdate";
import IReactSelectValue from "../../interfaces/IReactSelectValue";
import ActivityService from "../../services/activityService";
import TelemetryService from "../../services/telemetryService";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";
import { finishLoading, startLoading } from "./generalActions";
import {
  fetchedCleaningObject,
  getAllScheduledLocationsAsync,
  getScheduleByIdAsync,
  setActiveLocation,
  setActivitiesCheckoutTrigger,
} from "./scheduleActions";

export const fetchedCancellationReasons = actionCreator<
  ActionTypes.FETCHED_CANCELLATION_REASONS,
  IReactSelectValue[] | undefined
>(ActionTypes.FETCHED_CANCELLATION_REASONS);

export function finishActivitiesAsync(
  activityStatusUpdates: IActivityStatusUpdate[],
  routeHistory: H.History
): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    dispatch(startLoading(LoaderTypes.CleaningObject));
    const activityService = new ActivityService();

    const checkoutTrigger = getState().scheduleState.currentCheckoutTrigger ?? CheckoutTriggerType.Unknown;
    activityStatusUpdates.forEach(activity => {
      activity.checkoutTrigger = checkoutTrigger;
    });

    activityService
      .finishActivitiesAsync(activityStatusUpdates)
      .then((response: Response) => {
        if (response.ok) {
          handleSuccessfullActivityFinish(dispatch, routeHistory);
        } else {
          const error = i18next.t(TranslationMapper.global.errors.error_finishing_activity);

          NotificationManager.error(error);
          TelemetryService.AppInsights?.trackException({
            exception: { name: "finishActivitiesAsync exception", message: error },
          });
        }
      })
      .catch((error: Error) => {
        if (error.message.includes(ExceptionTypes.MultipleCheckOutException.toString())) {
          handleSuccessfullActivityFinish(dispatch, routeHistory);
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.global.errors.error_finishing_activity));
        }
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.CleaningObject));
      });
  };
}

export function assignActivityToOperator(activityIds: string[], history: H.History): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    dispatch(startLoading(LoaderTypes.CleaningObject));
    const activityService = new ActivityService();
    const customerId = getState().customerState.activeCustomer;
    const location = getState().scheduleState.selectedLocation;

    activityService
      .assignOperatorToActivityAsync(activityIds, customerId)
      .then(r => {
        if (r.status === 400) {
          NotificationManager.error(
            i18next.t(TranslationMapper.components.notifications.error_activity_already_assigned)
          );
        }
      })
      .catch(() =>
        NotificationManager.error(i18next.t(TranslationMapper.components.notifications.error_assign_activity))
      )
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.CleaningObject));

        // Always refresh list after fail or success
        if (location) {
          dispatch(setActiveLocation(location));
        }
        dispatch(getScheduleByIdAsync(history));
      });
  };
}

function handleSuccessfullActivityFinish(dispatch: any, routeHistory: H.History): void {
  dispatch(fetchedCleaningObject(undefined));
  dispatch(setActivitiesCheckoutTrigger(CheckoutTriggerType.Unknown));
  dispatch(getScheduleByIdAsync(routeHistory));
  dispatch(getAllScheduledLocationsAsync());
  NotificationManager.success(i18next.t(TranslationMapper.components.activities.finished));
}
