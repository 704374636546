import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import DateUtils from "utils/dateUtils";

import ILogbookMessageProps from "./interfaces/ILogbookMessageProps";

export default class LogbookMessage extends Component<ILogbookMessageProps> {
  public render(): JSX.Element {
    return (
      <>
        <div
          className={`logbook-message ${
            this.props.isFromCurrentUser ? "logbook-message--user-current" : "logbook-message--user-sender"
          }`}
        >
          <div className="logbook-message__content">
            {!this.props.isFromCurrentUser && <h3>{this.props.senderName}</h3>}
            {this.props.messageContent && <p>{this.props.messageContent}</p>}
          </div>
          <div className="logbook-message__timestamp">
            {DateUtils.getShorthandDateTimeStringFromString(this.props.dateTime)}
          </div>
        </div>
        {this.props.isLastReadMessage && !this.props.isLastMessage && (
          <div className="logbook-message__messages-divider">
            <span>
              {LanguageProvider.t(TranslationMapper.components.logbook.single_message_component.new_message_line)}
            </span>
          </div>
        )}
      </>
    );
  }
}
