import * as H from "history";

export const RoutingPath = {
  home: "/",
  schedule: "/schedule",
  cleaningObject: "/cleaningObject",
  notification: "/notification",
  pageNotFound: "/pageNotFound",
  error: "/error",
  null: "/null",
};

export interface IReactRouteStateParams {
  scheduleId?: string;
  cleaningObjectId?: string;
}

export default class RoutingProvider {
  public static invokePushNewRoute(
    routeHistoryProps: H.History,
    routePath: string,
    propToPass?: IReactRouteStateParams
  ): void {
    routeHistoryProps.push(routePath, propToPass ?? undefined);
  }

  public static invokePushNewRouteWithRefresh(routeHistoryProps: H.History, routePath: string): void {
    routeHistoryProps.push(routePath);

    window.location.reload();
  }

  public static clearLocationRouteState(routeHistoryProps: H.History): void {
    routeHistoryProps.replace(routeHistoryProps.location.pathname, undefined);
  }

  public static getRouteState(historyLocation: H.Location): IReactRouteStateParams | undefined {
    if (!historyLocation.state) {
      return;
    }
    const state = historyLocation.state;

    if (!this.isValidStateObject(state)) {
      return undefined;
    }
    return state;
  }

  private static isValidStateObject(stateObj: any): stateObj is IReactRouteStateParams {
    // Creates a bit more type safety then a simple cast, eventually we can extend this to add a similar check for the objects
    const isIdValid = stateObj.id === undefined || typeof stateObj.id === "string";
    const isReservationToShowOnMapValid =
      stateObj.reservationToShowOnMap === undefined || typeof stateObj.reservationToShowOnMap === "object";

    return isIdValid && isReservationToShowOnMapValid;
  }
}
