import { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DesignSystemExclusiveTypes from "./interfaces/DesignSystemExclusiveTypeEnum";
import IDesignSystemExclusiveProps from "./interfaces/IDesignSystemExclusiveProps";
import IDesignSystemExclusiveState from "./interfaces/IDesignSystemExclusiveState";

export default class DesignSystemExclusive extends Component<IDesignSystemExclusiveProps, IDesignSystemExclusiveState> {
  public constructor(props: IDesignSystemExclusiveProps) {
    super(props);

    const state: IDesignSystemExclusiveState = {};

    this.state = state;
  }

  public renderHeader(): JSX.Element {
    return <></>;
  }

  public renderButton(): JSX.Element {
    return (
      <>
        <hr />
        <div className="col-12 block-content--px block-content--py block-content--border">
          {/* eslint-disable-next-line */}
          <a id="button-content" style={{ scrollMarginTop: "11rem" }} />
          <div className="alert alert-primary" role="alert">
            <h4 className="alert-heading">
              <FontAwesomeIcon icon={["fal", "folder-closed"]} fixedWidth className="me-2" />
              Button content
            </h4>
          </div>
          <div>
            {/* Button content with all posibilities */}
            <button className="btn btn--content">
              <div className="btn--content_block">
                <div>
                  <div className="btn--content__content">
                    <h2>Title</h2>
                    <p>
                      Text
                      <br />
                      Text
                    </p>
                  </div>
                  <div className="btn--content__message">
                    <div className="btn--content__message-info">
                      <span className="fa-stack">
                        <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                        <FontAwesomeIcon
                          icon={["fas", "circle-exclamation"]}
                          fixedWidth
                          className="fa-stack-1x fa-xs"
                        />
                      </span>
                      <span>Text</span>
                    </div>
                    <div className="btn--content__message-info">
                      <span className="fa-stack">
                        <FontAwesomeIcon icon={["fal", "hourglass"]} fixedWidth className="fa-stack-1x" />
                        <FontAwesomeIcon
                          icon={["fas", "circle-exclamation"]}
                          fixedWidth
                          className="fa-stack-1x fa-xs"
                        />
                      </span>
                      <span>Text</span>
                    </div>
                  </div>
                </div>
                <div className="btn--content__end">
                  <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
                </div>
              </div>
            </button>

            {/* Button content with only title and subtext */}
            <button className="btn btn--content">
              <div className="btn--content_block">
                <div>
                  <div className="btn--content__content">
                    <h2>Title</h2>
                    <p>
                      Text
                      <br />
                      Text
                    </p>
                  </div>
                </div>
                <div className="btn--content__end">
                  <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
                </div>
              </div>
            </button>

            {/* Button content with only a title and empty message */}
            <button className="btn btn--content">
              <div className="btn--content_block">
                <div>
                  <div className="btn--content__content">
                    <h2>Title</h2>
                  </div>
                </div>
                <div className="btn--content__message"></div>
                <div className="btn--content__end">
                  <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
                </div>
              </div>
            </button>

            {/* Button content with only a title and nfc icon */}
            <button className="btn btn--content">
              <div className="btn--content_block">
                <div>
                  <div className="btn--content__content">
                    <h2>Title</h2>
                  </div>
                </div>
                <div className="btn--content__end">
                  <FontAwesomeIcon icon={["fal", "mobile-signal"]} fixedWidth className="align-middle" />
                </div>
              </div>
            </button>

            {/* Button content with a title messages and nfc icon */}
            <button className="btn btn--content">
              <div className="btn--content_block">
                <div>
                  <div className="btn--content__content">
                    <h2>Title</h2>
                  </div>
                  <div className="btn--content__message">
                    <div className="btn--content__message-info">
                      <span className="fa-stack">
                        <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                        <FontAwesomeIcon
                          icon={["fas", "circle-exclamation"]}
                          fixedWidth
                          className="fa-stack-1x fa-xs"
                        />
                      </span>
                      <span>Text</span>
                    </div>
                    <div className="btn--content__message-info">
                      <span className="fa-stack">
                        <FontAwesomeIcon icon={["fal", "hourglass"]} fixedWidth className="fa-stack-1x" />
                        <FontAwesomeIcon
                          icon={["fas", "circle-exclamation"]}
                          fixedWidth
                          className="fa-stack-1x fa-xs"
                        />
                      </span>
                      <span>Text</span>
                    </div>
                  </div>
                </div>
                <div className="btn--content__end">
                  <FontAwesomeIcon icon={["fal", "mobile-signal"]} fixedWidth className="align-middle" />
                </div>
              </div>
            </button>

            {/* Button content with only title messages and nfc icon and button */}
            <button className="btn btn--content">
              <div className="btn--content_block">
                <div>
                  <div className="btn--content__content">
                    <h2>Title</h2>
                  </div>
                  <div className="btn--content__message">
                    <div className="btn--content__message-info">
                      <span className="fa-stack">
                        <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                        <FontAwesomeIcon
                          icon={["fas", "circle-exclamation"]}
                          fixedWidth
                          className="fa-stack-1x fa-xs"
                        />
                      </span>
                      <span>Text</span>
                    </div>
                    <div className="btn--content__message-info">
                      <span className="fa-stack">
                        <FontAwesomeIcon icon={["fal", "hourglass"]} fixedWidth className="fa-stack-1x" />
                        <FontAwesomeIcon
                          icon={["fas", "circle-exclamation"]}
                          fixedWidth
                          className="fa-stack-1x fa-xs"
                        />
                      </span>
                      <span>Text</span>
                    </div>
                  </div>
                </div>
                <div className="btn--content__end">
                  <FontAwesomeIcon icon={["fal", "mobile-signal"]} fixedWidth className="align-middle" />
                </div>
              </div>
              <div className="btn--content_block">
                <button className="btn btn-outline-secondary btn--element-end w-100">
                  BTN.outline-primary
                  <FontAwesomeIcon icon={["fal", "circle-check"]} fixedWidth />
                </button>
              </div>
              <div className="btn--content_block">
                <button className="btn btn-outline-secondary w-100 me-2">BTN.outline-primary</button>
                <button className="btn btn-secondary w-100 ms-2">BTN.outline-primary</button>
              </div>
            </button>

            {/* Button content accorion */}
            <button className="btn btn--content rounded-bottom-0">
              <div className="btn--content_block">
                <div>
                  <div className="btn--content__content">
                    <h2>Begane grond (2)</h2>
                  </div>
                  <div className="btn--content__message">
                    <div className="btn--content__message-info">
                      <span className="fa-stack">
                        <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                        <FontAwesomeIcon
                          icon={["fas", "circle-exclamation"]}
                          fixedWidth
                          className="fa-stack-1x fa-xs"
                        />
                      </span>
                      <span>Text</span>
                    </div>
                  </div>
                </div>
                <div className="btn--content__end">
                  <FontAwesomeIcon icon={["fal", "chevron-down"]} fixedWidth className="align-middle" />
                </div>
              </div>
            </button>
            <div className="btn--content__accordion-items">
              <button className="btn btn--content  btn--content__accordion-item">
                <div className="btn--content_block">
                  <div>
                    <div className="btn--content__content">
                      <h3 className="mb-0">Zone 1</h3>
                    </div>
                    <div className="btn--content__message">
                      <div className="btn--content__message-info">
                        <span className="fa-stack">
                          <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                          <FontAwesomeIcon
                            icon={["fas", "circle-exclamation"]}
                            fixedWidth
                            className="fa-stack-1x fa-xs"
                          />
                        </span>
                        <span>Text</span>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="btn--content__end">
                    <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
                  </div>
                </div>
              </button>
              <button className="btn btn--content  btn--content__accordion-item">
                <div className="btn--content_block">
                  <div>
                    <div className="btn--content__content">
                      <h3 className="mb-0">Zone 2</h3>
                    </div>
                    <div className="btn--content__message">
                      <div className="btn--content__message-info">
                        <span className="fa-stack">
                          <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
                          <FontAwesomeIcon
                            icon={["fas", "circle-exclamation"]}
                            fixedWidth
                            className="fa-stack-1x fa-xs"
                          />
                        </span>
                        <span>Text</span>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="btn--content__end">
                    <FontAwesomeIcon icon={["fal", "mobile-signal"]} fixedWidth className="align-middle" />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  public renderNav(): JSX.Element {
    return (
      <ul className="nav nav-pills">
        <li className="nav-item">
          <a href="#button-content" className="nav-link">
            Buttons content
          </a>
        </li>
      </ul>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.props.type === DesignSystemExclusiveTypes.HEADER && this.renderHeader()}
        {this.props.type === DesignSystemExclusiveTypes.BUTTON && this.renderButton()}
        {this.props.type === DesignSystemExclusiveTypes.NAV && this.renderNav()}
      </>
    );
  }
}
