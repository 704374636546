import CheckoutTriggerType from "enums/checkoutTriggerType";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CustomerSelection from "../../components/customerSelection/customerSelection";
import Loader from "../../components/loader/loader";
import LocationOverview from "../../components/location/locationOverview";
import Nfc from "../../components/nfc/nfc";
import LoaderTypes from "../../enums/loaderTypes";
import { NfcScanType } from "../../enums/nfcScanType";
import { getUniversalReferenceObjectAsync } from "../../store/actions/scheduleActions";
import { RootState } from "../../store/reducers/rootReducer";
import NFCValidator from "../../utils/nfcValidator";
import IHomePageProps, { IHomePageDispatchProps, IHomePageStateProps } from "./interfaces/IHomePageProps";

class HomePage extends React.Component<IHomePageProps> {
  public constructor(props: IHomePageProps) {
    super(props);

    this.state = {
      isLoadingCleaningObjects: false,
    };

    this.onReceiveManualTagInput = this.onReceiveManualTagInput.bind(this);
    this.onReceiveNFCMessage = this.onReceiveNFCMessage.bind(this);
  }

  public onReceiveNFCMessage(input: any): void {
    const nfcMessage = NFCValidator.tryParseNfcMessage(input);

    if (!nfcMessage) {
      return;
    }

    const nfcRequest: INfcReferenceObjectRequest = {
      id: nfcMessage.id,
      code: nfcMessage.message,
    };

    this.props.onNfcTagScanned(nfcRequest, this.props.history, CheckoutTriggerType.NfcTagScanned);
  }

  private onReceiveManualTagInput(tag: string): void {
    const nfcRequest: INfcReferenceObjectRequest = {
      id: undefined,
      code: tag,
    };

    this.props.onNfcTagScanned(nfcRequest, this.props.history, CheckoutTriggerType.NfcTagManual);
  }

  private getCustomerSelectionPage(): JSX.Element {
    if (this.props.isLoadingLocations) {
      return <Loader isLoading={this.props.isLoadingLocations} />;
    } else {
      return <CustomerSelection />;
    }
  }

  private renderLocationOverview(): JSX.Element {
    return (
      <>
        {this.props.isLoadingOperatorLocations && (
          <div className="loader-container">
            <Loader isLoading={true} />
          </div>
        )}
        {!this.props.isLoadingOperatorLocations && <LocationOverview />}
        {this.props.showNfcFooter && (
          <Nfc
            scanType={NfcScanType.Building}
            onGetByManualInput={this.onReceiveManualTagInput}
            callback={this.onReceiveNFCMessage}
          />
        )}
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {!this.props.activeCustomer && this.getCustomerSelectionPage()}
        {this.props.activeCustomer && this.renderLocationOverview()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IHomePageStateProps => ({
  schedule: state.scheduleState.schedule,
  isLoadingLocations: state.generalState.loaders.some(
    (loader) => loader === LoaderTypes.OperatorLocations || loader === LoaderTypes.NfcScan
  ),
  activeCustomer: state.customerState.activeCustomer,
  showNfcFooter:
    state.scheduleState.locations?.some((l) => l.customerId === state.customerState.activeCustomer && l.hasNfc) ??
    false,
  isLoadingOperatorLocations: state.notificationState.loaders.some(
    (loader) => loader === LoaderTypes.OperatorLocations
  ),
});

const mapDispatchToProps: IHomePageDispatchProps = {
  onNfcTagScanned: getUniversalReferenceObjectAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
