import { TranslationMapper } from "i18n/mapper";
import moment, { Moment } from "moment";
import LanguageProvider from "providers/languageProvider";

export default class DateUtils {
  public static getTimeString(date: Date): string {
    return moment(date).format("H:mm");
  }

  public static getDateString(date: Date): string {
    return moment(date).format("DD-MM-YYYY");
  }

  public static getMonthName(date: Date): string {
    const monthLabels = [
      TranslationMapper.global.date_formats.months.january,
      TranslationMapper.global.date_formats.months.february,
      TranslationMapper.global.date_formats.months.march,
      TranslationMapper.global.date_formats.months.april,
      TranslationMapper.global.date_formats.months.may,
      TranslationMapper.global.date_formats.months.june,
      TranslationMapper.global.date_formats.months.july,
      TranslationMapper.global.date_formats.months.august,
      TranslationMapper.global.date_formats.months.september,
      TranslationMapper.global.date_formats.months.october,
      TranslationMapper.global.date_formats.months.november,
      TranslationMapper.global.date_formats.months.december,
    ];
    const monthLabel = monthLabels[date.getMonth()];
    return LanguageProvider.t(monthLabel);
  }

  public static getMonthShortName(date: Date): string {
    const monthLabels = [
      TranslationMapper.global.date_formats.months_shorthand.january,
      TranslationMapper.global.date_formats.months_shorthand.february,
      TranslationMapper.global.date_formats.months_shorthand.march,
      TranslationMapper.global.date_formats.months_shorthand.april,
      TranslationMapper.global.date_formats.months_shorthand.may,
      TranslationMapper.global.date_formats.months_shorthand.june,
      TranslationMapper.global.date_formats.months_shorthand.july,
      TranslationMapper.global.date_formats.months_shorthand.august,
      TranslationMapper.global.date_formats.months_shorthand.september,
      TranslationMapper.global.date_formats.months_shorthand.october,
      TranslationMapper.global.date_formats.months_shorthand.november,
      TranslationMapper.global.date_formats.months_shorthand.december,
    ];
    const monthLabel = monthLabels[date.getMonth()];
    return LanguageProvider.t(monthLabel);
  }

  public static getWeekDayName(date: Date): string {
    const dayLabels = [
      TranslationMapper.global.date_formats.weekdays.sunday,
      TranslationMapper.global.date_formats.weekdays.monday,
      TranslationMapper.global.date_formats.weekdays.tuesday,
      TranslationMapper.global.date_formats.weekdays.wednesday,
      TranslationMapper.global.date_formats.weekdays.thursday,
      TranslationMapper.global.date_formats.weekdays.friday,
      TranslationMapper.global.date_formats.weekdays.saturday,
    ];
    const dayLabel = dayLabels[date.getDay()];
    return LanguageProvider.t(dayLabel);
  }

  public static getFriendlyDateString(date: Date): string {
    return `${this.formatDateAsDayNumberString(date)} ${this.getMonthName(date).toLowerCase()}`;
  }

  public static getFriendlyDateTimeString(date: Date): string {
    const formattedTime = this.getTimeString(date);
    return `${this.formatDateAsDayNumberString(date)} ${this.getMonthName(date).toLowerCase()} ${formattedTime}`;
  }

  public static getTimeDifferenceInMinutes(start: Date, end: Date): number {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return duration.asMinutes();
  }

  public static utcToLocal(date: string | Date): Date {
    return moment.utc(date).local().toDate();
  }

  public static formatDateToYearMonthDay(date?: Date): string | undefined {
    if (!date) {
      return undefined;
    }
    return moment(date).format("yyyy-MM-DD");
  }

  public static addDays(date: Date, days: number): Date {
    return moment(date).add(days, "days").toDate();
  }

  public static getStartOfDay(date: Date): Date {
    return moment(date).startOf("day").toDate();
  }

  public static getShorthandDateTimeStringFromString(dateString: string): string {
    return this.getShorthandDateTimeString(new Date(dateString));
  }

  public static getShorthandDateTimeString(date: Date): string {
    const formattedTime = this.getTimeString(date);
    return `${this.formatDateAsDayNumberString(date)} ${this.getMonthShortName(date).toLowerCase()} ${formattedTime}`;
  }

  public static convertMinutesToMs(minutes: number): number {
    return minutes * 60000;
  }

  public static formatDateAsDayNumberString(date: Moment | Date): string {
    return moment(date).format("D");
  }
}
