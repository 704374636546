import IChannelMessages from "interfaces/IChannelMessages";
import ILogbook from "interfaces/ILogbook";
import ILogbookMessageRequest from "interfaces/request/ILogbookMessageRequest";

import LogbookClient from "./clients/logbookClient";

export default class LogbookService {
  private readonly logbookClient: LogbookClient = new LogbookClient();

  public async getLogbookAsync(topicIds: string[]): Promise<ILogbook> {
    const logbook = await this.logbookClient.getLogbookAsync(topicIds);
    logbook.channelResponse.forEach((c) => {
      if (c.dateTimeLastMessage != null) {
        c.dateTimeLastMessage = new Date(c.dateTimeLastMessage).toISOString();
      }
    });

    return logbook;
  }

  public async getChannelMessagesAsync(topicId: string): Promise<IChannelMessages> {
    return this.logbookClient.getChannelMessagesAsync(topicId);
  }

  public async sendLogbookMessageAsync(messageRequest: ILogbookMessageRequest): Promise<Response> {
    return this.logbookClient.sendLogbookMessageAsync(messageRequest);
  }
}
