import { CmApiAuthenticator } from "authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "config";
import IChannelMessages from "interfaces/IChannelMessages";
import ILogbook from "interfaces/ILogbook";
import ILogbookMessageRequest from "interfaces/request/ILogbookMessageRequest";

import BaseClient from "./baseClient";

export default class LogbookClient extends BaseClient {
  private readonly endpoint: string = "api/logbook";

  public constructor() {
    super(ApplicationConfig.endpoints.cmApi, new CmApiAuthenticator());
  }

  public async getLogbookAsync(topicIds: string[]): Promise<ILogbook> {
    return this.postWithResponse<ILogbook>(this.endpoint, { topicIds });
  }

  public async getChannelMessagesAsync(topicId: string): Promise<IChannelMessages> {
    return this.get<IChannelMessages>(`${this.endpoint}/channel/${topicId}`);
  }

  public async sendLogbookMessageAsync(messageRequest: ILogbookMessageRequest): Promise<Response> {
    return this.post(`${this.endpoint}/message`, messageRequest);
  }
}
