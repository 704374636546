import LoginFailedImage from "assets/img/illustration/login-failed.svg";
import MsalFactory from "factories/msalFactory";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withRouter } from "react-router-dom";

import Logo from "../../../assets/img/logo.svg";
import RoutingProvider, { RoutingPath } from "../../../providers/routingProvider";
import IErrorDisplayProps from "./interfaces/IErrorDisplayProps";

class ErrorDisplay extends React.Component<IErrorDisplayProps, {}> {
  public constructor(props: IErrorDisplayProps) {
    super(props);

    this.retryLoadingPage = this.retryLoadingPage.bind(this);
  }

  private retryLoadingPage(): void {
    RoutingProvider.invokePushNewRouteWithRefresh(this.props.history, RoutingPath.home);
  }

  private signout(): void {
    MsalFactory.logoutAllAccounts(null);
  }

  private get showGoHomeButton(): boolean {
    const path = window.location.pathname;
    return path !== "" && path !== "/";
  }

  public render(): JSX.Element {
    return (
      <div className="app">
        <header className="header">
          <div className="nav-spacing--start">
            <img src={Logo} className="logo" alt="Hi Five" />
          </div>
        </header>
        <main>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 block-content--px block-content--py">
                  <div className="container bg-white pt-5 pb-5">
                    <div className="row justify-content-center">
                      <div className="col-md-6 text-center">
                        <img src={LoginFailedImage} className="illustration mb-4" alt="login failed" />
                        <h2 className="text-infographic">{LanguageProvider.t(this.props.errorTitle)}</h2>
                        {this.props.errorText && <p>{LanguageProvider.t(this.props.errorText)}</p>}
                        <p className="mb-4">
                          <small>Error code {this.props.errorCode}</small>
                        </p>
                        <div>
                          {this.showGoHomeButton && (
                            <button className="btn btn-primary mb-2" onClick={this.retryLoadingPage}>
                              {LanguageProvider.t(TranslationMapper.global.errors.reload_home)}
                            </button>
                          )}
                        </div>
                        <div>
                          <button className="btn btn-primary" onClick={this.signout}>
                            {LanguageProvider.t(TranslationMapper.global.errors.try_again)}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(ErrorDisplay);
