import Button from "components/material/buttons/button";
import CustomSelect from "components/material/select/customSelect";
import { ICustomSelectOption } from "components/material/select/interfaces/ICustomSelectProps";
import ModalComponent from "components/modal/modal";
import UnreadMessagesCounter from "components/unreadMessagesCounter/unreadMessagesCounter";
import LoaderTypes from "enums/loaderTypes";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withTelemetry } from "services/telemetryService";
import { fetchLogbookChannelsForUser, sendMessageForUser } from "store/actions/logbookActions";
import { RootState } from "store/reducers/rootReducer";
import { isNullOrEmpty } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ILogbookProps, { ILogbookDispatchProps, ILogbookStateProps } from "./interfaces/ILogbookProps";
import ILogbookState from "./interfaces/ILogbookState";
import LogbookMessages from "./logbookMessages";

class Logbook extends React.Component<ILogbookProps, ILogbookState> {
  public constructor(props: ILogbookProps) {
    super(props);

    const state: ILogbookState = {
      showLogbook: false,
      selectedTopicId: this.props.selectedLocationProjectId,
    };

    this.state = state;

    this.onSelectedTopicChange = this.onSelectedTopicChange.bind(this);
    this.closeLogbook = this.closeLogbook.bind(this);
    this.openLogbook = this.openLogbook.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.clearContents = this.clearContents.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onMessageSentSuccessfully = this.onMessageSentSuccessfully.bind(this);
  }

  public componentDidMount(): void {
    // Fetching channels is handled by store
    // this fetch is a failsafe because first fetch can be skipped due to flags
    this.props.onFetchLogbookChannels(true, this.props.topicIds);
  }

  private get isChannelMessageLoading(): boolean {
    return this.props.isChannelMessageLoading;
  }

  private get selectedTopicId(): string | undefined {
    return this.state.selectedTopicId ?? this.props.selectedLocationProjectId;
  }

  private onSelectedTopicChange(topicId?: string): void {
    this.setState({
      selectedTopicId: topicId || undefined,
    });
  }

  private get dropdownOptions(): ICustomSelectOption[] {
    return (
      this.props.logbook?.channelResponse.map((channel) => {
        const showUnreadMessagesCounter = channel.unreadMessagesCount != null && channel.unreadMessagesCount > 0;
        const customerName = this.props.locations?.find((l) => l.projectId === channel.topicId)?.customerName;
        const option: ICustomSelectOption = {
          key: channel.topicId,
          optionName: channel.channelName,
          subtext: customerName,
          additionalComponent: showUnreadMessagesCounter ? (
            <UnreadMessagesCounter
              unreadMessagesCount={channel.unreadMessagesCount}
              showMessageCounter={showUnreadMessagesCounter}
            />
          ) : undefined,
        };
        return option;
      }) ?? []
    );
  }

  private get renderTopicSelector(): JSX.Element {
    return (
      <div className="logbook__sticky">
        <CustomSelect
          isDisabled={this.props.isChannelLoading}
          onChange={this.onSelectedTopicChange}
          enableSearch={true}
          options={this.dropdownOptions}
          placeholder={LanguageProvider.t(TranslationMapper.components.logbook.select_venue)}
          selectedKey={this.state.selectedTopicId}
        />
      </div>
    );
  }

  private get shouldRenderTopicSelector(): boolean {
    return (!!this.props.logbook && this.props.logbook.channelResponse.length > 1) || !this.selectedTopicId;
  }

  private get renderLogbook(): JSX.Element {
    return (
      <>
        {this.shouldRenderTopicSelector && this.renderTopicSelector}
        <LogbookMessages selectedTopicId={this.selectedTopicId} />
      </>
    );
  }

  private onMessageChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    let value = event.currentTarget.value;

    if (value != null) {
      // Strip input of new lines (enter) in textarea
      value = value.replace(/[\r\n]/gm, "");
    }

    this.setState({ newMessageContent: value });
  }

  private onMessageSentSuccessfully(): void {
    if (this.selectedTopicId) {
      this.setState({
        newMessageContent: undefined,
      });
    }
  }

  private async sendMessage(): Promise<void> {
    if (this.selectedTopicId && this.hasNewMessageInput && !this.isChannelMessageLoading) {
      this.props.onSendMessage(
        this.selectedTopicId,
        this.state.newMessageContent ?? "",
        this.onMessageSentSuccessfully
      );
    }
  }

  private clearContents(): void {
    this.setState({
      newMessageContent: undefined,
    });
  }

  private get hasNewMessageInput(): boolean {
    return this.state.newMessageContent != null && !isNullOrEmpty(this.state.newMessageContent);
  }

  private get renderMessageInputField(): JSX.Element {
    return (
      <div className="logbook__add-message-container">
        <div className="logbook__add-message-input-container">
          <Form.Control
            maxLength={1000}
            placeholder={LanguageProvider.t(
              TranslationMapper.components.logbook.messages_component.message_input_placeholder
            )}
            aria-label="Message"
            as="textarea"
            value={this.state.newMessageContent ?? ""}
            onChange={this.onMessageChange}
            disabled={this.props.isChannelLoading || !this.selectedTopicId}
          />
        </div>
        <button
          className="btn btn-primary btn--rounded"
          disabled={this.isChannelMessageLoading || !this.hasNewMessageInput || !this.hasCurrentActiveLocationLogbook}
          type="button"
          onClick={this.sendMessage}
        >
          <FontAwesomeIcon icon={["fal", "paper-plane-top"]} fixedWidth className="fa-rotate-by" />
        </button>
      </div>
    );
  }

  private openLogbook(): void {
    this.setState({
      showLogbook: true,
      selectedTopicId: this.props.selectedLocationProjectId,
    });
  }

  private closeLogbook(): void {
    this.setState({
      showLogbook: false,
      selectedTopicId: undefined,
    });

    if (this.props.onCloseLogbook) {
      this.props.onCloseLogbook();
    }
  }

  private get shouldRenderUnreadMessagesIconForActiveLocation(): boolean {
    return (
      (!this.props.activeLocation ||
        (this.props.activeLocation &&
          this.props.logbook?.channelResponse.some(
            (c) =>
              c.topicId === this.props.activeLocation?.projectId && c.unreadMessagesCount && c.unreadMessagesCount > 0
          ))) ??
      false
    );
  }

  private get hasCurrentActiveLocationLogbook(): boolean {
    return (
      !this.selectedTopicId ||
      this.props.logbook?.channelResponse.find((c) => c.topicId === this.selectedTopicId) != null
    );
  }

  public render(): JSX.Element {
    const showUnreadDot =
      this.props.logbook?.totalNumberOfUnreadMessages &&
      this.props.logbook?.totalNumberOfUnreadMessages > 0 &&
      this.shouldRenderUnreadMessagesIconForActiveLocation
        ? true
        : false;
    return (
      <div className="toggle-logbook-modal-button">
        {this.props.showLogbookButton && this.hasCurrentActiveLocationLogbook && (
          <div className="d-inline-block position-relative">
            <Button className="btn-link btn-sm" iconStart={["fal", "comments"]} onClick={this.openLogbook} />
            {showUnreadDot && <span className="unread-messages-dot"></span>}
          </div>
        )}

        {
          <ModalComponent
            show={this.props.showLogbook ?? this.state.showLogbook}
            showHeader={true}
            onModalHide={this.closeLogbook}
            content={this.renderLogbook}
            footerContent={this.renderMessageInputField}
            titleResourceLabel={TranslationMapper.components.logbook.header}
            fullscreen={true}
            className="modal--logbook logbook modal__fullscreen"
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ILogbookStateProps => ({
  channelMessages: state.logbookState.selectedChannelMessages,
  logbook: state.logbookState.logbook,
  isChannelLoading: state.generalState.loaders.some((l) => l === LoaderTypes.LogbookChannel),
  isChannelMessageLoading: state.generalState.loaders.some((l) => l === LoaderTypes.LogbookChannelMessage),
  topicIds: state.scheduleState.locations?.map((l) => l.projectId) ?? [],
  locations: state.scheduleState.locations ?? [],
  activeLocation: state.scheduleState.selectedLocation,
});

const mapDispatchToProps: ILogbookDispatchProps = {
  onSendMessage: sendMessageForUser,
  onFetchLogbookChannels: fetchLogbookChannelsForUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTelemetry(Logbook, "Logbook"));
