import Loader from "enums/loaderTypes";
import IReactSelectValue from "interfaces/IReactSelectValue";

export interface IActivityState {
  loaders: Loader[];
  cancellationReasons?: IReactSelectValue[];
}

export const initialActivityState: IActivityState = {
  loaders: [],
};
