import { ApplicationConfig } from "config";
import { TranslationMapper } from "i18n/mapper";
import i18next from "i18next";
import IFeatureFlag from "interfaces/IFeatureFlag";
import { FlagNames } from "providers/featureFlagProvider";
import FlagService from "services/flagService";
import EnvironmentUtils from "utils/environmentUtils";

import LoaderTypes from "../../enums/loaderTypes";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const startLoading = actionCreator<ActionTypes.START_LOADING, LoaderTypes>(ActionTypes.START_LOADING);
export const finishLoading = actionCreator<ActionTypes.FINISH_LOADING, LoaderTypes>(ActionTypes.FINISH_LOADING);
export const interceptedError = actionCreator<ActionTypes.INTERCEPTED_ERROR, Error | undefined>(
  ActionTypes.INTERCEPTED_ERROR
);
export const fetchedFeatureFlags = actionCreator<ActionTypes.FETCHED_FEATUREFLAGS, IFeatureFlag[]>(
  ActionTypes.FETCHED_FEATUREFLAGS
);

const featureFlagStoragePath: string = `cleaning.app.${ApplicationConfig.environment}.featureFlags`;
const featureFlagTimestampPath: string = `${featureFlagStoragePath}.timeStamp`;

export function setAsLoading(loader: LoaderTypes): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(startLoading(loader));
  };
}

export function unsetAsLoading(loader: LoaderTypes): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(finishLoading(loader));
  };
}

export function interceptError(error: any): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    if (!error) {
      error = i18next.t(TranslationMapper.global.errors.general);
    }
    dispatch(interceptedError(error));
  };
}

export function clearError(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    dispatch(interceptedError(undefined));
  };
}

export function checkFlagsExpiration(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    const currentTime = new Date();
    const flagsTimeStamp = getFlagsTimeStamp();

    if (!flagsTimeStamp) {
      return;
    }

    const differenceInMinutes = (dateTimeA: Date, dateTimeB: Date): number =>
      Math.round((dateTimeA.getTime() - dateTimeB.getTime()) / 1000 / 60);
    const minutesSinceUpdate = differenceInMinutes(currentTime, flagsTimeStamp);

    let flagExpirationTimeInMinutes = 5;
    if (EnvironmentUtils.isTestOrDevEnvironment()) {
      flagExpirationTimeInMinutes = 30;
    }

    if (minutesSinceUpdate >= flagExpirationTimeInMinutes) {
      dispatch(getFeatureFlagsAsync(true));
    }
  };
}

export function getFeatureFlagsAsync(shouldClearOldFlags: boolean): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    const featureFlagNames = Object.values(FlagNames);
    const featureFlagsService = new FlagService();

    featureFlagsService.GetFlagsByNameAsync(featureFlagNames).then(featureFlags => {
      if (shouldClearOldFlags) {
        featureFlagNames.forEach(flag => {
          removeFlagFromStorage(flag);
        });
      }

      const featureFlagsForStore: IFeatureFlag[] = updateAndStoreFeatureFlagsWithUserSetting(featureFlags);

      dispatch(fetchedFeatureFlags(featureFlagsForStore));
    });
  };
}

export function updateFlagsWithStorageValue(storageUpdate: StorageEvent): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const flags = Object.values(FlagNames);
    const featureFlagsService = new FlagService();

    if (!storageUpdate.key || !storageUpdate.key.includes(featureFlagStoragePath)) {
      return;
    }

    featureFlagsService.GetFlagsByNameAsync(flags).then(featureFlags => {
      const featureFlagsForStore: IFeatureFlag[] = updateAndStoreFeatureFlagsWithUserSetting(featureFlags);
      dispatch(fetchedFeatureFlags(featureFlagsForStore));
    });
  };
}

function updateAndStoreFeatureFlagsWithUserSetting(featureFlags: IFeatureFlag[]): IFeatureFlag[] {
  const retFeatureFlags: IFeatureFlag[] = [];

  featureFlags.forEach(featureFlag => {
    const flagIsActive = getFlagFromStorage(featureFlag.flagName);

    if (flagIsActive != null) {
      retFeatureFlags.push({ flagName: featureFlag.flagName, active: flagIsActive });
    } else {
      saveFlagInStorage(featureFlag.flagName, featureFlag.active);
      retFeatureFlags.push(featureFlag);
    }
  });

  return retFeatureFlags;
}

function saveFlagInStorage(flagName: string, flagValue: boolean): void {
  if (!flagName) {
    return;
  }

  const flagTimeStamp = JSON.stringify(new Date());

  sessionStorage.setItem(`${featureFlagStoragePath}.${flagName}`, flagValue.toString());

  sessionStorage.setItem(featureFlagTimestampPath, flagTimeStamp);
}

function getFlagFromStorage(flagName: string | typeof FlagNames): boolean | null {
  const value = sessionStorage.getItem(`${featureFlagStoragePath}.${flagName}`);
  return value ? value === "true" : null;
}

function getFlagsTimeStamp(): Date | undefined {
  const storageItem = sessionStorage.getItem(featureFlagTimestampPath);
  return storageItem ? new Date(JSON.parse(storageItem)) : undefined;
}

function removeFlagFromStorage(flagName: string): void {
  sessionStorage.removeItem(`${featureFlagStoragePath}.${flagName}`);
}
