import React from "react";
import { Form } from "react-bootstrap";

import BaseInput from "./baseInput";

export default class NumericInput extends BaseInput {
  public render(): JSX.Element {
    return (
      <Form.Control
        id={this.props.id}
        name={this.props.id}
        className={this.className}
        type="number"
        onChange={this.onChange}
        disabled={this.props.inputDisabled}
        value={this.props.answer ?? ""}
      ></Form.Control>
    );
  }
}
