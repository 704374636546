import { ApplicationConfig } from "../config";
import { IAuthenticator } from "./interfaces/IAuthenticator";
import { MsalAuthenticator } from "./msalAuthenticator";

export class CmApiAuthenticator extends MsalAuthenticator implements IAuthenticator {
  public async authenticateRequestAsync(request: RequestInit): Promise<RequestInit> {
    const accessToken = await this.acquireTokenAsync(ApplicationConfig.scopes.cmApi);
    request.headers = { ...request.headers, Authorization: `Bearer ${accessToken}` };
    return request;
  }
}
