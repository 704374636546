export class StyleUtils {
  public static getCSSPropertyValue(cssProperty: string, fallbackValue?: string): string {
    let propValue = window.getComputedStyle(document.documentElement).getPropertyValue(cssProperty);

    if ((propValue == null || propValue === "") && !(fallbackValue == null || fallbackValue === "")) {
      propValue = fallbackValue;
    }

    return propValue.trim();
  }

  public static getBreakpointValue(
    breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl",
    fallbackValue?: string
  ): number {
    const cssVariable = `--breakpoint-${breakpoint}`;
    const cssValue = this.getCSSPropertyValue(cssVariable, fallbackValue);

    return parseInt(cssValue);
  }

  public static maintainViewportSize(): void {
    this.setViewportSize();
    // Viewport on mobile can vary, for example when a virtual keyboard is opened.
    // By using @include full-viewport-height as css prop you get 100% of current viewport
    // By using the function getViewportHeightPercentage($percentage) you get a percentage
    window.addEventListener("resize", () => {
      // Update size when resize is detected
      this.setViewportSize();
    });
  }

  private static setViewportSize(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--viewport-height", `${vh}px`);
  }
}
