import React, { Component } from "react";

import Activities from "../../components/activities/activities";

class CleaningObjectPage extends Component {
  public render(): JSX.Element {
    return (
      <>
        <Activities />
      </>
    );
  }
}

export default CleaningObjectPage;
