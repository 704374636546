import Loader from "enums/loaderTypes";
import IFeatureFlag from "interfaces/IFeatureFlag";

export interface IGeneralStoreState {
  loaders: Loader[];
  featureFlags: IFeatureFlag[];
  error?: Error;
  errorCode?: string;
}

export const initialGeneralStoreState: IGeneralStoreState = {
  loaders: [],
  featureFlags: [],
};
