import ErrorTypes from "enums/errorTypes";
import LoaderTypes from "enums/loaderTypes";
import * as H from "history";
import { TranslationMapper } from "i18n/mapper";
import i18next from "i18next";
import INotification from "interfaces/INotification";
import INotificationRequest from "interfaces/INotificationRequest";
import INotificationStatusUpdate from "interfaces/INotificationStatusUpdate";
import RoutingProvider, { RoutingPath } from "providers/routingProvider";
import { NotificationManager } from "react-notifications";
import NotificationService from "services/notificationService";

import INotificationCategoryItem from "../../interfaces/INotificationCategoryItem";
import TelemetryService from "../../services/telemetryService";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";
import { resetError, setError } from "./errorActions";
import { finishLoading, startLoading } from "./generalActions";
import { getCleaningObjectByIdAsync, getScheduleByIdAsync } from "./scheduleActions";

export const fetchedNotificationCategories = actionCreator<
  ActionTypes.FETCHED_NOTIFICATION_CATEGORIES,
  { cleaningObjectId: string; notificationCategories: INotificationCategoryItem[] | undefined }
>(ActionTypes.FETCHED_NOTIFICATION_CATEGORIES);
export const fetchedNotification = actionCreator<ActionTypes.FETCHED_NOTIFICATION, INotification>(
  ActionTypes.FETCHED_NOTIFICATION
);

export function updateNotificationStatusAsync(
  notificationStatusUpdate: INotificationStatusUpdate,
  cleaningObjectId: string,
  routeHistory: H.History
): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getstate) => {
    const notificationService = new NotificationService();
    notificationService.updateNotificationStatusAsync(notificationStatusUpdate).then((response: Response) => {
      if (response.ok) {
        dispatch(
          getCleaningObjectByIdAsync(getstate().scheduleState.schedule?.id ?? "", cleaningObjectId, routeHistory)
        );
        dispatch(getScheduleByIdAsync(routeHistory, undefined, false));
        NotificationManager.success(i18next.t(TranslationMapper.components.notifications.updated));
      } else {
        const error = i18next.t(TranslationMapper.global.errors.error_updating_notification);

        NotificationManager.error(error);
        TelemetryService.AppInsights?.trackException({
          exception: { name: "updateNotification exception", message: error },
        });
      }
    });
  };
}

export function createNotificationAsync(notification: INotificationRequest, routeHistory: H.History): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getstate) => {
    dispatch(startLoading(LoaderTypes.NotificationCreate));
    dispatch(resetError(ErrorTypes.NotificationCreate));
    const notificationService = new NotificationService();
    notificationService
      .createNotificationAsync(notification)
      .then((response: Response) => {
        if (response.ok) {
          dispatch(
            getCleaningObjectByIdAsync(
              getstate().scheduleState.schedule?.id ?? "",
              notification.cleaningObjectId,
              routeHistory
            )
          );
          dispatch(getScheduleByIdAsync(routeHistory, undefined, false));
        } else {
          const error = i18next.t(TranslationMapper.global.errors.error_creating_notification);
          dispatch(setError(ErrorTypes.NotificationCreate));
          TelemetryService.AppInsights?.trackException({
            exception: { name: "createNotification exception", message: error },
          });
        }
      })
      .catch(() => {
        dispatch(setError(ErrorTypes.NotificationCreate));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.NotificationCreate));
      });
  };
}

export function getNotificationCategoriesForCleaningObjectAsync(cleaningObjectId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    dispatch(startLoading(LoaderTypes.Notifications));
    const notificationService = new NotificationService();
    notificationService
      .getNotificationCategoriesForCleaningObjectAsync(cleaningObjectId)
      .then((notificationCategories: INotificationCategoryItem[]) => {
        if (notificationCategories != null && notificationCategories.length > 0) {
          dispatch(fetchedNotificationCategories({ cleaningObjectId, notificationCategories }));
        }
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.Notifications));
      });
  };
}

export function getNotificationAsync(notificationId: string, routeHistory: H.History): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    dispatch(startLoading(LoaderTypes.Notifications));
    const notificationService = new NotificationService();
    notificationService
      .getNotificationAsync(notificationId)
      .then((notification: INotification) => {
        dispatch(fetchedNotification(notification));
        RoutingProvider.invokePushNewRoute(routeHistory, RoutingPath.notification);
      })
      .catch((e) => {
        TelemetryService.AppInsights?.trackException({ exception: { name: "getNotification exception", message: e } });
        NotificationManager.error(i18next.t(TranslationMapper.pages.notification_page.no_notification_found));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.Notifications));
      });
  };
}
