export class ScriptUtil {
  private static readonly clarityId: string | undefined = process.env.REACT_APP_ClarityId;

  public static addClarityScript(): void {
    if (this.clarityId === "") {
      return;
    }

    const scriptEl = document.createElement("script");
    scriptEl.setAttribute("type", "text/javascript");
    scriptEl.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);
        t.async=1;
        t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t,y);
        })
        (window, document, "clarity", "script", '${this.clarityId}');`;

    document.head.insertBefore(scriptEl, document.head.firstChild);
  }
}
