import FeatureFlagProvider, { FlagNames } from "providers/featureFlagProvider";
import DateUtils from "utils/dateUtils";
import { ScriptUtil } from "utils/scriptUtil";

import {
    checkFlagsExpiration, getFeatureFlagsAsync, updateFlagsWithStorageValue
} from "./actions/generalActions";
import { fetchLogbookChannelsForUser } from "./actions/logbookActions";
import { getAllScheduledLocationsAsync } from "./actions/scheduleActions";
import { AppAction } from "./appAction";

export function fetchInitialConfiguration(): any {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    dispatch(getFeatureFlagsAsync(false));
    dispatch(ensureFlagsUpToDate());
    dispatch(getAllScheduledLocationsAsync(manageLogbookRefreshInterval)).then(() => {
      if (FeatureFlagProvider.isFeatureActive(FlagNames.CleaningApp_FF_Is_Clarity_Active)) {
        ScriptUtil.addClarityScript();
      }
    });
  };
}

function manageLogbookRefreshInterval(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    setInterval(() => dispatch(fetchLogbookChannelsForUser(false)), 60000);
  };
}

function ensureFlagsUpToDate(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async dispatch => {
    // Check for storage updates and update flags on change
    window.addEventListener("storage", (e: StorageEvent) => dispatch(updateFlagsWithStorageValue(e)));

    // Check validity of flags on time interval. SetInterval isn't possible on dispatches
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    (function _r() {
      setTimeout(() => {
        dispatch(checkFlagsExpiration());
        _r();
      }, DateUtils.convertMinutesToMs(5));
    })();
  };
}
