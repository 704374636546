import { CmApiAuthenticator } from "authenticators/cmApiAuthenticator";
import ICleaningObject from "interfaces/ICleaningObject";
import ILocationOverview from "interfaces/ILocationOverview";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import ISchedule from "interfaces/ISchedule";
import IUniversalReferenceResponse from "interfaces/IUniversalReferenceResponse";

import { ApplicationConfig } from "../../config";
import BaseClient from "./baseClient";

export default class ScheduleClient extends BaseClient {
  private endpoint: string = "api/schedule";

  public constructor() {
    super(ApplicationConfig.endpoints.cmApi, new CmApiAuthenticator());
  }

  public async getScheduleByLocationAsync(locationId: string): Promise<ISchedule> {
    return this.get<ISchedule>(`${this.endpoint}/location/${locationId}`);
  }

  public async getCleaningObjectById(projectId: string, cleaningObjectId: string): Promise<ICleaningObject> {
    return this.get<ICleaningObject>(`${this.endpoint}/project/${projectId}/cleaningobjectbyid/${cleaningObjectId}`);
  }

    public async getScheduledLocationsAsync(): Promise<ILocationOverview[]> {
        return this.get<ILocationOverview[]>(`${this.endpoint}/operator/locations`);
    }

  public async getScheduleByFloorAsync(floorId: string, projectId?: string): Promise<ISchedule> {
    return this.postWithResponse<ISchedule>(`${this.endpoint}/location/floor`, {
      floorId: floorId,
      projectId: projectId,
    });
  }

  public async getUniversalReferenceObject(
    nfcReferenceRequestObject: INfcReferenceObjectRequest
  ): Promise<IUniversalReferenceResponse> {
    return this.postWithResponse<IUniversalReferenceResponse>(
      `${this.endpoint}/nfcreferenceobject`,
      nfcReferenceRequestObject
    );
  }
}
