import Logbook from "components/logbook/logbook";
import Button from "components/material/buttons/button";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clearActiveLocation, clearScannedFloorDispatcher } from "store/actions/scheduleActions";

import Logo from "../../assets/img/logo.svg";
import InfoModal from "../../components/modal/infoModal";
import RoutingProvider, { RoutingPath } from "../../providers/routingProvider";
import { setActiveCustomer } from "../../store/actions/customerActions";
import { RootState } from "../../store/reducers/rootReducer";
import UserSettings from "../userSettings/userSettings";
import { IMainHeaderDispatchProps, IMainHeaderProps, IMainHeaderStateProps } from "./interfaces/IMainHeaderProps";
import IMainHeaderState from "./interfaces/IMainHeaderState";

class MainHeader extends React.Component<IMainHeaderProps, IMainHeaderState> {
  public constructor(props: IMainHeaderProps) {
    super(props);

    const state: IMainHeaderState = {
      showInfoModal: false,
    };

    this.state = state;

    this.toPreviousPage = this.toPreviousPage.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.onCloseInfoModal = this.onCloseInfoModal.bind(this);
  }

  public componentDidUpdate(prevProps: IMainHeaderProps): void {
    if (localStorage.getItem("initialized") == null) {
      localStorage.setItem("initialized", "true");
      this.setState({
        showInfoModal: true,
      });
    }
  }

  public componentDidMount(): void {
    switch (this.props.location.pathname) {
      case RoutingPath.notification:
      case RoutingPath.cleaningObject:
      case RoutingPath.schedule:
        if (!this.props.activeCustomer || this.props.activeCustomer === "") {
          RoutingProvider.invokePushNewRoute(this.props.history, RoutingPath.home);
        }
        return;
      default:
        return;
    }
  }

  private get headerTitle(): string {
    switch (this.props.location.pathname) {
      case RoutingPath.cleaningObject:
        return this.props.cleaningObjectName;
      case RoutingPath.schedule:
        return this.props.activeSchedule != null
          ? this.props.activeSchedule.locationName ?? "-"
          : this.props.activeLocation?.name ?? "-";
      case RoutingPath.notification:
        return LanguageProvider.t(TranslationMapper.components.notifications.title);
      case RoutingPath.home:
        return (
          this.props.availableCleaningLocations.find(c => c.customerId === this.props.activeCustomer)?.customerName ??
          ""
        );
      default:
        return "";
    }
  }

  private toPreviousPage(): void {
    const historyState = RoutingProvider.getRouteState(this.props.location);

    switch (this.props.location.pathname) {
      case RoutingPath.cleaningObject:
        RoutingProvider.invokePushNewRoute(this.props.history, RoutingPath.schedule, {
          scheduleId: historyState?.scheduleId,
        });
        return;
      case RoutingPath.home:
        this.props.clearActiveLocation();
        this.props.clearScannedFloor();
        this.props.setActiveCustomer("");
        return;
      case RoutingPath.schedule:
        if (
          this.props.availableCleaningLocations.filter(
            c => c.customerId === this.props.activeCustomer && c.isScheduledToday
          ).length === 1 &&
          !this.props.availableCleaningLocations.find(
            c => c.customerId === this.props.activeCustomer && c.isScheduledToday
          )?.hasNfc
        ) {
          this.props.clearScannedFloor();
          this.props.setActiveCustomer("");
        }
        this.props.clearActiveLocation();
        RoutingProvider.invokePushNewRoute(this.props.history, RoutingPath.home);
        return;
      case RoutingPath.notification:
        RoutingProvider.invokePushNewRoute(this.props.history, RoutingPath.cleaningObject, {
          cleaningObjectId: historyState?.cleaningObjectId,
        });
        return;
      default:
        RoutingProvider.invokePushNewRoute(this.props.history, RoutingPath.home);
    }
  }

  private openInfoModal(): void {
    this.setState({
      showInfoModal: true,
    });
  }

  private onCloseInfoModal(): void {
    this.setState({
      showInfoModal: false,
    });
  }

  private get showBackButton(): boolean {
    if (this.props.activeCustomer != null && this.props.activeCustomer !== "") {
      if (this.props.location.pathname !== RoutingPath.home) {
        return this.props.availableCleaningLocations.length >= 1;
      }

      return [...new Set(this.props.availableCleaningLocations.map(item => item.customerId))].length > 1;
    }

    return false;
  }

  private renderInfoIcon(): JSX.Element {
    if (this.props.activeCustomer === "JDE") {
      return (
        <li className="nav-item">
          <Button className="btn-link btn-sm" iconStart={["fal", "circle-info"]} onClick={this.openInfoModal} />;
        </li>
      );
    }
    return <></>;
  }

  private get renderLogbook(): JSX.Element {
    const hasLogbookInformation = this.props.logbook != null && this.props.logbook.channelResponse.length > 0;

    if (!hasLogbookInformation) {
      return <></>;
    }

    return <Logbook showLogbookButton={true} selectedLocationProjectId={this.props.activeLocation?.projectId} />;
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header">
          <div className="nav-spacing--start">
            {this.showBackButton ? (
              <Button
                className="btn-link btn-sm ps-0"
                iconStart={["fal", "circle-arrow-left"]}
                onClick={this.toPreviousPage}
              />
            ) : (
              <img src={Logo} className="logo" alt="Hi Five" />
            )}
          </div>

          <h4 className="header--title">{this.headerTitle}</h4>
          <ul className="nav nav-spacing--end">
            {this.renderInfoIcon()}
            <li className="nav-item">{this.renderLogbook}</li>
            <li className="nav-item">
              <UserSettings />
            </li>
          </ul>
        </header>
        <InfoModal show={this.state.showInfoModal} onClose={this.onCloseInfoModal} />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMainHeaderStateProps => ({
  cleaningLocationsAvailable: state.scheduleState.locations != null && state.scheduleState.locations.length > 0,
  availableCleaningLocations: state.scheduleState.locations ?? [],
  cleaningObjectName: state.scheduleState.selectedCleaningObject?.name ?? "-",
  activeCustomer: state.customerState.activeCustomer,
  activeLocation: state.scheduleState.selectedLocation,
  activeSchedule: state.scheduleState.schedule,
  featureFlags: state.generalState.featureFlags,
  logbook: state.logbookState.logbook,
});

const mapDispatchToProps: IMainHeaderDispatchProps = {
  setActiveCustomer: setActiveCustomer,
  clearActiveLocation: clearActiveLocation,
  clearScannedFloor: clearScannedFloorDispatcher,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader));
