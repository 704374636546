import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";

import ITextAreaInputProps from "./interfaces/ITextAreaInputProps";

export class TextAreaInput extends Component<ITextAreaInputProps> {
  public constructor(props: ITextAreaInputProps) {
    super(props);

    this.onTextAreaInputAnswerChange = this.onTextAreaInputAnswerChange.bind(this);
  }

  private get remainingAnswerLength(): number {
    return this.props.answer ? this.props.maxAnswerLength - this.props.answer?.length : this.props.maxAnswerLength;
  }

  private get remainingAnswerLengthLabel(): string {
    return (
      this.remainingAnswerLength + " " + LanguageProvider.t(TranslationMapper.components.activities.feedback.characters)
    );
  }

  private onTextAreaInputAnswerChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    const answer = event.currentTarget.value;
    const answerLength = answer.toString().length;

    if (answerLength <= this.props.maxAnswerLength) {
      this.props.onInputChange(answer);
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <Form.Control
          as="textarea"
          id={this.props.id}
          name={this.props.id}
          onChange={this.onTextAreaInputAnswerChange}
          disabled={this.props.inputDisabled}
          isInvalid={this.props.invalid || this.remainingAnswerLength === 0}
          value={this.props.answer ?? ""}
        />
        <div className={this.remainingAnswerLength > 0 ? "form-text" : "invalid-feedback d-block"}>
          {this.remainingAnswerLengthLabel}
        </div>
      </>
    );
  }
}

export default TextAreaInput;
