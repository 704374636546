import NotificationStatus from "enums/notificationStatus";
import { TranslationMapper } from "i18n/mapper";
import INotification from "interfaces/INotification";
import LanguageProvider from "providers/languageProvider";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ICleaningObjectOpenNotificationsProps } from "./interfaces/cleaningObjectOpenNotificationsProps";

class CleaningObjectOpenNotifications extends React.Component<ICleaningObjectOpenNotificationsProps> {
  private get openNotifications(): INotification[] {
    return this.props.notifications?.filter(notification => {
      if (notification.status == null) {
        return false;
      }

      return (
        notification.status === NotificationStatus.Open ||
        notification.status === NotificationStatus.InProgress ||
        notification.status === NotificationStatus.ActionRequired
      );
    });
  }

  private get notificationSubText(): string {
    return `${this.openNotifications?.length} ${LanguageProvider.t(
      this.openNotifications.length !== 1
        ? TranslationMapper.components.notifications.notifications
        : TranslationMapper.components.notifications.notification
    )}`;
  }

  public render(): JSX.Element {
    return (
      <>
        {this.openNotifications?.length > 0 && (
          <div className="btn--content__message-info">
            <span className="fa-stack">
              <FontAwesomeIcon icon={["fal", "bell"]} fixedWidth className="fa-stack-1x" />
              <FontAwesomeIcon icon={["fas", "circle-exclamation"]} fixedWidth className="fa-stack-1x fa-xs" />
            </span>
            <span>{this.notificationSubText}</span>
          </div>
        )}
      </>
    );
  }
}

export default CleaningObjectOpenNotifications;
