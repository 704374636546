import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { ICustomerState, initialCustomerState } from "../state/customerStoreState";

function customerReducer(state: ICustomerState = initialCustomerState, action: ActionTypesCollection): ICustomerState {
  switch (action.type) {
    case ActionTypes.SET_CUSTOMER:
      return { ...state, activeCustomer: action.payload };
    default:
      return state;
  }
}

export default customerReducer;
