import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SystemNotificationPriority } from "../systemNotificationBar/interfaces/ISystemNotificationBarProps";
import SystemNotificationBar from "../systemNotificationBar/systemNotificationBar";
import IServiceWorkerState from "./interfaces/IServiceWorkerState";

class ServiceWorkerComponent extends React.Component<{}, IServiceWorkerState> {
  private serviceWorkerRegistration: Promise<ServiceWorkerRegistration>;

  public constructor(props: {}) {
    super(props);

    this.state = {
      isUpdateAvailable: false,
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.onCheckUpdate = this.onCheckUpdate.bind(this);

    this.serviceWorkerRegistration = navigator.serviceWorker.ready;
  }

  public componentDidMount(): void {
    const minuteInMs = 60 * 1000;

    this.setState({
      interval: setInterval(this.onCheckUpdate, minuteInMs),
    });
  }

  public componentWillUnmount(): void {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  private readonly timeoutForServiceWorkerToUpdate: number = 2500;

  private onUpdate(): void {
    this.serviceWorkerRegistration.then(registration => {
      if (registration?.waiting != null) {
        registration?.waiting.postMessage({ type: "SKIP_WAITING" });

        setTimeout(this.reload, this.timeoutForServiceWorkerToUpdate);
      }
    });
  }

  private reload(): void {
    document.location.assign(document.location.origin + "/");
  }

  private onCheckUpdate(): void {
    this.serviceWorkerRegistration.then(registration => {
      this.setState({
        isUpdateAvailable: registration.waiting != null,
      });

      registration.update();
    });
  }

  public render(): JSX.Element {
    return (
      <SystemNotificationBar
        isHidden={!this.state.isUpdateAvailable}
        messagePriority={SystemNotificationPriority.medium}
        onClick={this.onUpdate}
      >
        <FontAwesomeIcon icon={["fal", "rotate-right"]} fixedWidth className="mr-1" />

        {LanguageProvider.t(TranslationMapper.global.messages.new_app_version)}
      </SystemNotificationBar>
    );
  }
}

export default ServiceWorkerComponent;
