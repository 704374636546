import ISchedule from "../interfaces/ISchedule";

const emptySchedule: ISchedule = {
  id: "",
  externalId: "",
  description: "",
  floors: [],
  groupByFloors: false,
  showFloorNames: false,
  isFloorNFCRequired: false,
};

export default emptySchedule;
