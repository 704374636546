import "./i18n/config";
import "./main.scss";

import ReactNotification from "components/notifications/reactNotification";
import ScrollToTop from "components/scroll/scrollToTop";
import { TranslationMapper } from "i18n/mapper";
import FeatureFlagProvider from "providers/featureFlagProvider";
import ReactDOM from "react-dom";
import { NotificationContainer } from "react-notifications";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Store } from "redux";
import UserService from "services/userService";
import { ActionTypesCollection } from "store/actionTypes";
import { RootState } from "store/reducers/rootReducer";
import { StyleUtils } from "utils/styleUtils";

import App from "./app";
import ErrorBoundary from "./error/components/errorBoundary/errorBoundary";
import ErrorDisplay from "./error/components/errorDisplay/errorDisplay";
import MsalFactory from "./factories/msalFactory";
import TelemetryProvider from "./providers/telemetryProvider";
import { register } from "./registerServiceWorker";
import AppEventHub from "./utils/appEventHub";
import StoreFactory from "store/store";

AppEventHub.initialize();
MsalFactory.initialize();
initializeServiceWorker();
StyleUtils.maintainViewportSize();
ReactNotification.manageNotifications();

const userService = new UserService();
userService.ensureAccessAsync().then(
  (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      const store = StoreFactory.Create();
      store.subscribe(() => handleStoreChangeSubscriptions(store));

      ReactDOM.render(
        <ErrorBoundary>
          <Router>
            <TelemetryProvider>
              <NotificationContainer />
              <Provider store={store}>
                <ScrollToTop>
                  <App />
                </ScrollToTop>
              </Provider>
            </TelemetryProvider>
          </Router>
        </ErrorBoundary>,
        document.getElementById("root")
      );
    } else {
      renderLoginFailed();
    }
  },
  () => {
    renderError();
  }
);

function renderError(): void {
  ReactDOM.render(
    <Router>
      <ErrorDisplay
        errorTitle={TranslationMapper.global.errors.browser_specific_error_title}
        errorText={TranslationMapper.global.errors.browser_specific_error_text}
        errorCode="0x4B9A"
      />
    </Router>,
    document.getElementById("root")
  );
}

function renderLoginFailed(): void {
  ReactDOM.render(
    <Router>
      <ErrorDisplay
        errorTitle={TranslationMapper.global.errors.failed_login_title}
        errorText={TranslationMapper.global.errors.failed_login_text}
        errorCode="0x1BA1"
      />
    </Router>,
    document.getElementById("root")
  );
}

function initializeServiceWorker(): void {
  register();
}

function handleStoreChangeSubscriptions(store: Store<RootState, ActionTypesCollection>): void {
  // Functions subscribed to store updates outside connected components
  FeatureFlagProvider.manageFlagChanges(store.getState().generalState.featureFlags);
}
