import PollutionSlider from "components/input/slider/pollutionSlider";
import TextAreaInput from "components/input/textarea/textAreaInput";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Form } from "react-bootstrap";

import NumericInput from "../../components/input/numericInput";
import SelectInput from "../../components/input/selectInput";
import FeedbackQuestionTypes from "../../enums/feedbackQuestionTypes";
import { IFeedbackQuestionProps } from "./interfaces/IFeedbackQuestionProps";

export default class FeedbackQuestion extends Component<IFeedbackQuestionProps> {
  public constructor(props: IFeedbackQuestionProps) {
    super(props);

    this.onAnswerChange = this.onAnswerChange.bind(this);
    this.onSelectAnswerChange = this.onSelectAnswerChange.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  private get invalid(): boolean {
    return this.props.question.required && (this.props.answer == null || this.props.answer === "");
  }

  private onAnswerChange(input: string | number): void {
    this.props.onAnswerChange(this.props.activityId, this.props.question.id, input);
  }

  private get answerValueForSelect(): string | undefined {
    const answer = this.props.question.feedbackQuestionAnswerOptions.find(a => a.answer === this.props.answer);

    return answer?.id;
  }

  private onSelectAnswerChange(answerId: string | number): void {
    const answer = this.props.question.feedbackQuestionAnswerOptions.find(a => a.id === answerId)?.answer;

    this.props.onAnswerChange(this.props.activityId, this.props.question.id, answer ?? "");
  }

  private get feedbackDescription(): string {
    const translationMapper =
      TranslationMapper.components.activities.feedback[LanguageProvider.keyToMapperKey(this.props.question.question)];

    if (translationMapper == null) {
      return this.props.question.question;
    }
    return LanguageProvider.t(translationMapper);
  }

  private renderInput(): JSX.Element {
    switch (this.props.question.questionType) {
      case FeedbackQuestionTypes.Text:
        return (
          <TextAreaInput
            id={this.props.question.id}
            answer={this.props.answer}
            inputDisabled={this.props.disabled}
            invalid={this.invalid}
            maxAnswerLength={150}
            onInputChange={this.onAnswerChange}
          />
        );
      case FeedbackQuestionTypes.Numeric:
        return (
          <NumericInput
            id={this.props.question.id}
            answer={this.props.answer}
            inputDisabled={this.props.disabled}
            invalid={this.invalid}
            onAnswerChange={this.onAnswerChange}
          />
        );
      case FeedbackQuestionTypes.Select:
        return (
          <SelectInput
            id={this.props.question.id}
            answer={this.answerValueForSelect}
            inputDisabled={this.props.disabled}
            answerOptions={this.props.question.feedbackQuestionAnswerOptions}
            invalid={this.invalid}
            onAnswerChange={this.onSelectAnswerChange}
          />
        );
      case FeedbackQuestionTypes.Slider:
        return (
          <PollutionSlider
            onSelectionChange={this.onAnswerChange}
            onSliderChange={this.onAnswerChange}
            min={1}
            max={100}
            value={this.props.answer ? (this.props.answer as unknown as number) : 1}
            sliderWidth="90%"
          />
        );
      default:
        return <></>;
    }
  }

  public render(): JSX.Element {
    return (
      <Form.Group>
        <Form.Label>{this.feedbackDescription}</Form.Label>
        {this.renderInput()}
      </Form.Group>
    );
  }
}
