import Button from "components/material/buttons/button";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";

import {
  IConfirmationModalDispatchProps,
  IConfirmationModalProps,
  IConfirmationModalStateProps,
} from "./interfaces/IConfirmationModalProps";

class ConfirmationModal extends Component<IConfirmationModalProps> {
  public render(): JSX.Element {
    return (
      <Modal show={true} onHide={this.props.cancel} dialogClassName="modal-dialog-centered">
        <Modal.Header closeButton>
          <div className="modal-header__info">
            <div>
              <h1 className="modal-title">{this.props.title}</h1>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal__message">
            <img src={this.props.imageSrc} alt="" />
            <div className="modal__message__block">
              <h2 className="text-infographic">{this.props.bodyText}</h2>
              <p>{this.props.bodySubText}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            label={LanguageProvider.t(TranslationMapper.components.notifications.confirmation_cancel)}
            className="btn btn-outline-secondary"
            onClick={this.props.cancel}
            disabled={false}
            iconEnd={["fal", "xmark"]}
          />
          <Button
            label={this.props.confirmButtonText}
            className="btn btn-primary"
            onClick={this.props.confirm}
            disabled={false}
            iconEnd={["fal", "check"]}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IConfirmationModalStateProps => ({});

const mapDispatchToProps: IConfirmationModalDispatchProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal));
