import LanguageProvider from "providers/languageProvider";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";

import Loader from "../../components/loader/loader";
import IModalComponentProps from "./interfaces/IModalComponentProps";

export default class ModalComponent extends Component<IModalComponentProps> {
  public constructor(props: IModalComponentProps) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  private renderContent(): React.ReactNode {
    if (this.props.content == null && this.props.children == null) {
      return <></>;
    }

    return this.props.content ?? this.props.children;
  }

  private renderHeader(): React.ReactNode {
    if (this.props.header != null) {
      return this.props.header;
    } else if (this.props.titleResourceLabel != null) {
      return (
        <div className="modal-header__info">
          <h1 className="modal-title">{LanguageProvider.t(this.props.titleResourceLabel)}</h1>
        </div>
      );
    }
    return <></>;
  }

  private get className(): string {
    let className = "";

    if (this.props.className != null) {
      className += ` ${this.props.className}`;
    }

    return className;
  }

  public render(): JSX.Element {
    const modalHook = document.getElementById("modal");

    return (
      <>
        {modalHook &&
          ReactDOM.createPortal(
            <Modal
              id={this.props.id}
              size={this.props.size}
              centered
              show={this.props.show}
              onHide={this.props.onModalHide}
              className={this.className}
              backdrop="static"
              scrollable
              fullscreen={this.props.fullscreen}
            >
              {this.props.showHeader && (
                <Modal.Header {...(this.props.fullscreen ? { "data-bs-theme": "dark" } : {})}>
                  {this.renderHeader()}
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    data-dismiss="modal"
                    onClick={this.props.onModalHide}
                  ></button>
                </Modal.Header>
              )}
              {this.props.loading && (
                <div className="modal-body">
                  <div className="loader-container">
                    <Loader isLoading={true} />
                  </div>
                </div>
              )}

              {!this.props.loading && <Modal.Body>{this.renderContent()}</Modal.Body>}

              {this.props.footerContent && (
                <Modal.Footer className="justify-content-between">{this.props.footerContent}</Modal.Footer>
              )}
            </Modal>,
            modalHook
          )}
      </>
    );
  }
}
