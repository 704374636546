import ManualTagInputModal from "components/modal/manualTagInputModal";
import ICleaningObjectActivity from "interfaces/ICleaningObjectActivity";
import INfcReferenceObjectRequest from "interfaces/INfcReferenceObjectRequest";
import emptySchedule from "models/emptySchedule";
import FeatureFlagProvider, { FlagNames } from "providers/featureFlagProvider";
import NativeCommunicationProvider, { INativeShellCallback } from "providers/nativeCommunicationProvider";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { assignActivityToOperator } from "store/actions/activityActions";
import { RootState } from "store/reducers/rootReducer";
import NFCValidator from "utils/nfcValidator";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CheckoutTriggerType from "../../enums/checkoutTriggerType";
import {
  getCleaningObjectByIdAsync,
  getUniversalReferenceObjectAsync,
  setActiveActivitiesCheckoutTrigger,
} from "../../store/actions/scheduleActions";
import AssignActivity from "./assignActivity";
import CleaningObjectOpenNotifications from "./cleaningObjectOpenNotifications";
import {
  IScheduleCleaningObjectDispatchProps,
  IScheduleCleaningObjectProps,
  IScheduleCleaningObjectStateProps,
} from "./interfaces/IScheduleCleaningObjectProps";
import { IScheduleCleaningObjectState } from "./interfaces/IScheduleCleaningObjectState";

class ScheduleCleaningObject extends React.Component<IScheduleCleaningObjectProps, IScheduleCleaningObjectState> {
  public constructor(props: IScheduleCleaningObjectProps) {
    super(props);
    this.state = {
      showManualNFCInputModal: false,
    };

    this.onReceiveManualTagInput = this.onReceiveManualTagInput.bind(this);
    this.openManualInputModal = this.openManualInputModal.bind(this);
    this.onCloseManualInputModal = this.onCloseManualInputModal.bind(this);
    this.processCleaningObject = this.processCleaningObject.bind(this);
    this.assignActivity = this.assignActivity.bind(this);
    this.updateCleaningObject = this.updateCleaningObject.bind(this);
    this.startScan = this.startScan.bind(this);
    this.onReceiveNFCMessage = this.onReceiveNFCMessage.bind(this);
  }

  private get description(): string {
    if (this.props.cleaningObject.locationDescription == null) {
      return this.props.cleaningObject.name;
    }
    return this.props.cleaningObject.locationDescription;
  }

  private processCleaningObject(): void {
    if (!this.props.cleaningObject.hasNfc) {
      this.props.onCleaningObjectSelected(CheckoutTriggerType.Clicked);
      this.updateCleaningObject();
      return;
    }
    if (
      !NativeCommunicationProvider.isNativeShellActive() &&
      FeatureFlagProvider.isFeatureActive(FlagNames.CleaningApp_FF_Show_Old_Manual_NFC_Button)
    ) {
      this.openManualInputModal();
    } else if (!this.state.showManualNFCInputModal) {
      this.startScan();
    }
  }

  private startScan(): void {
    const callbackOnScan: INativeShellCallback = {
      function: this.onReceiveNFCMessage,
      functionName: "locationOverviewOnReceiveNFC",
    };

    const callbackOpenManualInput: INativeShellCallback = {
      function: this.openManualInputModal,
      functionName: "locationOverviewOpenManualInputModal",
    };

    NativeCommunicationProvider.initiateNFCScanner(callbackOnScan, callbackOpenManualInput);
  }

  private onReceiveNFCMessage(input: any): void {
    const nfcMessage = NFCValidator.tryParseNfcMessage(input);

    if (!nfcMessage) {
      return;
    }

    const nfcRequest: INfcReferenceObjectRequest = {
      id: nfcMessage.id,
      code: nfcMessage.message,
    };

    this.props.onNfcTagScanned(
      nfcRequest,
      this.props.history,
      CheckoutTriggerType.NfcTagScanned,
      this.props.schedule.id
    );
  }

  private openManualInputModal(): void {
    this.setState({
      showManualNFCInputModal: true,
    });
  }

  private onCloseManualInputModal(): void {
    this.setState({
      showManualNFCInputModal: false,
    });
  }

  private onGetByManualInput(tag: string): void {
    this.onReceiveManualTagInput(tag);
    this.setState({
      showManualNFCInputModal: false,
    });
  }

  private onReceiveManualTagInput(tag: string): void {
    const nfcRequest: INfcReferenceObjectRequest = {
      id: undefined,
      code: tag,
    };

    this.props.onNfcTagScanned(
      nfcRequest,
      this.props.history,
      CheckoutTriggerType.NfcTagManual,
      this.props.schedule.id
    );
  }

  private get assignableActivities(): ICleaningObjectActivity[] | undefined {
    const assignableActivities = this.props.cleaningObject.activities?.filter((a) => a.isAssignable);

    if (!assignableActivities) {
      return undefined;
    }

    if (assignableActivities.length > 1) {
      // When there is more than 1 assignable activity, we return the first upcoming SLA date
      const filtered = assignableActivities.filter((ac) => ac.slaStartDateTime != null);

      return filtered ?? undefined;
    }

    return assignableActivities;
  }

  private assignActivity(): void {
    if (!this.assignableActivities) {
      return;
    }

    this.props.onAssignActivity(
      this.assignableActivities.map((activity) => activity.id),
      this.props.history
    );
  }

  private updateCleaningObject(): void {
    this.props.onGetCleaningObject(this.props.schedule.id, this.props.cleaningObject.id, this.props.history);
  }

  public render(): JSX.Element {
    return (
      <button
        className={"btn btn--content " + (this.props.isAccordionItem ? " btn--content__accordion-item" : "")}
        key={this.props.cleaningObject.id}
        onClick={this.processCleaningObject}
      >
        <div className="btn--content_block">
          <div>
            <div className="btn--content__content">
              {this.props.isAccordionItem && <h3 className="mb-0">{this.props.cleaningObject.name}</h3>}
              {!this.props.isAccordionItem && <h2>{this.props.cleaningObject.name}</h2>}

              {!this.props.isAccordionItem && this.props.schedule.showFloorNames && <p>{this.description}</p>}
            </div>
            <div className="btn--content__message">
              <CleaningObjectOpenNotifications
                nextSlaDateTime={this.props.cleaningObject.nextSlaDateTime}
                notifications={this.props.cleaningObject.notifications}
              />
            </div>

            <ManualTagInputModal
              show={this.state.showManualNFCInputModal}
              onClose={this.onCloseManualInputModal}
              onGetByManualInput={(tag: string): void => this.onGetByManualInput(tag)}
            />
          </div>
          <div className="btn--content__end">
            {!this.props.cleaningObject.hasNfc && (
              <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth className="align-middle" />
            )}
            {this.props.cleaningObject.hasNfc && (
              <FontAwesomeIcon icon={["fal", "mobile-signal"]} fixedWidth className="align-middle" />
            )}
          </div>
        </div>
        {this.assignableActivities && this.assignableActivities[0] && (
          <div className="btn--content_block">
            <AssignActivity
              activity={this.assignableActivities[0]}
              location={this.props.cleaningObject.locationDescription}
              onAssignToEmployee={this.assignActivity}
              cleaningObjectName={this.props.cleaningObject.name}
            />
          </div>
        )}
      </button>
    );
  }
}

const mapStateToProps = (state: RootState): IScheduleCleaningObjectStateProps => ({
  // no schedule available could be the case when this page is called directly by the user
  schedule: state.scheduleState.schedule ?? emptySchedule,
});

const mapDispatchToProps: IScheduleCleaningObjectDispatchProps = {
  onGetCleaningObject: getCleaningObjectByIdAsync,
  onCleaningObjectSelected: setActiveActivitiesCheckoutTrigger,
  onAssignActivity: assignActivityToOperator,
  onNfcTagScanned: getUniversalReferenceObjectAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleCleaningObject));
